function ListOfRoundButtons() {
    var ListOfRoundButtons = [
        {
            id: 0,
            Location:'top',
            Image:'top.png'
        },
        {
            id: 1,
            Location:'bottom',
            Image:'bottom.png'
        },
        {
            id: 2,
            Location:'left',
            Image:'left.png'
        },
        {
            id: 3,
            Location:'right',
            Image:'right.png'
        }
      
        // Add more button styles as needed
    ];
    
    return ListOfRoundButtons;   
}
      
export default ListOfRoundButtons;
