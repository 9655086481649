import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getUser } from '../service/AuthService';
import prologo from '../assets/tagitpro.png';
import leaf from '../assets/tagittagleaf2.png'; // Import the leaf image or any other image for the floating tiles
import axios from 'axios';
import Header from '../Header/Header';

const subscribeAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/subscribe';
const unsubscribeAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/unsubscribe';

const user = getUser();
console.log(user);

const features = ["Insights & Analytics", "Pro Module: Audio Player", "Access to new features first."];
const delays = [
  0, 6, 15, 17, 25, 35, 3, 9, 12, 23,  39, 
  4, 7, 10, 18, 26, 31, 8, 5, 14, 24, 30, 33, 
  2, 11, 16, 19, 21, 36, 13, 27, 32, 20, 22, 28, 
  37, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90
  // You can continue adding delays here
];

const transition = { duration: 2, ease: "easeInOut", loop: Infinity };
function subscribeMonthly() {
  const requestBody = {
    name: user.username,
    paymentPeriod: "Monthly"
  }

  axios.post(subscribeAPIUrl, requestBody).then((response) => {
    var targetUrl = response.data.checkoutLink;
    window.location.href = targetUrl;
  }).catch(handleError);
}

function subscribeYearly() {
  const requestBody = {
    name: user.username,
    paymentPeriod: "Yearly"
  }

  axios.post(subscribeAPIUrl, requestBody).then((response) => {
    var targetUrl = response.data.checkoutLink;
    window.location.href = targetUrl;
  }).catch(handleError);
}

function unsubscribe() {
  const requestBody = {
    name: user.username
  }

  axios.post(unsubscribeAPIUrl, requestBody).then((response) => {
    window.location.href = '/dashboard';
  }).catch(handleError);
}

function handleError(error) {
  if (error.response.status === 401 || error.response.status === 403) {
    alert(error.response.data.message);
  } else {
    alert('Sorry, the server is down right now, please try again later.');
  }
}

const Subscriptions = () => {
  const [featureIndex, setFeatureIndex] = useState(0);
  const [showUnsubscribeConfirmation, setShowUnsubscribeConfirmation] = useState(false);

  const handleUnsubscribeClick = () => {
    setShowUnsubscribeConfirmation(true);
  }

  const handleUnsubscribeConfirm = () => {
    unsubscribe();
    setShowUnsubscribeConfirmation(false);
  }

  const handleUnsubscribeCancel = () => {
    setShowUnsubscribeConfirmation(false);
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      setFeatureIndex((prevFeatureIndex) => (prevFeatureIndex + 1) % features.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    }
  }, []);

  return (
    <motion.div className='fixed w-full h-full right-0 top-0 bg-blue-300 overflow-hidden flex items-center justify-center'>
   {
  [
    '-90%', '90%', '-100%', '90%', '-80%', '100%', '300%', '-300%', '300%', '-300%', '300%', '-300%',
    '400%', '-400%', '400%', '-400%', '500%', '-500%', '600%', '-600%', '700%', '-700%',
    '800%', '-800%', '900%', '-900%', '1000%', '-1000%', '1100%', '-1100%', '1200%', '-1200%', '1300%', '-1300%', '1400%', '-1400%', '1500%', '-1500%',
    // You can continue adding offsets here
  ].map((initialOffset, index) => (
    // Your motion code here
  
      <motion.img
        className='z-0 opacity-50'
        key={index}
        src={leaf}
        alt="Flying Image"
        initial={{ y: '-700%', x: initialOffset, zIndex: 0 }} // zIndex set to 0
        animate={{ y: '3000%', x: initialOffset }}
        transition={{
          delay: delays[index % delays.length], // Reuse the delay values
          duration: 50,
          ease: 'linear',
          repeat: Infinity
        }}
        style={{ width: '100px', height: '100px', position: 'absolute', opacity: '50%' }}
      />
    ))}
      
    
  <div className='flex flex-col overflow-y-auto w-4/5 h-4/5 rounded-lg'>
  <Header />
    <motion.div 
      className="flex flex-col justify-center items-center z-0 text-white bg-white rounded-lg h-full"
      animate={{ backgroundSize: ["200%", "200%", "200%", "150%", "200%"], backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"] }}
      transition={transition}
    >
      <motion.div className="text-4xl md:text-5xl font-bold mb-5">
        <img src={prologo} className=' h-48'></img>
      </motion.div>
      <AnimatePresence mode='wait'>
        <motion.div 
          key={features[featureIndex]}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 1 }}
          className="text-lg md:text-2xl text-center mb-10"
        >
          {features[featureIndex]}
        </motion.div>
      </AnimatePresence>
      {user.level >= 1 ? (
        <div className="flex flex-col items-center">
          {showUnsubscribeConfirmation ? (
            <div className="flex flex-col items-center">
              <button onClick={handleUnsubscribeConfirm} className="px-4 py-2 bg-red-500 text-white rounded-lg font-semibold tracking-wide shadow-lg z-20 hover:bg-red-600 transition-colors duration-200 text-sm md:text-base mb-2">
                Confirm Unsubscribe
              </button>
              <button onClick={handleUnsubscribeCancel} className="px-4 py-2 bg-gray-400 text-white rounded-lg font-semibold tracking-wide shadow-lg z-20 hover:bg-gray-500 transition-colors duration-200 text-sm md:text-base">
                Cancel
              </button>
            </div>
          ) : (
            <button onClick={handleUnsubscribeClick} className="px-4 py-2 bg-red-500 text-white rounded-lg font-semibold tracking-wide shadow-lg z-20 hover:bg-red-600 transition-colors duration-200 text-sm md:text-base">
              Unsubscribe from Service
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div className="flex flex-row space-x-4">
            <button  className="px-4 py-2 bg-blue-500 text-white rounded-lg font-semibold tracking-wide shadow-lg z-20 hover:bg-blue-600 transition-colors duration-200 text-sm md:text-base">
              Monthly Subscription ($9.99/month)
            </button>
            <button  className="px-4 py-2 bg-green-500 text-white rounded-lg font-semibold tracking-wide shadow-lg z-20 hover:bg-green-600 transition-colors duration-200 text-sm md:text-base">
              Yearly Subscription ($7.99/month)
            </button>
          </div>
        </div>
      )}
    </motion.div>
    </div>
    </motion.div>
  );
}

export default Subscriptions;
