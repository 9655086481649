import React from 'react';

function CustomColorPicker({ label, value, onChange }) {
  return (
    <div className="flex items-center space-x-4">
      <label className="text-zinc-50">{label}:</label>
      <div className="relative" style={{ width: '36px', height: '36px' }}>
        {/* The circle div now allows clicks to pass through it */}
        <div
          style={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            backgroundColor: value,
            border: '2px solid #ddd',
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none', // Allows click events to pass through to the color input
          }}
        />
        <input
          type="color"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{
            opacity: 0, // Make the color input invisible
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </div>
    </div>
  );
}

export default CustomColorPicker;
