import React, { useState } from 'react';
import basicWebImage from '../../assets/basicwebimage.png';
import ListOfButtons from '../../TagCreator/ListOfButtons';

function LinkComponent({ data }) {
  const listOfButtons = ListOfButtons();
  const [isOpen, setIsOpen] = useState(false);
  const [startDragX, setStartDragX] = useState(0);
  const [currentDragX, setCurrentDragX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  console.log("Data!", data);

  const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor,
  };

  const faviconSrc = (data.url.split("/"))[0] + "//" + data.url.split("/")[2] + '/' + 'favicon.ico';

  const truncatedUrl = data.url.length > 30 ? data.url.slice(0, 20) + '...' : data.url;

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartDragX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setCurrentDragX(e.clientX);
      const dragDistance = startDragX - currentDragX;
      if (dragDistance > 50) { // Dragging left
        setIsOpen(true);
      } else if (dragDistance < -50) { // Dragging right
        setIsOpen(false);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setStartDragX(0);
    setCurrentDragX(0);
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      handleMouseUp();
    }
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartDragX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      setCurrentDragX(e.touches[0].clientX);
      const dragDistance = startDragX - currentDragX;
      if (dragDistance > 50) { // Dragging left
        setIsOpen(true);
      } else if (dragDistance < -50) { // Dragging right
        setIsOpen(false);
      }
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setStartDragX(0);
    setCurrentDragX(0);
  };

  if (data.ButtonStyle.containerType === "quicklink") {
    return (
      <a
        className="inline-flex items-center justify-center rounded-full p-2 outline outline-1"
        id="tagModule"
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          ...Object.assign({}, additionalStyles),
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <img
            style={{ width: '30px', height: '30px' }}
            src={faviconSrc}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = basicWebImage;
            }}
            alt="Favicon"
          />
        </div>
      </a>
    );
  }

  const darkerBackgroundColor = `rgba(0, 0, 0, 0.3)`; // Adjust this based on the original background color if needed

  return (
    <div
      className="inline-flex items-center outline outline-2 w-full relative"
      id='tagModule'
      style={{ position: 'relative', overflow: 'hidden', borderRadius: baseStyles.borderRadius, ...Object.assign({}, additionalStyles), }} // Added borderRadius to parent
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <a
        className="absolute left-0 top-0 bottom-0 flex items-center justify-center"
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          width: '40px',
          height: '100%', // Ensure the height matches the container
          backgroundColor: darkerBackgroundColor,
          zIndex: 2,
          overflow: 'hidden', // Hide overflow content
          borderRadius: baseStyles.borderRadius, // Match the parent border radius
        }}
      >
        <img
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={faviconSrc}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = basicWebImage;
          }}
          alt="Favicon"
        />
      </a>
      <a
        className="flex-1 flex justify-center items-center"
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          ...Object.assign({}, baseStyles, additionalStyles),
          position: 'relative',
          zIndex: 1,
          transform: isOpen ? 'translateX(-100%)' : 'translateX(0)',
          transition: 'transform 0.3s ease',
        }}
      >
        <div className={`p-2 ${data.ButtonStyle.fontClassName}`}>
          &nbsp; {data.title}
        </div>
      </a>
      <div
        className="absolute top-0 right-0 bottom-0 flex items-center justify-center"
        style={{
          width: '100%', // Adjust width to cover full area
          zIndex: 0,
          backgroundColor: 'white', // Adjust background color as needed
          transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 0.3s ease',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center the content
        }}
      >
        <a
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-800 p-2 text-center"
        >
          {truncatedUrl}
        </a>
      </div>
    </div>
  );
}

export default LinkComponent;
