import React, { useState, useEffect } from 'react';
import { HiXMark } from "react-icons/hi2";
import ButtonStyle from '../ButtonStyle';
import VideoComponent from '../../TagReader/Video/VideoComponent';

function VideoModuleCreator({ onSave, initialData, onCancel, recentButtonStyles }) {
  const [scale, setScale] = useState(1);

  // Adjust scale based on window size
  useEffect(() => {
    const handleResize = () => {
      const scaleHeight = Math.min(window.innerHeight / 900, 1);
      setScale(scaleHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [selectedButtonStyleId, setSelectedButtonStyleId] = useState(0); // ID of the selected button style
  const [buttonColor, setButtonColor] = useState('#99ffdd'); // Default color
  const [fontColor, setFontColor] = useState('#99ffdd'); // Default font color

  useEffect(() => {
    if (initialData) {
      setLink(initialData.url || '');
      setSelectedButtonStyleId(initialData.buttonStyleId || 0);
      if (initialData.ButtonStyle) {
        setButtonColor(initialData.ButtonStyle.buttonColor);
        setFontColor(initialData.ButtonStyle.fontColor);
        setLink(initialData.video)
        setSelectedButtonStyleId(initialData.ButtonStyle.buttonStyleId);
      }
    } else {
      // Reset to defaults if no initialData
      setLink('');
      setSelectedButtonStyleId(0);
      setButtonColor('#99ffdd'); // Default color
      setFontColor('#99ffdd'); // Default font color
    }
  }, [initialData]);
  
  const handleFontColorChange = (newColor) => {
    setFontColor(newColor);
  };

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
  };

  const handleSubmit = () => {
    const ButtonStyleData = {
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        containerType: "regular",
        fontColor: fontColor,
      },
      type: 'video',
      video: link,
      id: initialData?.id
    };
    
    onSave(ButtonStyleData);
  };

  const buttonSelection = (e) => {
    setSelectedButtonStyleId(Number(e.target.value));
  };

  const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

  const previewStyle = {
    backgroundColor: '#333',
    backgroundImage: `url("${svgGrid}")`,
    backgroundSize: '20px 20px',
    height: '200px',
    width: '100%',
    marginBottom: '20px',
  };

  return (
    <div className="fixed inset-0 rounded bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40 flex items-center justify-center">
       <div className="mx-auto border border-cyan-400 outline outline-1 overflow-y-auto overflow-x-hidden scrollbar-custom outline-cyan-400 shadow-lg rounded-md -mb-12 h-75vh w-10/12  sm:w-2/5 1k:w-3/12 bg-zinc-700 text-zinc-50 relative">
        <div className="text-center">
          <div style={{ ...previewStyle, position: 'relative' }}>
            <button 
              className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 z-50 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150"
              onClick={onCancel}
            >
              <HiXMark className="text-white" size={23} />
            </button>
            <div className='flex justify-center items-center w-full h-full'>
              <div className='w-4/5 flex justify-center scale-95 items-center' style={{ pointerEvents :'none',}}>
                <VideoComponent data={{
                  ButtonStyle: {
                    buttonStyleId: selectedButtonStyleId,
                    buttonColor: buttonColor,
                    containerType: "regular",
                    fontColor: fontColor,
                  },
                  type: 'video',
                  video: link,
                  id: initialData?.id,
                  title: title,
                }} />
              </div>
            </div>
          </div>

          <h3 className="text-base font-semibold bg-transparent w-full mx-8 text-left"> Video Link:</h3>
          <div className='justify-center items-center flex'> 
            <text className='p-2'>URL:</text> 
            <input 
              type="text" 
              value={link} 
              onChange={(e) => setLink(e.target.value)}
              className="mt-2 p-1 border border-gray-300 text-black rounded w-4/6"
              placeholder="Enter Youtube link here"
            />
          </div>
          
          <ButtonStyle
            selectedButtonStyleId={selectedButtonStyleId}
            buttonSelection={buttonSelection}
            buttonColor={buttonColor}
            fontColor={fontColor}
            handleFontColorChange={handleFontColorChange}
            handleColorChange={handleColorChange}
            recentButtonStyles={recentButtonStyles}
          />

          <div className="flex justify-end items-center p-4">
            <button 
              className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded transition duration-150 ease-in-out"
              onClick={handleSubmit}
            >
              Add Tag Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoModuleCreator;
