import React from 'react';
import { HiOutlineTrash } from "react-icons/hi2";

function CartItem({ item, quantity, removeItem, decreaseQuantity, increaseQuantity, setQuantity }) {
  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 1) {
      setQuantity(item.uniqueID, value);
    }
  };

  const handleRemoveItem = () => {
    removeItem(item.id, item.uniqueID);
  };

  if (item.id === 10) {
    return (
      <div className="relative mb-4 w-full bg-zinc-800 rounded-md shadow p-4 outline outline-1 outline-white">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <h2 className="text-lg font-semibold">{item.name}</h2>
       
            <img className=' w-20 h-20' src={'https://tagit.s3.us-west-2.amazonaws.com/'+item.ServerID+'.png'}></img>
            <p className="text-sm text-gray-500 ml-4">Price: ${item.price}</p>
          </div>
          <button onClick={handleRemoveItem} className="text-red-500 text-sm ml-4"><HiOutlineTrash size={15} /></button>
        </div>
      </div>
    );
  }
  if (item.id === 4) {
    return (
      <div className="relative mb-4 w-full bg-zinc-800 rounded-md shadow p-4 outline outline-1 outline-white">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <h2 className="text-lg font-semibold">{item.name}</h2>
       
            <img className=' w-20 h-20' src={'https://tagit.s3.us-west-2.amazonaws.com/'+item.stickerServerID+'.png'}></img>
            <p className="text-sm text-gray-500 ml-4">Price: ${item.price}</p>
            <div className="flex items-center justify-center">
            <button className='p-2' onClick={() => decreaseQuantity(item.id, item.stickerServerID)}>-</button>
              <h1 className=' outline outline-1 rounded w-10 items-center justify-center flex'>{quantity}</h1>
              <button className='p-2' onClick={() => increaseQuantity(item.id, item.stickerServerID)}>+</button>
            </div>
          </div>
          <button onClick={handleRemoveItem} className="text-red-500 text-sm ml-4"><HiOutlineTrash size={15} /></button>
        </div>
      </div>
    );
  }
  return (
    <div className="relative mb-4 w-full bg-zinc-800 rounded-md shadow p-4 outline outline-1 outline-white">
      <button onClick={handleRemoveItem} className="absolute top-2 right-2 text-red-500 text-sm"><HiOutlineTrash size={15} /></button>
      
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <img src={require('../assets/'+item.image)} alt={item.name} className="w-16 h-16 object-cover rounded mr-4" />
          
          <div>
            <h2 className="text-lg font-semibold">{item.name}</h2>
            <p className="text-sm text-gray-500">Price: ${item.price}</p> {/* Display price here */}
            <div className="flex items-center justify-center">
              <button className='p-2' onClick={() => decreaseQuantity(item.id)}>-</button>
              <h1 className=' outline outline-1 rounded w-10 items-center justify-center flex'>{quantity}</h1>
              <button className='p-2' onClick={() => increaseQuantity(item.id)}>+</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
