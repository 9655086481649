import TagReader from "../TagReader/TagReader";

function Preview({ modules, editingModule, tagName, tagOwner, statsForNerds, infoBar, profilePictureInfo, background }) {
  var tagData = {
    modules: modules,
    editingModule: editingModule,
    tagName: tagName,
    tagOwner: tagOwner,
    statsForNerds: statsForNerds,
    infoBar: infoBar,
    profilePictureInfo: profilePictureInfo,
    background: background
  };
  
  // Inline styles with media queries
  const tagReaderStyle = {
    minWidth: '300px', // Ensures a minimum width for smaller screens
    width: '30vh',
    height: '60vh',
    minHeight: '100%',
    pointerEvents:'auto',
    overflowY: 'auto',
    background: 'white',
    borderRadius: '0.5rem', // rounded-md equivalent
    position: 'relative',
    
    // Use a CSS-in-JS approach to include media queries directly in your style object
    '@media (max-width: 600px)': { // Adjusts sizes for very small screens
      width: '50vw', // Increases width on smaller screens
      height: '100vw', // Adjust height accordingly to maintain aspect ratio
    },
    '@media (min-width: 601px) and (max-width: 800px)': { // Adjusts for slightly larger screens
      width: '40vw', // Adjusts width
      height: '80vw', // Adjusts height to maintain aspect ratio
    }
  };

  return (
    <TagReader tagData={tagData} style={tagReaderStyle} mode={'preview'} className="rounded-md relative overflow-y-auto scrollbar-custom bg-white" >
    </TagReader>
  );
}

export default Preview;
