import React, { useState, useEffect, useRef } from 'react';
import { getUser } from "../../service/AuthService";
import { QRCode } from 'react-qrcode-logo';
import axios from 'axios';
import { HiSave } from "react-icons/hi";


function Device({ Device, CreatedTags }) {
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const user = getUser();
    const imageRef = useRef(null);

    useEffect(() => {
        if (Device.deviceid) {
            setLoading(true);
            getDevice(Device.deviceid)
                .then((data) => {
                    setDeviceData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching device data:', error);
                });
        }
    }, [Device.deviceid]);

    const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/></svg>`)}`;

    const previewStyle = {
        backgroundColor: '#333',
        backgroundImage: `url("${svgGrid}")`,
        backgroundSize: '20px 20px',
        height: '50%',
        width: '100%',
        marginBottom: '20px',
        position: 'relative'
    };

    const handleDownload = () => {
        if (imageRef.current && imageRef.current.src) {
            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = imageRef.current.src;
            link.setAttribute('download', 'device-image.png'); // Set the file name for download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Image reference is not set or the image source is missing.');
            alert('Image is not available for download.');
        }
    };
    
    
    

    const renderContent = () => {
        if (loading) {
            return <div className='w-64 h-64 text-white p-8'>Loading...</div>;
        }

        if (Device.devicetype === 'sticker' || Device.devicetype === 'image') {
            return (
                <div className='relative w-64 h-64 text-white'>
                    {deviceData && deviceData.image ? (
                        <img className='h-full w-full p-4' ref={imageRef} src={deviceData.image} alt='Sticker' />
                    ) : (
                        <QRCode
                            qrStyle="dots"
                            bgColor='#93C5FD'
                            size={100}
                            ecLevel="H"
                            logoPadding={5}
                            logoHeight={15}
                            logoWidth={40}
                            removeQrCodeBehindLogo={true}
                            logoImage={require("../../assets/tagitblackwhite.png")}
                            value={`tagit.link/${user.username}/${Device.TagName}`}
                            eyeRadius={5}
                        />
                    )}
                </div>
            );
        }

        if (Device.devicetype === 'wristband') {
            return (
                <div className='w-64 h-64 text-white'>
                    <img className='h-full w-full p-8' src={require('../../assets/TagitSportsBand3.png')} alt='Wristband' />
                </div>
            );
        }

        if (Device.devicetype === 'ufo') {
            return (
                <div className='w-64 h-64 text-white'>
                    <img className='h-full w-full p-8' src={require('../../assets/ufo.png')} alt='UFO' />
                </div>
            );
        }

        return <div>Device</div>;
    };




    return (
        <div className="flex w-full h-full justify-center items-top">
            <div className="w-full">
                <div style={previewStyle} className='flex justify-center items-center' ref={imageRef}>
                    {renderContent()}
                </div>

                <div className="flex bg-zinc-900 rounded-md outline scrollbar-custom outline-1 outline-cyan-300 overflow-x-auto flex-nowrap p-1"
                    style={{
                        boxShadow: '0 0 5px #93C5FD, 0 0 5px #93C5FD, 0 0 5px #93C5FD, 0 0 5px #93C5FD',
                        maxWidth: '100%',
                        minHeight: '150px'
                    }}>
                    {CreatedTags.map((tag, index) => (
                        deviceData && tag.TagName === deviceData.sharedtagname ? (
                            <button key={index}
                                onClick={() => setDevice(user.username, deviceData.Id, tag.TagName)}
                                className="flex flex-col items-center justify-center bg-blue-600 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2">
                                <div>
                                    <QRCode
                                        qrStyle="dots"
                                        bgColor='#93C5FD'
                                        size={100}
                                        ecLevel="H"
                                        logoPadding={5}
                                        logoHeight={15}
                                        logoWidth={40}
                                        removeQrCodeBehindLogo={true}
                                        logoImage={require("../../assets/tagitblackwhite.png")}
                                        value={`tagit.link/${user.username}/${tag.TagName}`}
                                        eyeRadius={5}
                                    />
                                </div>
                                <div>{tag.NickName}<br /></div>
                                <div>Current<br /></div>
                            </button>
                        ) : (
                            <button key={index}
                                onClick={() => setDevice(user.username, deviceData.Id, tag.TagName)}
                                className="flex flex-col items-center justify-center bg-blue-400 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2">
                                <div>
                                    <QRCode
                                        qrStyle="dots"
                                        bgColor='#93C5FD'
                                        size={100}
                                        ecLevel="H"
                                        logoPadding={5}
                                        logoHeight={15}
                                        logoWidth={40}
                                        removeQrCodeBehindLogo={true}
                                        logoImage={require("../../assets/tagitblackwhite.png")}
                                        value={`tagit.link/${user.username}/${tag.TagName}`}
                                        eyeRadius={5}
                                    />
                                </div>
                                <div>{tag.NickName}<br /></div>
                            </button>
                        )
                    ))}
                </div>

                {(Device.devicetype === 'sticker' || Device.devicetype === 'image') && (
                    <div className="flex justify-center mt-4">
                        <button
                            className="bg-blue-500 text-white p-2 rounded-lg shadow-lg hover:bg-blue-700 transition-colors duration-300"
                            onClick={handleDownload}
                        >
                            <HiSave className="inline mr-2" size={20} />
                            Download Image
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Device;

function getDevice(deviceid) {
    const deviceReadUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/deviceread';

    return new Promise((resolve, reject) => {
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_KEY,
            },
        };
        const requestBody = {
            deviceid: deviceid,
        };

        axios.post(deviceReadUrl, requestBody, requestConfig)
            .then((response) => {
                const DeviceData = response.data.device;
                resolve(DeviceData);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        console.log('Unauthorized or Forbidden:', error.response.data.message);
                    } else {
                        console.log('Server error:', error.response.data.message);
                    }
                } else {
                    console.log('Network error');
                }
                reject(error);
            });
    });
}

function setDevice(username, deviceid, tagid) {
    const deviceReadUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/devicetagset';

    return new Promise((resolve, reject) => {
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_KEY,
            },
        };
        const requestBody = {
            deviceid: deviceid,
            sharedtagcreator: username,
            sharedtagname: tagid
        };

        axios.post(deviceReadUrl, requestBody, requestConfig)
            .then((response) => {
                const DeviceData = response.data.device;
                resolve(DeviceData);
                window.location.reload(); // Refresh the page
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        console.log('Unauthorized or Forbidden:', error.response.data.message);
                    } else {
                        console.log('Server error:', error.response.data.message);
                    }
                } else {
                    console.log('Network error');
                }
                reject(error);
            });
    });
}
