import React, { useState } from 'react';
import axios from 'axios';

function PollVote({ data, onShowResults,setErrorMessage  }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  async function votePoll(PollID, VoterIP, SelectedAnswer) {
    const getPollAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/votepoll';

    try {
      const response = await axios.post(getPollAPIURL, {
        PollID: PollID, // Corrected this part
        VoterIP: VoterIP,
        SelectedAnswer: SelectedAnswer
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_KEY
        }
      });

      return response.data; // Assume the data structure contains the poll details
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        setErrorMessage(error.response.data.message); // Set error message
      } else {
        console.log('Sorry, the server is down right now, please try again later.');
        console.log(error.response ? error.response.data.message : error.message);
      }
      throw error;
    }
  }

  const handleVote = async () => {
    if (selectedAnswer !== null) {
      console.log(`Selected Answer: ${selectedAnswer}`);

      try {
        // Fetch the user's IP address
        const response = await axios.get('https://api.ipify.org?format=json');
        const userIp = response.data.ip;

        const vote = await votePoll(data.id, userIp, selectedAnswer);
        // Handle vote response if needed
        console.log('Vote recorded:', vote);

        // Optionally show results after voting
        onShowResults();
      } catch (error) {
        onShowResults();
      }
    } else {
      console.log('No answer selected');
    }
  };

  const getEmbedLink = (watchLink) => {
    let videoId;
    let embedLink;
    if (watchLink?.includes("v=")) {
      videoId = watchLink.split("v=")[1];
      let ampersandPosition = videoId.indexOf("&");
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      embedLink = "https://www.youtube.com/embed/" + videoId;
    } else if (watchLink?.includes("youtu.be")) {
      videoId = watchLink.split("/").pop();
      embedLink = "https://www.youtube.com/embed/" + videoId;
    }
    return embedLink;
  };

  const getSpotifyEmbedLink = (spotifyLink) => {
    return spotifyLink?.replace("open.spotify.com", "open.spotify.com/embed");
  };

  const renderAnswerContent = (answer) => {
    switch (answer.type) {
      case 'text':
        return <span>{answer.data}</span>;
      case 'video':
        const videoLink = getEmbedLink(answer.data);
        return (
          <div className="w-full aspect-video">
            <iframe src={videoLink} frameBorder="0" allowFullScreen className="w-full h-full p-4"></iframe>
          </div>
        );
      case 'music':
        const musicLink = getSpotifyEmbedLink(answer.data);
        return (
          <div className="w-full scale-92 h-20 ">
            <iframe src={musicLink} width="100%" height="80" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
          </div>
        );
      default:
        return <span>{answer.data}</span>;
    }
  };

  return (
    <div className={`flex flex-col items-center w-full overflow-auto max-w-lg mx-auto ${data.ButtonStyle.fontClassName}`}>
      <h1 className="text-2xl font-bold mb-4">{data.title}</h1>
      <div className=' w-full h-0.5 ' style={{ background: data.ButtonStyle.fontColor }}></div>
      <div className="flex flex-col items-center w-full">
        {data.list.map((answer, index) => (
          <button
            key={index}
            className={`p-2 m-2 border rounded-lg flex items-center justify-between w-full ${selectedAnswer === index ? 'border-black' : 'border-gray-300'}`}
            style={selectedAnswer === index ? {} : { borderColor: data.ButtonStyle.fontColor }}
            onClick={() => setSelectedAnswer(index)}
          >
            {renderAnswerContent(answer)}
            <div className={`w-4 h-4 rounded-full border-2 ${selectedAnswer === index ? 'bg-white border-black' : 'border-gray-300'}`} ></div>
          </button>
        ))}
      </div>
      <div className="flex w-full justify-between mt-4 p-1">
        <button
          className=" p-4"
          onClick={onShowResults}
        >
          Show Results
        </button>
        <button
          className=" bg-green-500 text-white hover:bg-green-600 py-2 px-4 outline outline-1 rounded"
          onClick={handleVote}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default PollVote;
