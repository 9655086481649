import React, { useState, useRef, useEffect } from 'react';
import { getUser } from "../../service/AuthService";
import { QRCode } from 'react-qrcode-logo';
import { HiSave, HiX } from "react-icons/hi";
import { HiOutlinePencil } from "react-icons/hi";
import axios from 'axios';
import QRCodeCustomizer from './QRCodeCustomizer';
import { getTagitLogoSvgString } from '../../assets/QRCodeLogos/TagitLogo1'; // Import the function

import html2canvas from 'html2canvas';
function CreatedClickedTag(data) {
    console.log('Data from clicked: ', data);
    const user = getUser();
    const [nickname, setNickname] = useState('');
    const [showNicknameInput, setShowNicknameInput] = useState(false);

    const [logoHeight, setLogoHeight] = useState(30);
    const [logoWidth, setLogoWidth] = useState(80);
    const [qrBackground, setQRBackground] = useState('#a8c4e3');
    const [qrForeground, setQRForeground] = useState('#000000');
    const [centerImage, setCenterImage] = useState('');
    const qrCodeRef = useRef(null);



    useEffect(() => {
        const svgString = getTagitLogoSvgString(qrForeground);
        const base64Data = btoa(svgString);
        setCenterImage(`data:image/svg+xml;base64,${base64Data}`);
    }, [qrForeground]);


    const handleDownload = () => {
        if (qrCodeRef.current === null) {
            return;
        }
    
        html2canvas(qrCodeRef.current)
            .then((canvas) => {
                canvas.toBlob((blob) => {
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.download = 'qr-code.png';
                    link.href = url;
                    link.click();
                    URL.revokeObjectURL(url); // Clean up after download
                });
            })
            .catch((error) => {
                console.error('Error capturing screenshot:', error);
            });
    };
    const handleCopyLink = () => {
        let link = `tagit.link/${user.username}`;
        if (data.ClickedTag.TagName !== data.HomeTag) {
          link = `tagit.link/${user.username}/${data.ClickedTag.TagName}`;
        }
      
        try {
          navigator.clipboard.writeText(link).then(() => {
            alert('Link copied to clipboard!');
          }).catch(() => {
            // Fallback for unsupported browsers or errors
            fallbackCopyText(link);
          });
        } catch (err) {
          // Fallback for iOS or other environments where clipboard API might not work
          fallbackCopyText(link);
        }
      };
      
      const fallbackCopyText = (text) => {
        // Create a temporary input element
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
      
        // Select the text field's value
        input.select();
        input.setSelectionRange(0, 99999); // For mobile devices
      
        // Copy the text inside the text field
        document.execCommand('copy');
      
        // Remove the temporary input element
        document.body.removeChild(input);
      
       
      };
      
      
    const [showOnlyPreview, setShowOnlyPreview] = useState(false);
    const handleHiSaveClick = () => {
        setShowOnlyPreview(true);
    };

    const handleBackClick = () => {
        setShowOnlyPreview(false);
    };

    const handleNicknameSubmit = () => {
        console.log(nickname);
        const updateNickNameAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/updatenickname';
        console.log('setting home tag')
          const requestBody = {
            username: user.username,
            tagid:data.ClickedTag.TagName,
            nickname:nickname
          };
        
          axios.post(updateNickNameAPIURL, requestBody).then((response) => {
            console.log("HERE IS THE RESPONSE FOR YOUR TAG")
            console.log(response);
            window.location.reload();
          }).catch((error) =>{
            if(error.response.status === 401 || error.response.status === 403){
              console.log(error.response.data.message);
            }else{
              console.log('Sorry, the server is down right now, please try again later.');
              console.log(error.response.data.message);
            }
          })
        
        setShowNicknameInput(false);
        setNickname('');

    };

    const handleSetHomeTag = () => {
        const homeTagSetAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/hometagset';
        console.log('setting home tag')
          const requestBody = {
            username: user.username,
            tagid:data.ClickedTag.TagName
          };
        
          axios.post(homeTagSetAPIURL, requestBody).then((response) => {
            console.log("HERE IS THE RESPONSE FOR YOUR TAG")
            console.log(response);
            window.location.reload();
          }).catch((error) =>{
            if(error.response.status === 401 || error.response.status === 403){
              console.log(error.response.data.message);
            }else{
              console.log('Sorry, the server is down right now, please try again later.');
              console.log(error.response.data.message);
            }
          })
        
        };

        const handleDeleteTag = () => {
            const homeTagSetAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/deletetag';
            console.log('setting home tag')
              const requestBody = {
                TagCreator: user.username,
                TagName:data.ClickedTag.TagName
              };
            
              axios.post(homeTagSetAPIURL, requestBody).then((response) => {
                console.log("HERE IS THE RESPONSE FOR YOUR TAG")
                console.log(response);
                window.location.reload();
              }).catch((error) =>{
                if(error.response.status === 401 || error.response.status === 403){
                  console.log(error.response.data.message);
                }else{
                  console.log('Sorry, the server is down right now, please try again later.');
                  console.log(error.response.data.message);
                }
              })
            
            };
    const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

    const previewStyle = {
        backgroundColor: '#333', // Dark gray background
        backgroundImage: `url("${svgGrid}")`,
        backgroundSize: '20px 20px',
        height: '50%',
        width: '100%',
        marginBottom: '20px',
        position: 'relative' // Make the container relatively positioned
    };
 if(data.ClickedTag.TagName === data.HomeTag){
    return (
        <div className="flex w-full h-full justify-center items-top">
            <div className="w-full">
                <div style={previewStyle} className='flex justify-center items-center'       >
                    <div  ref={qrCodeRef}>
                    <QRCode
                 
                        qrStyle="dots"
                        fgColor={qrForeground}
                        bgColor={qrBackground}
                        size={200}
                        ecLevel="H"
                        logoPadding={5}
                        logoHeight={logoHeight}
                        logoWidth={logoWidth}
                        removeQrCodeBehindLogo={true}
                        logoImage={centerImage}
                        value={`tagit.link/${user.username}`}
                        eyeRadius={5}
                    />
                    </div>
                    {showOnlyPreview && ( <div>
                        <button className='absolute -bottom-2 -right-2 bg-zinc-950 p-2 rounded-l shadow-lg' onClick={handleBackClick}>
                                <HiX className="text-blue-300 hover:text-gray-600" size={30} />
                            </button>
                     
                    </div>)}

                    {!showOnlyPreview && (
                        <>
                            <button className='absolute -bottom-2 -right-2 bg-zinc-950 p-2 rounded-l shadow-lg' onClick={handleHiSaveClick}>
                                <HiSave className="text-blue-300 hover:text-gray-600" size={30} />
                            </button>
    
                            <a className='absolute -bottom-2 -left-2 bg-zinc-950 p-2 rounded-r shadow-lg' href={'/tageditor/' + data.ClickedTag.TagName}>
                                <HiOutlinePencil className="text-blue-300 hover:text-gray-600" size={30} />
                            </a>
                        </>
                    )}
                </div>
    
                {!showOnlyPreview && (
                    <>
                        <div className="mt-4 flex items-center">
                            <input
                                type="text"
                                value={`tagit.link/${user.username}`}
                                readOnly
                                className="border text-white text-xl font-semibold bg-black outline-3 outline outline-cyan-500 p-2 w-full rounded-l-lg"
                            />
                            <button
                                onClick={handleCopyLink}
                                className="p-2 rounded-r-lg border text-white text-xl font-semibold bg-sky-500 outline-3 outline hover:bg-cyan-800 outline-cyan-500"
                                style={{ boxShadow: '0 0 6px #67E8F9 ' }}
                            >
                                Copy
                            </button>
                        </div>
    
                        {showNicknameInput ? (
                            <div className="mt-4 flex items-center">
                                <input
                                    type="text"
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}
                                    maxLength="10"
                                    className="border p-2 w-full rounded-lg"
                                />
                                <button
                                    onClick={handleNicknameSubmit}
                                    className="ml-2 p-2 bg-green-500 text-white rounded-lg hover:bg-green-700"
                                >
                                    ✔️
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={() => setShowNicknameInput(true)}
                                className="mt-4 p-1 border text-white text-xl font-semibold bg-sky-500 outline-3 outline hover:bg-cyan-800 outline-cyan-500 w-full rounded-lg"
                                style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                            >
                                Rename Tag
                            </button>
                        )}
    
                        <div
                            className="mt-4 p-1 flex justify-center items-center border text-white text-xl font-semibold bg-cyan-800 outline-3 outline hover:bg-cyan-800 outline-cyan-500 w-full rounded-lg"
                            style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                        >
                            Set as HomeTag
                        </div>
    
                        <button
                            onClick={() => handleDeleteTag()}
                            className="mt-4 p-1 border text-white text-xl font-semibold bg-red-500 outline-3 outline hover:bg-red-800 outline-cyan-500 w-full rounded-lg"
                            style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                        >
                            Delete tag
                        </button>


                   
                    </>
                )}
                    
{showOnlyPreview && ( <div className=' relative w-full h-52 rounded-md outline outline-1 outline-blue-300 text-xl text-white'>
                        
                       <QRCodeCustomizer
                        setQRBackground={setQRBackground} 
                        setQRForeground={setQRForeground} 
                        qrForeground={qrForeground} 
                        qrBackground={qrBackground} 
                        setCenterImage = {setCenterImage}
                        centerImage={centerImage} 
                        handleDownload={handleDownload}
                        setLogoHeight={setLogoHeight}
                        setLogoWidth={setLogoWidth}
                        />
                        </div>)}
            </div>
        </div>
    );
    

 }
    return (
        <div className="flex w-full h-full justify-center items-top">
            <div className="w-full">
                <div style={previewStyle} className='flex justify-center items-center'>
                <div  ref={qrCodeRef}>
                    <QRCode
                        qrStyle="dots"
                        bgColor={qrBackground}
                        fgColor={qrForeground}
                        size={200}
                        ecLevel="H"
                        logoPadding={5}
                        logoHeight={logoHeight}
                        logoWidth={logoWidth}
                        removeQrCodeBehindLogo={true}
                        logoImage={centerImage}
                        value={`tagit.link/${user.username}/${data.ClickedTag.TagName}`}
                        eyeRadius={5}
                    />
                    </div>
                    {showOnlyPreview && ( <div>
                        <button className='absolute -bottom-2 -right-2 bg-zinc-950 p-2 rounded-l shadow-lg' onClick={handleBackClick}>
                                <HiX className="text-blue-300 hover:text-gray-600" size={30} />
                            </button>
                     
                    </div>)}
                    {!showOnlyPreview && (
                        <>
                    <button className='absolute -bottom-2 -right-2 bg-zinc-950 p-2  rounded-l shadow-lg ' onClick={ handleHiSaveClick}>
                        <HiSave className="text-blue-300 hover:text-gray-600" size={30} />
                    </button>
                    <a className='absolute -bottom-2 -left-2 bg-zinc-950 p-2  rounded-r shadow-lg '  href={'/tageditor/'+data.ClickedTag.TagName}>
                    <HiOutlinePencil className="text-blue-300 hover:text-gray-600" size={30}  />
                </a>
                </>
                    )}
                </div>
                
                {!showOnlyPreview && (
                    <>
                <div className="mt-4 flex items-center">
                    <input
                        type="text"
                        value={`tagit.link/${user.username}/${data.ClickedTag.TagName}`}
                        readOnly
                        className="border text-white text-xl font-semibold bg-black outline-3 outline outline-cyan-500  p-2 w-full rounded-l-lg"
                      
                    />
                    <button
                        onClick={handleCopyLink}
                        className=" p-2  rounded-r-lg border text-white text-xl font-semibold bg-sky-500 outline-3 outline hover:bg-cyan-800  outline-cyan-500"
                        style={{ boxShadow: '0 0 6px #67E8F9 ' }}
                    >
                        Copy
                    </button>
                </div>
                {showNicknameInput ? (
                    <div className="mt-4 flex items-center">
                        <input
                            type="text"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            maxLength="10"
                            className="border p-2 w-full rounded-lg"
                        />
                        <button
                            onClick={handleNicknameSubmit}
                            className="ml-2 p-2 bg-green-500 text-white rounded-lg hover:bg-green-700"
                        >
                            ✔️
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => setShowNicknameInput(true)}
                        className="mt-4 p-1 border text-white text-xl font-semibold bg-sky-500 outline-3 outline hover:bg-cyan-800  outline-cyan-500   w-full rounded-lg"
                        style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                    >
                        Rename Tag
                    </button>
                )}
                <button
                        onClick={() => handleSetHomeTag()}
                        className="mt-4 p-1 border text-white text-xl font-semibold bg-sky-500  outline-3 outline hover:bg-cyan-800  outline-cyan-500  w-full rounded-lg"
                        style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                    >
                    Set as HomeTag
                </button>
                <button
                        onClick={() => handleDeleteTag()}
                        className="mt-4 p-1 border text-white text-xl font-semibold bg-red-500  outline-3 outline hover:bg-red-800  outline-cyan-500   w-full rounded-lg"
                        style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                    >
                    Delete tag
                </button>
                      </>
                    )}

{showOnlyPreview && ( <div className=' relative w-full h-52 rounded-md outline outline-1 outline-blue-300 text-xl text-white'>
                    <QRCodeCustomizer
                        setQRBackground={setQRBackground} 
                        setQRForeground={setQRForeground} 
                        qrForeground={qrForeground} 
                        qrBackground={qrBackground} 
                        setCenterImage = {setCenterImage}
                        centerImage={centerImage} 
                        handleDownload={handleDownload}
                        setLogoHeight={setLogoHeight}
                        setLogoWidth={setLogoWidth}
                        />
                       
                       </div>)}

            </div>
        </div>
    );
}

export default CreatedClickedTag;
