import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ScrollDownAnimation = ({ backgroundData }) => {
  const [numRows, setNumRows] = useState(0);
  const gridSize = 4; // For a 4x4 grid
  const movementDuration = 3; // Duration for the animation

  useEffect(() => {
    const updateDimensions = () => {
      const viewportHeight = window.innerHeight;
      const imageHeight = viewportHeight / gridSize; // Height of each image
      const rowsNeeded = Math.ceil(viewportHeight / imageHeight); // Rows needed to fill the screen
      setNumRows(rowsNeeded * 5); // Increase the number of rows to ensure continuous coverage
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const getBackgroundImageStyle = (imagePath) => {
    // Check if imagePath is a URL
    if (typeof imagePath === 'string' && imagePath.startsWith('http')) {
      return `url(${imagePath})`;
    }
    // Otherwise, treat it as a local module path and require it
    return `url(${require(`../../assets/backgroundForeground/${imagePath}`)})`;
  };

  const createGrid = () => {
    const grid = [];
    const imageSize = 100 / gridSize; // Width of each image in percentage
    const imageHeight = 100 / (numRows / 2.5); // Adjust the division factor to ensure better coverage

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < gridSize; col++) {
        const index = row * gridSize + col;
        grid.push(
          <motion.div
            key={index}
            initial={{ y: '-100%' }} // Starts from above the screen, adjust if necessary
            animate={{ y: '0%' }} // Moves to its original position
            exit={{ y: '100%' }} // Moves out of the screen at the bottom
            transition={{
              duration: movementDuration,
              ease: "linear",
              repeat: Infinity,
              repeatType: 'loop',
            }}
            style={{
              backgroundImage: getBackgroundImageStyle(backgroundData.backgroundSelection.backgroundForeground),
              backgroundSize: 'cover',
              position: 'absolute',
              width: `${imageSize}%`, // Width based on grid size
              height: `${imageHeight}%`, // Adjusted height for each image row
              left: `${col * imageSize}%`,
              top: `${row * imageHeight - imageHeight}%`, // Adjust the spawning point
              zIndex: 0,
            }}
          />
        );
      }
    }
    return grid;
  };

  return <div className="relative w-full h-full overflow-hidden">{createGrid()}</div>;
};

export default ScrollDownAnimation;
