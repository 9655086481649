module.exports = {
    getUser: function() {
      let user = sessionStorage.getItem('user');
      if (!user) {
        user = localStorage.getItem('user');
      }
      if (user === 'undefined' || !user) {
        return null;
      } else {
        return JSON.parse(user);
      }
    },
    getToken: function() {
      let token = sessionStorage.getItem('token');
      if (!token) {
        token = localStorage.getItem('token');
      }
      return token;
    },
    setUserSession: function(user, token, rememberMe = false) {
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('token', token);
      if (rememberMe) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
      }
    },
    resetUserSession: function() {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
  }
  