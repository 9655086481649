import React from 'react';

function ProfilePicture({ profilePictureData }) {
  const {
    profilePicture,
    profilePictureLocation, // Determines alignment
    infoBarColor = '#FFFFFF', // Default white background
    backgroundOpacity = 64, // Assuming full opacity by default
  } = profilePictureData;

  // Convert hex color to RGB
  const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `${r},${g},${b}`;
  };

  const opacity = Math.max(0, Math.min(100, backgroundOpacity)) / 100;
  const rgbColor = hexToRgb(infoBarColor);
  const backgroundColor = `rgba(${rgbColor}, ${opacity})`;

  // Determine container alignment and padding based on profilePictureLocation
  const alignmentClasses = (() => {
    switch (profilePictureLocation) {
      case 'left':
        return 'justify-start pl-4'; // Align to the left with padding
      case 'right':
        return 'justify-end pr-4'; // Align to the right with padding
      default:
        return 'justify-center'; // Default to center
    }
  })();

  return (
    <div className={`flex ${alignmentClasses} items-center`} style={{ backgroundColor, width: '100%' }}>
      {profilePicture && (
        <img
          src={profilePicture}
          alt="Profile"
          className="rounded-full p-2 object-cover"
          style={{ width: '40%' }} // Adjust size as needed
        />
      )}
    </div>
  );
}

export default ProfilePicture;
