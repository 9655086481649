import React from "react";
import { HiUser } from "react-icons/hi2";

function TotalViews({ totalViews }) {
    return (
        <div className="w-full h-full outline outline-1 bg-zinc-950 rounded-lg p-4 overflow-auto">
        
            <div className="flex flex-col items-center justify-center h-full">
            <div className="relative">
                 
                    <div className="absolute inset-0 flex items-center justify-center">
                        <HiUser size={80} className="text-blue-300  outline outline-2  rounded-full outline-white " style={{ filter: 'blur(3px)', color: '#67E8F9' }} />
                    </div>
                    <HiUser size={80} className="text-white outline outline-2  rounded-full outline-blue-300 " />
                </div>
                <h1 className=" text-xl py-2 font-semibold text-white" >Views: </h1>
                <h1 className=" text-3xl  font-semibold text-white">{totalViews} </h1>
            </div>
        </div>
    );
}

export default TotalViews;
