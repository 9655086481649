import React from 'react';
import AudioIcon from '../../assets/tagItemButtons/audio.gif';
import { HiTrash, HiOutlinePencil, HiOutlineArrowUp, HiOutlineArrowDown } from "react-icons/hi";
import AudioPlayer from '../../TagReader/Audio/AudioPlayer';
function AudioModule({ data, onEdit, onDelete, onMoveUp, onMoveDown }) {
// Utility function to get Spotify embed link
const getSpotifyEmbedLink = (spotifyLink) => {
  let embedLink = spotifyLink.replace("open.spotify.com", "open.spotify.com/embed");
  return embedLink;
};
console.log("Audio Data", data);
if(data.audioType === "spotify"){
  return(
    <div className="p-4 border rounded text-white relative flex">
    <div className='flex flex-col items-center mr-4 outline hover:bg-cyan-400  p-1 outline-1 rounded-xl outline-cyan-300'>
      <button onClick={() => onMoveUp()} className="mb-10 my-2 rounded-lg hover:bg-cyan-900 w-full h-full ">
        <HiOutlineArrowUp />
      </button>
      <button onClick={() => onMoveDown()} className='my-2 rounded-lg hover:bg-cyan-900' >
        <HiOutlineArrowDown />
      </button>
    </div>
   
{/* Centered Text with pointer-events-none */}
<div className="absolute inset-0 flex justify-center sm:ml-0 ml-8 items-center" style={{pointerEvents: 'none'}}>
<div className='flex flex-col items-center justify-center'> {/* Ensure you include 'flex' here */}
<span className="sm:text-lg text-sm scale-50 ">

      <iframe
        src={getSpotifyEmbedLink(data.url)}
        width="100%"
        height="80"
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
      ></iframe>   
   
</span>
</div>
</div>



    <div className='flex flex-col justify-between ml-auto'>
      <button onClick={() => onEdit(data)} className="self-end mb-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
        <HiOutlinePencil size={20} />
      </button>
      <button onClick={() => onDelete()} className="self-end mt-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
        <HiTrash size={20} />
      </button>
    </div>
  </div>
 );

}
else{
    return (
      <div className="p-4 border rounded text-white relative flex">
        <div className='flex flex-col items-center mr-4 outline hover:bg-cyan-400  p-1 outline-1 rounded-xl outline-cyan-300'>
          <button onClick={() => onMoveUp()} className="mb-10 my-2 rounded-lg hover:bg-cyan-900 w-full h-full ">
            <HiOutlineArrowUp />
          </button>
          <button onClick={() => onMoveDown()} className='my-2 rounded-lg hover:bg-cyan-900' >
            <HiOutlineArrowDown />
          </button>
        </div>
        <div className='flex flex-col items-center '>
          <img src={AudioIcon} alt="" className="sm:w-16  mt-4  sm:h-16 w-10 h-10 "/>
          <h1>Audio</h1>
        </div>
{/* Centered Text with pointer-events-none */}
<div className="absolute inset-0 flex justify-center sm:ml-0 ml-8 items-center" style={{pointerEvents: 'none'}}>
  <div className='flex flex-col items-center justify-center'> {/* Ensure you include 'flex' here */}
    <span className="sm:text-lg text-sm scale-70 ">
    <AudioPlayer src={data.url} image={data.image} title={data.title} font={data.ButtonStyle.fontClassName} type={"regular"} />
    </span>
  </div>
</div>



        <div className='flex flex-col justify-between ml-auto'>
          <button onClick={() => onEdit(data)} className="self-end mb-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
            <HiOutlinePencil size={20} />
          </button>
          <button onClick={() => onDelete()} className="self-end mt-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
            <HiTrash size={20} />
          </button>
        </div>
      </div>
    );
}
}
export default AudioModule;
