import LinkModuleCreator from './Link/LinkModuleCreator';
import LinkModule from './Link/LinkModule'; // Import the display component
import LinkIcon from '../assets/tagItemButtons/link.gif';
import ImageModuleCreator from './Image/ImageModuleCreator';
import ImageModule from './Image/ImageModule';
import ImageIcon from '../assets/tagItemButtons/image.gif';
import VideoModule from './Video/VideoModule';
import VideoModuleCreator from './Video/VideoModuleCreator';
import VideoIcon from '../assets/tagItemButtons/video.gif';
import AudioModuleCreator from './Audio/AudioModuleCreator';
import AudioModule from './Audio/AudioModule';
import AudioIcon from '../assets/tagItemButtons/audio.gif';
import ListModuleCreator from './List/ListModuleCreator';
import ListModule from './List/ListModule';
import ListIcon from '../assets/tagItemButtons/list.gif';
import PollModuleCreator from './Poll/PollModuleCreator';
import PollModule from './Poll/PollModule';
import PollIcon from '../assets/tagItemButtons/poll.gif';
import TextModuleCreator from './Text/TextModuleCreator';
import TextModule from './Text/TextModule';
import TextIcon from '../assets/tagItemButtons/text.gif';

import ARModuleCreator from './AR/ARModuleCreator';
import ARModule from './AR/ARModule';


const moduleTypes = {
  link: {
    displayName: "Link",
    category:"Information",
    creatorComponent: LinkModuleCreator,
    displayComponent: LinkModule, 
    icon:LinkIcon,
    // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },
  image: {
    displayName: "Image",
    category:"Media",
    icon:ImageIcon,
    creatorComponent: ImageModuleCreator,
    displayComponent: ImageModule, // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },
  video: {
    displayName: "Video",
    category:"Media",
    icon:VideoIcon,
    creatorComponent: VideoModuleCreator,
    displayComponent: VideoModule, // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },
  audio: {
    displayName: "Audio",
    category:"Media",
    icon:AudioIcon,
    creatorComponent: AudioModuleCreator,
    displayComponent: AudioModule, // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },
  list: {
    displayName: "List",
    category:"Information",
    icon:ListIcon,
    creatorComponent: ListModuleCreator,
    displayComponent: ListModule, // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },
  poll: {
    displayName: "Poll",
    category:"Interactive",
    icon:PollIcon,
    creatorComponent: PollModuleCreator,
    displayComponent: PollModule, // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },  
  text: {
    displayName: "Text",
    category:"Information",
    icon:TextIcon,
    creatorComponent: TextModuleCreator,
    displayComponent: TextModule, // Add the display component here
    // Add more properties as needed, like validation functions, etc.
  },
  
  // Define other module types similarly
};

export default moduleTypes;
