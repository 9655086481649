import React from 'react';
import './Fonts.css'; // Update with your path to the Fonts.css file

const FontLoader = () => (
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Kanit&family=Lora&family=Montserrat&family=Mukta&family=Open+Sans&family=Oswald&family=PT+Sans+Narrow&family=Poor+Story&family=Poppins&family=Roboto&family=Roboto+Condensed&family=Roboto+Mono&family=Rubik+Bubbles&family=Work+Sans&family=Xanh+Mono&family=Orbitron&display=swap" rel="stylesheet" />
  </div>
);

export default FontLoader;
