import React, { useState, useEffect } from 'react';
import Preview from './Preview';
import Header from '../Header/Header';
import ModuleSelectionModal from './ModuleSelectionModal';
import moduleTypes from './moduleTypes';
import BackgroundSelector from './BackgroundSelector';
import InfoBarEditor from './InfoBarEditor';
import ProfilePictureInfoEditor from './ProfilePictureInfoEditor';
import { getUser } from '../service/AuthService';
import axios from "axios";
import loading from '../assets/loading.gif';
import { useNavigate } from 'react-router-dom';

function TagEditor() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modules, setModules] = useState([]);
  const [editingModule, setEditingModule] = useState(null);
  const [recentButtonStyles, setRecentButtonStyles] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [tagNotFound, setTagNotFound] = useState(false);
  const navigate = useNavigate();

  const [infoBar, setInfoBar] = useState({
    name: '',
    title: '',
    phone: '',
    email: '',
    backgroundOpacity: "60",
    infoBarColor:'#FFFFFF',
    infoBarButtonColor:'#1a1a1a',
    location: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'United States',
    },
    displayLocation: 'none',
    displayType: 'buttons',
    
  });

  const [profilePictureInfo, setProfilePictureInfo] = useState({
    generalLocation: 'top',
    profilePicture: '',
    displayProfilePicture: false,
    profilePictureLocation:'center'
  });

  const [background, setBackground] = useState({
    backgroundSelection: {
      backgroundID: 0,
      backgroundForeground: '2.png',
      backgroundBackground: '#2efff1',
    },
    backgroundColor1: "#4ed4ef",
    backgroundMotion: 'rain',
    backgroundSpeed: '1x',
    backgroundColorFuser: 'fade',
  });

  const [isPreviewVisible, setIsPreviewVisible] = useState(!isMobile);

  const user = getUser();
  const tagReader = user ? user.username : '';
  const urldata = window.location.href.split("/");
  const tagname = urldata[4];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const urldata = window.location.href.split("/");
        const tagcreator = urldata[3];
        const tagname = urldata[4];
        const user = getUser();
        const tagReader = user.username;

      
        console.log('data being sent! ', tagReader.username, tagname);
        const data = await getData(tagReader, tagname,tagReader );

        if (!data || !data.data || !data.data.Tag || !data.data.Tag.TagContents) {
          setTagNotFound(true);
        } else {
          const tagContents = data.data.Tag.TagContents;
          setModules(tagContents.modules || []);
          setInfoBar(tagContents.infoBar || {});
          setProfilePictureInfo(tagContents.profilePictureInfo || {});
          setBackground(tagContents.background || {});
        }
        setLoadingData(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setTagNotFound(true);
        setLoadingData(false);
      }
    };

    fetchData();
  }, [tagReader]);

  useEffect(() => {
    const handleResize = () => {
      const currentIsMobile = window.innerWidth <= 768;
      setIsMobile(currentIsMobile);
      if (!currentIsMobile) {
        setIsPreviewVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
    setEditingModule(null);
  };

  const togglePreview = () => {
    setIsPreviewVisible(!isPreviewVisible);
  };

  
  const usernameToNumeric = (username) => {
    return username.split('').map(char => char.charCodeAt(0)).join('');
  };

  const addModule = (moduleData) => {


    const user = getUser();
    const usernameNumeric = usernameToNumeric(user.username);

    const newModule = { ...moduleData, id:  parseInt(`${usernameNumeric}${Date.now()}`, 10) };
  
    const updatedModules = editingModule && editingModule.id 
      ? modules.map(mod => mod.id === editingModule.id ? newModule : mod)
      : [...modules, newModule];
  
    setModules(updatedModules);
  
    if (moduleData.ButtonStyle && Object.keys(moduleData.ButtonStyle).length !== 0) {
      setRecentButtonStyles(prevStyles => [moduleData.ButtonStyle, ...prevStyles.slice(0, 4)]);
    }
  
    setEditingModule(null);
    setIsModalOpen(false);
  };
  







  const handleFinishSave = () => {
    const createTagAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/edittag';
    const user = getUser();
    const tagContents = {
      modules: modules,
      infoBar: infoBar,
      profilePictureInfo: profilePictureInfo,
      background: background,
    };

    const requestConfig = {
      headers: {
        'x-api-key': process.env.REACT_APP_KEY
      }
    };
    const requestBody = {
      username: user.username,
      tagname: tagname,
      tagcontents: tagContents
    };

    axios.post(createTagAPIURL, requestBody, requestConfig).then((response) => {

      console.log("Tag saved successfully", response);
      navigate('/dashboard');

    }).catch((error) => {
      console.error('Error saving tag:', error);
    });
  };

  const handleModuleSelect = (type) => {
    setEditingModule({ type });
    setIsModalOpen(false);
  };

  const handleEditModule = (module) => {
    setEditingModule(module);
  };

  const handleCancelEdit = () => {
    setEditingModule(null);
  };

  const deleteModule = (moduleId) => {
    setModules(modules.filter(module => module.id !== moduleId));
  };

  const moveModuleUp = (moduleId) => {
    setModules(prevModules => {
      const index = prevModules.findIndex(mod => mod.id === moduleId);
      if (index > 0) {
        [prevModules[index - 1], prevModules[index]] = [prevModules[index], prevModules[index - 1]];
      }
      return [...prevModules];
    });
  };

  const moveModuleDown = (moduleId) => {
    setModules(prevModules => {
      const index = prevModules.findIndex(mod => mod.id === moduleId);
      if (index < prevModules.length - 1) {
        [prevModules[index + 1], prevModules[index]] = [prevModules[index], prevModules[index + 1]];
      }
      return [...prevModules];
    });
  };

  const renderModules = () => {
    return modules.map((module) => {
      const ModuleComponent = moduleTypes[module.type]?.displayComponent;
      return ModuleComponent ? (
        <ModuleComponent
          key={module.id}
          data={module}
          onEdit={() => handleEditModule(module)}
          onDelete={() => deleteModule(module.id)}
          onMoveUp={() => moveModuleUp(module.id)}
          onMoveDown={() => moveModuleDown(module.id)}
        />
      ) : null;
    });
  };

  return loadingData ? (
    <div className="w-full h-full flex justify-center items-center bg-white">
      <img src={loading} alt="Loading..." style={{ width: '100px', height: '100px' }} />
    </div>
  ) : tagNotFound ? (
    <div className="w-full h-full flex justify-center items-center bg-white">
      <h1>Tag not found</h1>
    </div>
  ) : (
    <div className="fixed inset-0 bg-zinc-700 overflow-y-auto">
      <Header />

      {isMobile && (
        <div className="flex justify-center items-start ">
          <div className="w-full  h-[600px] max-w-xl scale-35 -translate-y-40  rounded-lg -mb-80">
            <Preview
              modules={modules}
              editingModule={editingModule}
              infoBar={infoBar}
              profilePictureInfo={profilePictureInfo}
              background={background}
            />
          </div>
        </div>
      )}

      <div className={`flex ${isMobile ? 'flex-col' : 'h-full'} main-content`} style={{ height: isMobile ? 'auto' : `calc(100vh - 3rem)` }}>
        {!isMobile && (
          <div className='w-1/4 p-4 overflow-y-auto bg-zinc-800 outline outline-1 scrollbar-custom outline-black'>
            <InfoBarEditor infoBar={infoBar} setInfoBar={setInfoBar} />
            <br />
            <ProfilePictureInfoEditor profilePictureInfo={profilePictureInfo} setProfilePictureInfo={setProfilePictureInfo} infoBarSettings={infoBar} />
            <br />
            <BackgroundSelector background={background} setBackground={setBackground} />
          </div>
        )}

        {!isMobile && isPreviewVisible && (
          <div className="flex-1 justify-center items-center flex">
            <div className="h-[500px] max-w-xl 2k:-translate-y-20 rounded-lg">
              <Preview
                modules={modules}
                editingModule={editingModule}
                infoBar={infoBar}
                profilePictureInfo={profilePictureInfo}
                background={background}
              />
            </div>
          </div>
        )}

        <div className={`${isMobile ? 'w-full' : 'sm:w-1/4'} bg-zinc-800 outline outline-1 outline-black scrollbar-custom p-4 overflow-y-auto`}>
          <div className="">
            <button className="px-8 py-2 w-full bg-blue-300 hover:bg-cyan-600 text-black rounded" onClick={openModal}>
              <h1 className="text-lg font-semibold">+ Add Tag Item</h1>
            </button>
          </div>
          <div className='relative top-2 rounded scrollbar-custom overflow-y-auto flex h-5/6 w-full outline outline-1 outline-cyan-300'>
            <div className='w-full h-5/6 p-2'>{renderModules()}</div>
          </div>
          {!isMobile && (
            <div className='w-full py-6'>
              <button className="py-6 w-full bg-green-400 hover:bg-green-600 text-white rounded" onClick={handleFinishSave}>
                <h1 className="text-lg font-semibold">Save Tag</h1>
              </button>
            </div>
          )}
        </div>

        {isMobile && (
          <div className='w-full p-4 overflow-y-auto bg-zinc-800'>
            <InfoBarEditor infoBar={infoBar} setInfoBar={setInfoBar} />
            <br />
            <ProfilePictureInfoEditor profilePictureInfo={profilePictureInfo} setProfilePictureInfo={setProfilePictureInfo} infoBarSettings={infoBar} />
            <br />
            <BackgroundSelector background={background} setBackground={setBackground} />
          </div>
        )}
        {isMobile && (
          <div className='w-full p-2 overflow-y-auto'>
            <button className="px-8 py-4 w-full bg-green-400 hover:bg-green-600 text-black rounded" onClick={handleFinishSave}>
              <h1 className="text-lg font-semibold">Save Tag</h1>
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <ModuleSelectionModal closeModal={() => setIsModalOpen(false)} onModuleSelect={handleModuleSelect} />
      )}

      {editingModule && moduleTypes[editingModule.type]?.creatorComponent && (
        React.createElement(moduleTypes[editingModule.type].creatorComponent, {
          key: editingModule.id || Date.now(),
          initialData: editingModule,
          onSave: addModule,
          onCancel: handleCancelEdit,
          recentButtonStyles: recentButtonStyles,
        })
      )}
    </div>
  );
}

export default TagEditor;

async function getData(tagcreator, tagname, tagreader) {
  const getTagAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/gettag';

  try {
    const response = await axios.post(getTagAPIURL, {
      TagCreator: tagreader,
      TagName: tagname,
      TagCollector: tagreader
    }, {
      headers: {
        'x-api-key': process.env.REACT_APP_KEY
      }
    });

    return response;
  } catch (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      console.log(error.response.data.message);
    } else {
      console.log('Sorry, the server is down right now, please try again later.');
      console.log(error.response ? error.response.data.message : error.message);
    }
    throw error;
  }
}
