import React from 'react';
import ScrollRightAnimation from './ScrollRightAnimation';
import ScrollDownAnimation from './ScrollDownAnimation'; // Import the new component
import ScrollLeftAndRightAnimation from './ScrollLeftAndRightAnimation';
import RainAnimation from './RainAnimation';
const createAnimatedBackground = (backgroundData) => {
  const motionType = backgroundData.backgroundMotion || 'scroll right';

  return (
    <div className="w-full h-full">
      {motionType === 'scroll right' && <ScrollRightAnimation backgroundData={backgroundData} />}
      {motionType === 'scroll down' && <ScrollDownAnimation backgroundData={backgroundData} />}
      {motionType === 'scroll left and right' && <ScrollLeftAndRightAnimation backgroundData={backgroundData} />}
      {motionType === 'rain' && <RainAnimation backgroundData={backgroundData} />}
    </div>
  );
};

export default createAnimatedBackground;
