import React from 'react';
import ListOfButtons from '../../TagCreator/ListOfButtons';
function ImageComponent({data}) {
const listOfButtons = ListOfButtons();
var linkDivSetup = <div></div>
console.log("Data!",data);

const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor
  };
  var linkDivSetup = <div></div>

  return (linkDivSetup = 
    <div id='tagModule' className="inline-flex items-center justify-center w-full  outline outline-2" style={Object.assign({}, baseStyles, additionalStyles)}> 
            <div className='p-4'>
                <img src={data.url} alt="Module Content" />
            </div>
        </div>

  );
}


export default ImageComponent;




