import React, { useState } from 'react';
import { HiHome } from "react-icons/hi";
import { QRCode } from 'react-qrcode-logo';
import { HiX, HiOutlineUser } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import CreatedClickedTag from './CreatedClickedTag';
import CollectedClickedTag from './CollectedClickedTag';
import { getUser } from "../../service/AuthService";
function Tags({ createdTags, collectedTags, homeTag }) {
    const [collectedClickedTag, setCollectedClickedTag] = useState(null);
    const [createdClickedTag, setCreatedClickedTag] = useState(null);
    const navigate = useNavigate(); // Use navigate for navigation
    const user = getUser();

    const handleTagClick = (tag, type) => {
        if (type === 'created'){
            setCreatedClickedTag(tag);
        }
        else{
            setCollectedClickedTag(tag);
        }
    };

    const handleClosePopup = () => {
        setCreatedClickedTag(null);
        setCollectedClickedTag(null);
    };

    const handleCreateButtonClick = () => {
        navigate('/tagcreator'); // Navigate to the /create tab
    };
    return (
        <div className="w-full h-full max-w-full max-h-full outline outline-1 overflow-auto bg-zinc-950 outline-blue-300 rounded-lg scrollbar-custom">
         <div className='flex w-full h-full items-center justify-center py-0 lg:py-0 md:py-0 xl:py-0 2k:pt-16'>
            <div className='flex-col w-5/6 h-full '>
            <h1 className="text-lg py-2 font-semibold text-white ">Created tags:</h1>
            <div className="flex bg-zinc-900 rounded-md outline scrollbar-custom outline-1 outline-cyan-300 overflow-x-auto flex-nowrap p-1"
         style={{
            boxShadow: '0 0 5px #93C5FD, 0 0 5px #93C5FD, 0 0 5px #93C5FD, 0 0 5px #93C5FD',
            maxWidth: '100%',
            minHeight: '150px' // Set a minimum height to maintain the bounding box height
          }}>
            {createdTags.map((tag, index) => (
                tag.TagName === homeTag ? (
                <button key={index}
                style={{ boxShadow: '0 0 15px #93C5FD'}}
                    onClick={() => handleTagClick(tag,'created')}
                    className="flex flex-col items-center justify-center bg-blue-400 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2">
                    <div>
                    <QRCode
                    qrStyle="dots"
                    bgColor='#a8c4e3'
                    size={100}
                    ecLevel="H"
                    logoPadding={5}
                    logoHeight={15}
                    logoWidth={40}
                    removeQrCodeBehindLogo={true}
                    logoImage={require("../../assets/tagitblackwhite.png")}
                    value={`tagit.link/${user.username}/${tag.TagName}`}
                    eyeRadius={5}
                    />
                    </div>
                    <div className=' flex'> <HiHome className='my-1' />{ tag.NickName}<br /></div>
                </button>
            ) : (  <button key={index}
                onClick={() => handleTagClick(tag,'created')}
                className="flex flex-col items-center justify-center bg-blue-400 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2">
                <div>
                <QRCode
                qrStyle="dots"
                bgColor='#93C5FD'
                size={100}
                ecLevel="H"
                logoPadding={5}
                logoHeight={15}
                logoWidth={40}
                removeQrCodeBehindLogo={true}
                logoImage={require("../../assets/tagitblackwhite.png")}
                value={`tagit.link/${user.username}/${tag.TagName}`}
                eyeRadius={5}
                />
                </div>
                <div>{tag.NickName}<br /></div>
            </button>)
))}

                        <button
                            onClick={handleCreateButtonClick}
                            className="flex flex-col items-center justify-center px-4  bg-blue-400 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2"
                            style={{ width: '100px', height: '144px' }}>
                            <div className="text-5xl p-4">+</div>
                        </button>
                    </div>


                <h1 className="text-lg py-2 font-semibold text-white">Collected tags:</h1>
           <div
  className="flex bg-zinc-900 rounded-md outline outline-1 scrollbar-custom outline-cyan-300 overflow-x-auto flex-nowrap p-1 "
  style={{
    boxShadow: '0 0 5px #93C5FD, 0 0 5px #93C5FD, 0 0 5px #93C5FD, 0 0 5px #93C5FD',
    maxWidth: '100%',
    minHeight: '150px' // Set a minimum height to maintain the bounding box height
  }}
>
  {
    collectedTags.map((tag, index) => (
      <button
        key={index}
        onClick={() => handleTagClick(tag, 'collected')}
        className="flex flex-col items-center justify-center bg-blue-400 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2"
      >
        <div>
          <QRCode
            qrStyle="dots"
            bgColor="#93C5FD"
            size={100}
            ecLevel="H"
            logoPadding={5}
            logoHeight={15}
            logoWidth={40}
            removeQrCodeBehindLogo={true}
            logoImage={require("../../assets/tagitblackwhite.png")}
            value={`tagit.link/${tag.TagCreator}/${tag.TagName}`}
            eyeRadius={5}
          />
        </div>
        <div>
          {tag.TagCreator}
          <br />
        </div>
      </button>
    ))
 }
</div>
<br></br>

                </div>
   
            </div>
            {
            createdClickedTag && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex p-4 justify-center items-center z-50">
                <div className="relative flex overflow-auto p-4 bg-zinc-950   rounded-lg max-w-lg w-full  sm:h-85vh 1k:h-4/6 h-75vh 2k:h-4/6"  style={{ boxShadow: '0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9' }}>
               

    <button className='absolute top-0 right-0 m-2 p-1.5 text-blue-300 bg-zinc-950 z-50 hover:text-gray-600   rounded-l shadow-lg '
    onClick={handleClosePopup}
    >
    <HiX size={33} />
</button>

                    <CreatedClickedTag ClickedTag={createdClickedTag} HomeTag={homeTag}/>
                        
                    
                                
                    </div>
                </div>
            )}
             {
            collectedClickedTag && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex p-4 justify-center items-center z-50">
                <div className="relative flex overflow-auto p-4 bg-zinc-950   rounded-lg max-w-lg w-full  sm:h-85vh 1k:h-4/6 h-75vh 2k:h-4/6"  style={{ boxShadow: '0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9' }}>
               

    <button className='absolute top-0 right-0 m-2 p-1.5 text-blue-300 bg-zinc-950 z-50 hover:text-gray-600   rounded-l shadow-lg '
    onClick={handleClosePopup}
    >
    <HiX size={33} />
</button>

                    <CollectedClickedTag ClickedTag={collectedClickedTag} HomeTag={homeTag}/>
                        
                    
                                
                    </div>
                </div>
            )}
        </div>
    );
}

export default Tags;

