import React from 'react';
import CustomColorPicker from './CustomColorPicker'; // Adjust the path as necessary
import ListOfFonts from './ListOfFonts'; // Ensure this is correctly imported

import ListOfRoundButtons from './ListOfRoundButtons';

function RoundStyle({ selectedButtonStyleId, buttonSelection, buttonColor, handleColorChange, selectedFontClassName, handleFontChange, recentButtonStyles }) {
  const fonts = ListOfFonts(); // Get the list of fonts
  const buttons = ListOfRoundButtons(); // Get button styles
  // Create a unique identifier based on font, color, and style and filter duplicates
  const uniqueIdentifier = style => `${style.fontClassName}-${style.buttonColor}-${style.buttonStyleId}`;

  const uniqueAndFilteredRecentStyles = recentButtonStyles
    .filter(style => style.containerType !== "regular") // Exclude 'regular' containerType
    .reduce((acc, current) => {
      const identifier = uniqueIdentifier(current);
      if (!acc.find(item => uniqueIdentifier(item) === identifier)) {
        acc.push(current);
      }
      return acc;
    }, []);
  const selectRecentStyle = (style) => {
    buttonSelection({ target: { value: style.buttonStyleId } });
    handleColorChange(style.buttonColor);
    handleFontChange({ target: { value: style.fontClassName } });
  };

  return (
    <div>
       <h3 className="text-base font-semibold bg-transparent  w-full mx-8 text-left">Select QuickLink Location:</h3>
      <div className='flex items-center justify-center'>
        <div className='flex overflow-auto justify-center items-center w-5/6' style={{ display: 'flex', flexDirection: 'row' }}>
          {buttons.map((button) => (
            <label className="labelexpanded mx-2" key={button.id} style={{ display: 'flex', flexDirection: 'column' }}>
              <input
                id={`locationOption-${button.id}`}
                className="locationOptions"
                type="radio"
                defaultChecked={button.id === selectedButtonStyleId}
                name="locationSelector"
                onClick={buttonSelection}
                value={button.id}
              />
              <div className="radio-btns-buttons mt-1">
                {/* Preview of the circle location might go here */}
                <img className=' h-28' src={require('../assets/layoutStyles/'+button.Image)}/>
                {button.Location}
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className='flex justify-center items-center  w-full'>
        <div className="flex items-center mr-4">
          <CustomColorPicker
              label="Circle Color"
              value={buttonColor}
              onChange={handleColorChange}
          />
        </div>
      </div>
      {uniqueAndFilteredRecentStyles && uniqueAndFilteredRecentStyles.length > 0 && (
        <div className=" w-full">
          <h3 className="text-base font-semibold bg-transparent mx-8 text-left">Recent Styles:</h3>
          <div
            className='flex overflow-auto p-2 w-full'
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            {uniqueAndFilteredRecentStyles.map((style, index) => {
              const buttonStyle = buttons.find(button => button.id === style.buttonStyleId)?.style;

              return (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => selectRecentStyle(style)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '8px',
                  }}
                >
                  <div
                    className={`examplebutton w-10 h-10 ${style.fontClassName}`}
                    style={{
                      backgroundColor: style.buttonColor, borderRadius: '50%' ,
                      ...buttonStyle,
                    }}
                  >
                    {/* Optional style name or ID display */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default RoundStyle;
