


function ListOfButtons() {

    var ListOfButtons = [
        {id: 0, style:   
        {   textAlign: "center",
            borderRadius: "0px 0px 0px",
          
        
        }},
    
        {id: 1, style:   
            {
            textAlign: "center",
            borderRadius: "15px 20px 15px",
  
        }},
        
        {id: 2, style:   
            {
            textAlign: "center",
            borderRadius: "40px 40px 40px",
       
            padding: "15px"}
           
            
        
            },

        {id: 3, style:   
            {
            textAlign: "center",
            borderRadius: "15px 60px 30px",
         
            padding: "15px"}},

   
            

            
          
  
        {
            id: 4,
            style: {
                textAlign: "center",
                borderRadius: "50% 40% 60% 50% / 40% 60% 40% 60%",
             
                padding: "15px",
               
                transform: "rotate(-5deg)" // Optional: Adds a slight tilt to enhance the irregular feel
            }
        },
      
        
        // Add more button styles as needed
    ];
    
    return ListOfButtons;   
}
      
export default ListOfButtons;
