function ListOfBackgroundImages() {
    var ListOfBackgroundImages = [
        { id: 0, name: "Standard", backgroundType: "color", needsColor: true, backgroundColor1: "#48b00c", userUploaded: false,needsImage: false, level: 0 },
        { id: 1, name: "Upload", backgroundType: "image", needsColor: false, templateImage: "1.png", userUploaded: true, needsImage: true, level: 1 },
        { id: 2, name: "Forrest", backgroundType: "image", needsColor: false, templateImage: "BackgroundN1.svg", userUploaded: false, needsImage: true, level: 0 },
    ];
    
    return ListOfBackgroundImages;     
}

export default ListOfBackgroundImages;
