import React from "react";
import { HiUser } from "react-icons/hi2";
import { AiOutlineSave } from "react-icons/ai";
import { getUser } from '../../service/AuthService';

function ViewsList({ viewsList, createdTags, totalViews }) {
  const user = getUser();
  const userLevel = user.level;

  // Sort the viewsList by TagViews in descending order
  const sortedViewsList = [...viewsList].sort((a, b) => b.TagViews - a.TagViews);

  // Helper function to get NickName from TagID
  const getNickName = (tagID) => {
    const tag = createdTags.find((tag) => tag.TagName === tagID);
    return tag ? tag.NickName : tagID;
  };

  return (
    <div className="w-full h-full outline outline-1 bg-zinc-950 outline-blue-300 rounded-lg p-4 overflow-auto relative">
    
      {sortedViewsList.map((view) => {
        const percentage = (view.TagViews / totalViews) * 100;
        const nickName = getNickName(view.TagID);

        return (
          <div key={view.TagID} className="my-2 flex items-center relative">
            <div
              className="bg-blue-500 h-10 rounded flex items-center"
              style={{ width: `${percentage}%` }}
            >
              <span className="text-white px-2">{nickName}</span>
            </div>
            <div className="absolute right-0 flex items-center">
              <div className="text-white flex flex-col items-center mx-2">
                <HiUser size={16} className="text-white" />
                <span>{view.TagViews}</span>
              </div>
              {userLevel > 0 && (
                <div className="text-white flex flex-col items-center mx-2">
                  <AiOutlineSave size={16} className="text-white" />
                  <span>{view.TagSaves}</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ViewsList;
