import React from 'react';
import loading from './assets/loading.gif';

function Loading() {
return(<div className="w-full h-full bg-zinc-950">
<img class="max-w-full max-h-400" src={loading} alt="Loading" />

</div>)
    }

export default Loading; 