import React from 'react';
import { Navigate, Route} from 'react-router-dom';
import {getToken} from '../service/AuthService';


function PublicRoute({ children }) {
    const auth = !getToken();
    return auth ? children : <Navigate to="/account" />;
  }




export default PublicRoute; 