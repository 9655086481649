import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import {getToken} from '../service/AuthService';



function PrivateRoute({ children }) {
    const auth = getToken();
    return auth ? children : <Navigate to="/login" />;
  }


export default PrivateRoute; 