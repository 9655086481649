
import Gradient from '../assets/backgroundColorFusors/Gradient';
import Gradient2 from '../assets/backgroundColorFusors/Gradient2';
import Display from '../assets/backgroundColorFusors/Display';
function ListOfBackgroundColorFusors() {
    var ListOfBackgroundColorFusors = [
        { id: -1, name: "Display", backgroundType: "component",  templateComponent: Display,   level: 420 },
        { id: 0, name: "Gradient", backgroundType: "component",  templateComponent: Gradient,   level: 0 },
        { id: 1, name: "Gradient2", backgroundType: "component",  templateComponent: Gradient2,  level: 0 },
        // ... other background fusors ...
    ];
    
    return ListOfBackgroundColorFusors;     
}

export default ListOfBackgroundColorFusors;
