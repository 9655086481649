import React, { useState, useEffect } from "react";
import { HiX } from "react-icons/hi";

function VirtualProducts({ VirtualInventory, addToCart, cartItems }) {
  const [cityStock, setCityStock] = useState([]);
  const [spaceStock, setSpaceStock] = useState([]);
  const [natureStock, setNatureStock] = useState([]);
  const [deviceClicked, setDeviceClicked] = useState(null);
  const [printAsSticker, setPrintAsSticker] = useState(false);
  const [stickerQuantity, setStickerQuantity] = useState(1);

  useEffect(() => {
    if (Array.isArray(VirtualInventory)) {
      const availableStock = VirtualInventory.filter(item => item.Status === 'available');
      const cityItems = availableStock.filter(item => item.Category === 'City');
      const spaceItems = availableStock.filter(item => item.Category === 'Space');
      const natureItems = availableStock.filter(item => item.Category === 'Nature');

      setCityStock(cityItems);
      setSpaceStock(spaceItems);
      setNatureStock(natureItems);
    }
  }, [VirtualInventory]);

  const isItemInCart = (itemID) => {
    return Object.keys(cartItems).some(key => key.includes(itemID));
  };

  // Function to add the main product to the cart
  function setID(item) {
    // Main product (Tagit AI QR)
    const mainProduct = {
      id: 10,
      name: "Tagit ARTQR",
      price: 4.99,
      ServerID: item,
      uniqueID: `10-${item}` // Create a unique ID for the main product
    };

    // Add the main product to the cart
    addToCart(mainProduct);
  }

  // Function to add stickers to the cart
  function addStickerToCart(item, quantity) {
    const dynamicStickerID = 4;
    
    const artqrStickerProduct = {
      id: dynamicStickerID,
      name: "ARTQR Sticker 2x2",
      price: 4.99,
      stickerServerID: item, // Server ID from the selected device
      quantity: quantity, // Pass the quantity selected by the user
      uniqueID: `${dynamicStickerID}-${item}` // Ensure uniqueness with stickerServerID and timestamp
    };
  
    console.log('Adding ARTQR sticker to cart:', artqrStickerProduct); // Debugging
    addToCart(artqrStickerProduct); // Add the sticker product with the quantity
  }
  
  
  
  
  const renderItems = (items) => (
    <div className="flex overflow-x-auto w-full py-4 scrollbar-custom">
      {items.map((item, index) => (
        <div
          key={index}
          style={{ boxShadow: '0 0 15px #93C5FD' }}
          className="flex flex-col items-center justify-center outline-1 outline outline-blue-300 bg-zinc-950 rounded-lg shadow-md transition-colors duration-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-4"
        >
          <button className="w-64 h-64 mb-2 sm:w-96 sm:h-96 flex items-center justify-center" onClick={() => setDeviceClicked(item.ID)}>
            <img className="object-cover w-full h-full rounded-lg" src={item.URL} alt={item.ID} />
          </button>
          <h1 className="text-white ">$4.99</h1>
          <button
            className={`text-white w-full py-2 ${isItemInCart(item.ID) ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-600'}`}
            onClick={() => setID(item.ID)}
            disabled={isItemInCart(item.ID)}
          >
            {isItemInCart(item.ID) ? 'Added to Cart' : 'Add to Cart'}
          </button>
          
          {/* Conditional rendering for additional button */}
          {isItemInCart(item.ID) && (
            <button
              className="mt-2 text-white bg-blue-600 hover:bg-blue-700 w-full py-2 rounded-md"
              onClick={() => addStickerToCart(item.ID, 1)} // Adds 1 sticker at a time
            >
              Purchase as a sticker
            </button>
          )}
        </div>
      ))}
    </div>
  );
  

  const handleClosePopup = () => {
    setDeviceClicked(null);
    setPrintAsSticker(false); // Reset when closing the popup
  };

  return (
    <div className="h-auto w-full">
      <h2 className="text-left p-3 -mb-7 text-3xl font-Orbitron text-blue-300">Nature:</h2>
      {renderItems(natureStock)}
      <h2 className="text-left p-3 -mb-7 text-3xl font-Orbitron text-blue-300">Alien Worlds:</h2>
      {renderItems(spaceStock)}
      <h2 className="text-left p-3 -mb-7 text-3xl font-Orbitron text-blue-300">City:</h2>
      {renderItems(cityStock)}

      {deviceClicked && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative flex flex-col items-center p-4 bg-zinc-950 rounded-lg h-85vh  w-11/12 sm:w-4/12" style={{ boxShadow: '0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9' }}>
            <button className="absolute top-2 right-2 m-2 p-1.5 text-cyan-300 outline outline-1 outline-cyan-300 bg-zinc-950 z-50 hover:text-gray-600 rounded-md shadow-lg" onClick={handleClosePopup}>
              <HiX size={20} />
            </button>
            <div className="w-full outline outline-1 outline-cyan-300 rounded-md  p-4 flex justify-center items-center bg-zinc-800">
              <img className=' h-52 sm:h-60 ' src={'https://tagit.s3.us-west-2.amazonaws.com/' + deviceClicked + '.png'} alt="Device" />
            </div>

            <div className="w-full mt-4 flex justify-end ">
              <h1 className="text-white p-4 text-xl outline outline-1 -mx-0 outline-cyan-300 rounded-l-md">$4.99</h1>
              <button
                className={`text-white w-60 py-2 outline outline-1 outline-cyan-300 rounded-r-md ${isItemInCart(deviceClicked) ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                onClick={() => setID(deviceClicked)}
                disabled={isItemInCart(deviceClicked)}
              >
                {isItemInCart(deviceClicked) ? 'Added to Cart' : 'Add to Cart'}
              </button>
            </div>

          

            <div className="w-full h-3/6 text-white mt-4 flex flex-col items-center overflow-auto scrollbar-custom">
            {isItemInCart(deviceClicked) && (
              <div className="w-full mt-4 flex flex-col items-center">
              <div className="mt-2 flex items-center">
                      <span className="text-white mr-2">Quantity:</span>
                      <input
                        type="number"
                        value={stickerQuantity}
                        min="1"
                        onChange={(e) => setStickerQuantity(Number(e.target.value))}
                        className="w-20 p-2 text-black rounded"
                      />
                    </div>

                    {/* Add Sticker to Cart Button */}
                    <button
                      className="mt-4 text-white bg-blue-600 hover:bg-blue-700 w-full py-2 rounded-md"
                      onClick={() => addStickerToCart(deviceClicked, stickerQuantity)}
                    >
                      Purchase as a sticker
                    </button>
              </div>
            )}
              <h1 className="p-3 text-3xl sm:text-4xl font-Orbitron text-blue-300">TagIt ArtQR</h1>
              <div className="mt-2 w-5/6 md:w-5/6 p-4">
                <p className="text-center text-xl font-bold">Tag with Art QR</p>
                <p> QR codes of yesterday were bland and boring. Use Art QR to share your style while linking whatever you want!</p>
                <br />
                <p className="text-center text-xl font-bold">Setup is simple: </p>
                <p> If you're logged in, your ARTQR Code will be added directly to your account's Dashboard on checkout.  </p>
                <p> Easily swap your ARTQR between any of your desired Tagit Tags at any time.</p>
                <p> If you're not logged in you will be sent an activation code for your ARTQR device in your confirmation email.  </p>
                <br></br>
                <p className="text-center text-xl font-bold">Buy a Sticker: </p>
                <p> Add a 2x2 sticker to your puchase and let us do the printing for you!   </p>
                <p> Stickers are printed and shipped quickly for your convenience.  </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualProducts;
