import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from '../Header/Header';

function CheckoutSuccess() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('orderId');

  useEffect(() => {
    // assuming you will fetch order details using the sessionId here
  }, [sessionId]);

  return (
    <div className=''>
      <Header></Header>
      <motion.div 
        className="flex flex-col items-center justify-center -my-28 h-screen bg-offwhite text-black"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="rounded-lg p-8 bg-white shadow-lg  md:w-1/2"
          initial={{ y: -250, scale: 0.8 }}
          animate={{ y: 0, scale: 1 }}
          transition={{ type: 'spring', stiffness: 120 }}
        >
         
          <motion.h2 
            className="text-2xl md:text-4xl mt-6 text-center"
            initial={{ y: -50 }}
            animate={{ y: -10 }}
            transition={{ type: 'spring', stiffness: 120 }}
          >
            Success!
          </motion.h2>
          <p className="mt-2 text-center break-word">Your order number is: <br/> <span className="font-bold text-blue-500 break-all">{sessionId}</span><br/>We have emailed your order confirmation, and will send you an update when your order has shipped.</p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default CheckoutSuccess;
