import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import leaf from '../assets/tagittagleaf2.png';
import prologo from '../assets/tagitpro.png';

const features = ["One Free AI QR code/month", "Pro Module: Augmented Reality", "Access to new features first."];
const delays = [0,6,15,17,25,35,3,9,12,23,29,39]; // add your delay times here

const BannerComponent = () => {
  const [featureIndex, setFeatureIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  const handleSubscribeClick = () => {
    window.location.href='/subscriptions'
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setFeatureIndex((prevFeatureIndex) => (prevFeatureIndex + 1) % features.length);
    }, 5000);
  
    const checkMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", checkMobile);
    
    return () => {
      clearInterval(timer);
      window.removeEventListener("resize", checkMobile);
    }
  }, []);
  return (
    <div className="relative p-1 md:p-3 bg-blue-300 text-white flex flex-col md:flex-row justify-between items-center overflow-hidden">

      <div className="text-lg  md:text-xl z-30"><img src={prologo} className=' sm:h-48  h-40'></img></div>
      <AnimatePresence mode='wait'>
        <motion.div 
        
          key={features[featureIndex]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 2 }}
          className="flex z-20 items-center"
        >
        <motion.div
  className="text-lg md:text-2xl my-3 md:my-0 z-20 hidden md:block"
  style={{ textShadow: '0 0 50px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 60px #fff' }}
> 
            {features[featureIndex]}
          </motion.div>
        </motion.div>
      </AnimatePresence>
      <button 
        
        className="px-4 py-2 bg-white text-blue-500 rounded-lg font-semibold tracking-wide shadow-lg z-20 hover:bg-blue-200 transition-colors duration-200 text-sm md:text-base"
      >
        Coming Soon
      </button>
      {['-90%', '90%', '-100%','90%', '-80%', '100%','300%', '-300%', '300%','-300%', '300%', '-300%',].map((initialOffset, index) => (
        <motion.img
          className=' z-0 opacity-50'
          key={index}
          src={leaf}
          alt="Flying Image"
          initial={isMobile ? { y: '-700%', x: initialOffset } : { x: '-700%', y: initialOffset }}
          animate={isMobile ? { y: '6000%', x: initialOffset } : { x: '6000%', y: initialOffset }}
          transition={{
            delay: delays[index],
            duration: 20,
            ease: 'linear',
            repeat: Infinity
          }}
          style={{ width: '50px', height: '50px', position: 'absolute', zIndex: 0, opacity: '50%' }}
        />
      ))}


    </div>
  );
}

export default BannerComponent;
