import React, { useState } from 'react';
import LinkIcon from '../../assets/tagItemButtons/link.gif';
import ListIcon from '../../assets/tagItemButtons/list.gif';
import { HiTrash, HiOutlinePencil, HiOutlineArrowUp, HiOutlineArrowDown } from "react-icons/hi";

function ListModule({ data, onEdit, onDelete, onMoveUp, onMoveDown }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Check if data.list is not undefined or null and has length
  const hasListData = data.list && data.list.length > 0;
  const initialItemsToShow = hasListData ? data.list.slice(0, 3) : [];
  const dropdownItems = hasListData ? data.list.slice(3) : [];

  return (
    <div className="p-4 border rounded text-white relative flex">
      <div className='flex flex-col items-center mr-4 outline hover:bg-cyan-400 p-1 outline-1 rounded-xl outline-cyan-300'>
        <button onClick={() => onMoveUp()} className="mb-10 my-2 rounded-lg hover:bg-cyan-900 ">
          <HiOutlineArrowUp />
        </button>
        <button onClick={() => onMoveDown()} className='my-2 rounded-lg hover:bg-cyan-900'>
          <HiOutlineArrowDown />
        </button>
      </div>
     
      <div className="flex justify-center sm:ml-0 ml-8 items-center" style={{ width: '100%', pointerEvents: 'auto' }}>
        <div className='flex flex-col items-center justify-center'>
          <div className="sm:text-lg text-sm">
            {initialItemsToShow.map((item, index) => (
              <div key={index} className="my-1">
                {item.data.substring(0, 20)}
              </div>
            ))}
          </div>
          {dropdownItems.length > 0 && (
            <button onClick={toggleDropdown} className="sm:text-lg text-xs mt-2">
              {isDropdownOpen ? 'Show Less' : `+${dropdownItems.length} more`}
            </button>
          )}
          {isDropdownOpen && (
            <div className="dropdown-content bg-cyan-900 text-white p-2 mt-2 rounded flex flex-col">
              {dropdownItems.map((item, index) => (
                <div key={`dropdown-${index}`} className="truncate my-1">
                  {item.data.substring(0, 20)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col justify-between ml-auto'>
        <button onClick={() => onEdit(data)} className="self-end mb-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
          <HiOutlinePencil size={20} />
        </button>
        <button onClick={() => onDelete()} className="self-end mt-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
          <HiTrash size={20} />
        </button>
      </div>
    </div>
  );
}

export default ListModule;
