import React, { useState } from 'react';
import ListOfButtons from '../../TagCreator/ListOfButtons';
import { ARViewer } from './ARViwer';

function ARComponent({ data }) {
  const listOfButtons = ListOfButtons();
  const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor,
  };

  const [isARActive, setIsARActive] = useState(false);

  const handleOpenAR = () => {
    setIsARActive(true);
  };

  const handleCloseAR = () => {
    setIsARActive(false);
  };

  return (
    <div
      className="inline-flex items-center outline outline-2 w-full relative"
      id="tagModule"
      style={{ position: 'relative', overflow: 'hidden', borderRadius: baseStyles.borderRadius, ...Object.assign({}, additionalStyles) }}
    >
      <button onClick={handleOpenAR} style={{ ...baseStyles, ...additionalStyles }} className="p-2">
        Open AR Experience
      </button>

      {isARActive && (
        <ARViewer arFile={data.arFile} onClose={handleCloseAR} />
      )}
    </div>
  );
}

export default ARComponent;
