import React, { useEffect, useState } from "react";
import axios from "axios";

function PollResults({ data }) {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getPoll(InteractableID) {
    const getPollAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/getpoll';

    try {
      const response = await axios.post(getPollAPIURL, {
        PollID: InteractableID
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_KEY
        }
      });

      return response.data; // Assume the data structure contains the poll details
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.log(error.response.data.message);
      } else {
        console.log('Sorry, the server is down right now, please try again later.');
        console.log(error.response ? error.response.data.message : error.message);
      }
      throw error;
    }
  }

  useEffect(() => {
    async function fetchPollData() {
      try {
        const pollResults = await getPoll(data.id);
        setResults(pollResults.Poll); // Set the fetched data to the state
        console.log(pollResults);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    }

    fetchPollData();
  }, [data.id]);

  const getEmbedLink = (watchLink) => {
    let videoId;
    let embedLink;
    if (watchLink && watchLink.includes("v=")) {
      videoId = watchLink.split("v=")[1];
      let ampersandPosition = videoId.indexOf("&");
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      embedLink = "https://www.youtube.com/embed/" + videoId;
    } else if (watchLink && watchLink.includes("youtu.be")) {
      videoId = watchLink.split("/").pop();
      embedLink = "https://www.youtube.com/embed/" + videoId;
    }
    return embedLink;
  };

  const getSpotifyEmbedLink = (spotifyLink) => {
    return spotifyLink.replace("open.spotify.com", "open.spotify.com/embed");
  };

  const renderAnswerContent = (answer) => {
    const type = answer.type;
    const content = answer.data;

    switch (type) {
      case 'text':
        return <span>{content}</span>;
      case 'video':
        const videoLink = getEmbedLink(content);
        return <iframe className="scale-70" src={videoLink} frameBorder="0" allowFullScreen title="video"></iframe>;
      case 'music':
        const musicLink = getSpotifyEmbedLink(content);
        return <iframe className=" h-20 scale-70" src={musicLink} frameBorder="0" allow="encrypted-media" title="music"></iframe>;
      default:
        return <span>{content}</span>;
    }
  };

  const calculatePercentage = (votes, totalVotes) => {
    if (totalVotes === 0) return 0;
    return (votes / totalVotes) * 100;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!results || !Array.isArray(results.AnswerList)) {
    return <div>No data available</div>;
  }

  // Sort the results.AnswerList array by the number of votes in descending order
  const sortedAnswers = [...results.AnswerList].sort((a, b) => b.votes - a.votes);

  const darkenColor = (color) => {
    // Assuming color is in hex format like '#RRGGBB'
    let hex = color.replace('#', '');
    if (hex.length === 3) {
      hex = hex.split('').map(x => x + x).join('');
    }
    const num = parseInt(hex, 16);
    const r = Math.max(0, Math.min(255, (num >> 16) * 0.55));
    const g = Math.max(0, Math.min(255, ((num >> 8) & 0x00FF) * 0.55));
    const b = Math.max(0, Math.min(255, (num & 0x0000FF) * 0.55));
    return `rgb(${r}, ${g}, ${b})`;
  };
  const darkenColor2 = (color) => {
    // Assuming color is in hex format like '#RRGGBB'
    let hex = color.replace('#', '');
    if (hex.length === 3) {
      hex = hex.split('').map(x => x + x).join('');
    }
    const num = parseInt(hex, 16);
    const r = Math.max(0, Math.min(255, (num >> 16) * 0.7));
    const g = Math.max(0, Math.min(255, ((num >> 8) & 0x00FF) * 0.7));
    const b = Math.max(0, Math.min(255, (num & 0x0000FF) * 0.7));
    return `rgb(${r}, ${g}, ${b})`;
  };
  return (




    <div className={`flex flex-col items-center w-full overflow-auto max-w-lg mx-auto ${data.ButtonStyle.fontClassName}`}>
      <h1 className="text-2xl font-bold mb-4">{data.title}</h1>
      <div className=' w-full h-0.5 ' style={{ background: data.ButtonStyle.fontColor }}></div>
      <div className="flex flex-col items-center w-full">
   

      <div className="flex flex-col items-center w-full overflow-auto">
     
      {sortedAnswers.map((answer, index) => {
        const percentage = calculatePercentage(answer.votes, results.VotesTotal);

        return (
          <div key={index} className=" w-64 p-2">
            <div
              className=" rounded-lg overflow-hidden "
              style={{ position: 'relative', background: darkenColor(data.ButtonStyle.fontColor) }}
            >
              <div
                className="h-full absolute top-0 left-0"
                style={{ width: `${percentage}%`, zIndex: 0 , background: darkenColor2(data.ButtonStyle.fontColor)}}
              ></div>
              <div className="relative justify-between items-center">
                <div className=" justify-center items-center flex ">{renderAnswerContent(answer)}</div>
                <div className="ml-4 text-gray-700 ">{percentage.toFixed(2)}%</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>



      </div>
    </div>







   
  );
}

export default PollResults;
