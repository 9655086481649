import React from 'react';
import { HiGlobeAmericas } from "react-icons/hi2";
import { HiPhone } from "react-icons/hi2";
import { HiEnvelope } from "react-icons/hi2";
function InfoBar({ infoBarData }) {
  const {
    location,
    displayType,
    phone,
    email,
    infoBarColor, // Assuming this is for the background
    infoBarButtonColor, // The color for the buttons
    backgroundOpacity,
  } = infoBarData;
  const { street, city, state, postalCode, country } = location || {};

  const formattedAddress = street && city && state && postalCode && country 
  ? `${street}, ${city}, ${state} ${postalCode}, ${country}`
  : "Address not available";

  // Button styles with dynamic background color from infoBarButtonColor
  const buttonStyles = {
    backgroundColor: infoBarButtonColor || 'gray', // Fallback to gray if no color provided
    color: 'white', // Assuming you want white text, adjust as needed
    padding: 'py-2 px-4',
    borderRadius: 'rounded',
    hoverBackgroundColor: 'hover:bg-opacity-75', // Adjust hover effect as needed
    // Add any other Tailwind classes or inline styles you need for the buttons
  };
    // Construct a className string from buttonStyles
    const buttonClassNames = Object.values(buttonStyles).join(' ');

  // Convert hex color to RGB
  const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `${r},${g},${b}`;
  };

  // Ensure backgroundOpacity is between 0 and 100, then convert to 0 - 1 for CSS
  const opacity = backgroundOpacity ? Math.max(0, Math.min(100, backgroundOpacity)) / 100 : 1;

  const rgbColor = infoBarColor ? hexToRgb(infoBarColor) : '255,255,255'; // Default to white if no color provided
  const backgroundColor = `rgba(${rgbColor}, ${opacity})`;

  const infoBarStyles = {
    backgroundColor: backgroundColor,
    // Other styles if needed
  };

  // Check if all parts of the address are provided
  const isFullAddressProvided = street && city && state && postalCode && country;

  // Construct the Google Maps URL only if the full address is provided
  const googleMapsUrl = isFullAddressProvided ? `https://www.google.com/maps/place/${encodeURIComponent(street)}+${encodeURIComponent(city)}+${encodeURIComponent(state)}+${encodeURIComponent(postalCode)}+${encodeURIComponent(country)}` : '';

  // Helper function to render phone and email based on displayType
  const renderContactInfo = () => {
    if (displayType === "buttons") {
      return (
        <>
          {phone && (
            <a href={`tel:${phone}`}  style={{ backgroundColor: infoBarButtonColor}} className="mt-2 inline-block  text-white py-2 px-4 rounded hover:bg-green-600">
            <HiPhone />
            </a>
          )}
          {email && (
            <a href={`mailto:${email}`} style={{ backgroundColor: infoBarButtonColor}} className="ml-2 inline-block  text-white py-2 px-4 rounded hover:bg-blue-600">
               <HiEnvelope />
            </a>
          )}
          {isFullAddressProvided && (
             <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: infoBarButtonColor }} className={`inline-block text-white py-2 px-4 rounded hover:bg-blue-600 ${displayType === 'buttons' ? 'ml-2' : ''}`}>
                <HiGlobeAmericas />
          </a>)}
        </>
      );
    } else {
      return (
        <>
         {phone && (
          <a href={`tel:${phone}`} className="block mt-2 text-blue-600 hover:text-blue-800 visited:text-purple-600 overflow-hidden text-ellipsis whitespace-nowrap" style={{ maxWidth: '100%' }}>
            <HiPhone className="inline mr-2" />{phone}
          </a>
        )}
        {email && (
          <a href={`mailto:${email}`} className="block  mt-2 text-blue-600 hover:text-blue-800 visited:text-purple-600 overflow-hidden text-ellipsis whitespace-nowrap" style={{ maxWidth: '100%' }}>
            <HiEnvelope className="inline mr-2" />{email}
          </a>
        )}
      {isFullAddressProvided && (
        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="block mt-2 text-blue-600 hover:text-blue-800 visited:text-purple-600 overflow-hidden text-ellipsis whitespace-nowrap" style={{ maxWidth: '100%' }}>
          <HiGlobeAmericas className="inline mr-2" />{formattedAddress}
        </a>
      )}
      </>
      );
    }
  };

 return (
    <div className="info-bar-content px-4 py-1  " style={infoBarStyles}>
      <h2>{infoBarData.name}</h2>
      <p>{infoBarData.title}</p>
     

      {renderContactInfo()}

    

    </div>
  );
}

export default InfoBar;
