import React from 'react';
import ListOfButtons from '../../TagCreator/ListOfButtons';

function TextComponent({ data }) {
  // Validate data
  const listOfButtons = ListOfButtons();
  if (!data || !data.ButtonStyle || !listOfButtons[data.ButtonStyle.buttonStyleId]) {
    console.error('Invalid data provided to TextComponent');
    return null; // Return null or an error message if data is invalid
  }

  // Extract styles and content from data
  const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor,
    fontFamily: data.ButtonStyle.fontClassName,
  };

  return (
    <div
      id='tagModule'
      className="inline-flex items-center justify-center w-full outline outline-2 p-2"
      style={{ ...baseStyles, ...additionalStyles }}
    >
      <div className="w-full">
        {/* Display the plain text content */}
        <p className={data.ButtonStyle.fontClassName} style={{ whiteSpace: 'pre-wrap' }}>{data.textBoxValue}</p>
      </div>
    </div>
  );
}

export default TextComponent;
