import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ScrollRightAnimation = ({ backgroundData }) => {
  const [numCols, setNumCols] = useState(0);
  const numRows = 7; // Increase the number of rows
  const movementDuration = 3; // Duration for the animation

  useEffect(() => {
    const updateDimensions = () => {
      const viewportWidth = window.innerWidth;
      const imageWidth = viewportWidth / numRows; // Width of each image
      const colsNeeded = Math.ceil(viewportWidth / imageWidth); // Columns needed to fill the screen
      setNumCols(colsNeeded * 5); // Increase the number of columns to ensure continuous coverage
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const getBackgroundImageStyle = (imagePath) => {
    if (typeof imagePath === 'string' && imagePath.startsWith('http')) {
      return `url(${imagePath})`;
    }
    return `url(${require(`../../assets/backgroundForeground/${imagePath}`)})`;
  };

  const createGrid = () => {
    const grid = [];
    const imageWidth = 28; // Fixed width for each image in percentage
    const imageHeight = 100 / numRows; // Height of each image in percentage

    for (let col = 0; col < numCols; col++) {
      for (let row = 0; row < numRows; row++) {
        const index = col * numRows + row;
        grid.push(
          <motion.div
            key={index}
            initial={{ x: '-100%' }} // Starts from left of the screen
            animate={{ x: '0%' }} // Moves to its original position
            exit={{ x: '100%' }} // Moves out of the screen at the right
            transition={{
              duration: movementDuration,
              ease: "linear",
              repeat: Infinity,
              repeatType: 'loop',
            }}
            style={{
              backgroundImage: getBackgroundImageStyle(backgroundData.backgroundSelection.backgroundForeground),
              backgroundSize: 'cover',
              position: 'absolute',
              width: `${imageWidth}%`, // Fixed width for each image
              height: `${imageHeight}%`, // Height based on the number of rows
              top: `${row * imageHeight}%`,
              left: `${col * imageWidth - imageWidth}%`, // Adjust the spawning point
              zIndex: 0,
            }}
          />
        );
      }
    }
    return grid;
  };

  return <div className="relative w-full h-full overflow-hidden">{createGrid()}</div>;
};

export default ScrollRightAnimation;
