import React from 'react';
import ImageIcon from '../../assets/tagItemButtons/image.gif';
import { HiTrash, HiOutlinePencil, HiOutlineArrowUp, HiOutlineArrowDown } from "react-icons/hi";



function ImageModule({ data, onEdit, onDelete, onMoveUp, onMoveDown }) {

  console.log("Image Data: ", data);

    return (
      <div className="p-4 border rounded text-white relative flex">
        <div className='flex flex-col items-center mr-4 outline hover:bg-cyan-400  p-1 outline-1 rounded-xl outline-cyan-300'>
          <button onClick={() => onMoveUp()} className="mb-10 my-2 rounded-lg hover:bg-cyan-900 w-full h-full ">
            <HiOutlineArrowUp />
          </button>
          <button onClick={() => onMoveDown()} className='my-2 rounded-lg hover:bg-cyan-900' >
            <HiOutlineArrowDown />
          </button>
        </div>
       
{/* Centered Text with pointer-events-none */}
<div className="absolute inset-0 flex justify-center sm:ml-0 ml-8 items-center" style={{pointerEvents: 'none'}}>
  <div className='flex flex-col items-center justify-center'> {/* Ensure you include 'flex' here */}
    <span className="sm:text-lg text-sm ">
    <img className='  h-20' src={data.url} alt="Module Content" />
    </span>

  </div>
</div>



        <div className='flex flex-col justify-between ml-auto'>
          <button onClick={() => onEdit(data)} className="self-end mb-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
            <HiOutlinePencil size={20} />
          </button>
          <button onClick={() => onDelete()} className="self-end mt-auto p-1 hover:outline-1 hover:outline hover:rounded-xl hover:outline-cyan-300">
            <HiTrash size={20} />
          </button>
        </div>
      </div>
    );
}

export default ImageModule;
