export const getTagitLogoSvgString = (color) => {
    return `
      <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378.33 153.33">
        <defs>
          <style>
            .d {
              fill: ${color};
              stroke-width: 0px;
            }
          </style>
        </defs>
        <g id="c" data-name="Layer 1">
          <g>
            <path class="d" d="M189.03,153.33c-61.76,0-123.52,0-185.27,0C.71,153.33,0,152.62,0,149.58,0,100.93,0,52.27,0,3.62,0,.76.77,0,3.63,0,127.28,0,250.92,0,374.57,0c3.04,0,3.76.71,3.76,3.76,0,48.61,0,97.22,0,145.82,0,3.04-.71,3.75-3.76,3.75-61.84,0-123.69,0-185.53,0ZM124.07,148.4v-.05h32.94c26.38,0,52.77,0,79.15,0,.56,0,1.13,0,1.69-.07,1.28-.18,1.97-1.01,2.2-2.24.08-.42.04-.87.04-1.3,0-45.39,0-90.79,0-136.18,0-.35.02-.7-.01-1.04-.12-1.4-.99-2.32-2.38-2.49-.47-.06-.95-.04-1.43-.04-29.03-.02-58.06-.05-87.1-.05-45.78,0-91.57,0-137.35,0-2.97,0-3.77.81-3.77,3.79,0,27.47,0,54.94,0,82.41,0,17.88,0,35.76,0,53.64,0,2.77.87,3.63,3.68,3.63.39,0,.78,0,1.17,0h111.18ZM248.24,4.93v143.47c.64,0,1.15,0,1.67,0,38.84,0,77.68,0,116.52,0,3.07,0,3.86-.78,3.86-3.84,0-45.26,0-90.53,0-135.79,0-3.06-.79-3.84-3.86-3.84-38.84,0-77.68,0-116.52,0h-1.67Z"/>
            <path class="d" d="M182.86,121.63c2.98.68,5.76,1.7,8.59,1.89,7.37.51,12.35-3.53,13.71-10.82.36-1.95.52-3.96.53-5.94.05-8.03.02-16.05-.03-24.08,0-1.03-.14-2.08-.38-3.09-1.27-5.52-7.04-7.88-11.89-4.91-3.9,2.39-5.42,7.03-3.64,11.07,1.7,3.85,6.15,5.74,10.52,4.46.57-.17,1.13-.37,1.84-.61v1.4c0,6.34-.02,12.67.02,19.01,0,.97-.25,1.42-1.23,1.74-13.99,4.62-28.27-2.05-33.73-16.28-7.55-19.66,3.56-38.79,21.87-43.43,10.72-2.72,20.86-.98,29.84,5.78,5.41,4.07,8.17,9.82,9.38,16.3.65,3.47,1.02,7.05,1.07,10.58.14,8.5.25,17.02-.04,25.51-.35,9.97-3.68,18.91-11.09,25.86-8.94,8.39-19.58,10.6-31.37,7.77-1.3-.31-3.02-.44-3.69-1.32-.66-.86-.29-2.55-.29-3.88-.02-5.29,0-10.59,0-15.88,0-.46,0-.93,0-1.12Z"/>
            <path class="d" d="M130.73,115.47c0-9,.02-17.89-.01-26.79,0-1.82-.02-3.65-.29-5.45-.7-4.67-4.06-7.87-8.23-7.99-4.65-.14-8.67,2.85-9.57,7.34-.7,3.51.12,6.69,3,9.02,3,2.43,6.38,2.37,9.83,1.03.44-.17.85-.4,1.46-.7.03.57.06,1.02.06,1.47,0,7.07-.03,14.14.03,21.22.01,1.37-.25,1.94-1.77,2.1-14.82,1.63-27.76-5.38-33.61-17.75-9.71-20.55,1.88-42.79,22.35-47.23,8.06-1.75,16.11-1.07,23.64,2.55,9.41,4.52,14.51,12.33,16.2,22.46.42,2.51.55,5.09.56,7.64.06,9.81.03,19.61.03,29.42v1.66h-23.68Z"/>
            <path class="d" d="M84.02,28.96v24.72h-19.65v61.78h-25.74v-61.77c-.68,0-1.18,0-1.69,0-5.6,0-11.2-.01-16.79.01-.83,0-1.26-.14-1.25-1.12.03-7.59.02-15.19.02-22.78,0-.25.05-.5.09-.84h65.02Z"/>
            <path class="d" d="M317.34,32.84h23.67v24.17h10.61v25.06h-10.51c-.08,3.4.11,6.58,1.69,9.49,1.47,2.71,4.14,3.4,6.92,3.72,2.51.29,2.52.23,2.52,2.78,0,6.98-.03,13.97.02,20.95,0,1.15-.35,1.46-1.48,1.48-9.14.18-17.12-2.72-23.82-8.96-5.2-4.84-7.97-10.96-8.96-17.89-.41-2.86-.62-5.78-.63-8.67-.06-16.74-.03-33.49-.03-50.23v-1.9Z"/>
            <path class="d" d="M278.88,32.84h25.49v86.54h-25.49V32.84Z"/>
          </g>
        </g>
      </svg>
    `;
  };
  