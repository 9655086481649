import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

const RainAnimation = ({ backgroundData }) => {
  const gridSize = 5; // For a 5x5 grid
  const movementDuration = 50; // Duration for each raindrop to move across the screen (adjusted for faster animation)
  const numberOfRaindrops = 30; // Total number of raindrops

  const initialOffsets = ['-100%', '-200%', '-60%'];
  const totalDelay = 30; // Total time over which all raindrops start their animation
  const delays = Array.from({ length: numberOfRaindrops }, (_, i) => (totalDelay / numberOfRaindrops) * i);

  const containerRef = useRef(null);
  const [raindrops, setRaindrops] = useState([]);

  useEffect(() => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;

    const newRaindrops = [];

    for (let row = 0; row < gridSize; row++) {
      for (let col = 0; col < gridSize; col++) {
        const index = row * gridSize + col;
        newRaindrops.push({
          key: index,
          x: col * (containerWidth / gridSize),
          y: -container.clientHeight * (index % initialOffsets.length / 30),
          delay: delays[index % delays.length],
        });
      }
    }

    setRaindrops(newRaindrops);
  }, [containerRef]);

  const getBackgroundImageStyle = (imagePath) => {
    if (typeof imagePath === 'string' && imagePath.startsWith('http')) {
      return imagePath;
    }
    return require(`../../assets/backgroundForeground/${imagePath}`);
  };

  const createImageGrid = (backgroundData) => {
    const grid = [];
    const columnCount = 5; // Fixed column count to always have 5 items in a row
    const rowCount = 9; // Number of rows (adjust as needed)
    const movementDuration = 15; // Duration for each image to move across the screen
    const spacingX = 100 / columnCount; // Even spacing for X position
 

    for (let col = 0; col < columnCount; col++) {
      for (let row = 0; row < rowCount; row++) {
        if (Math.random() < .33) { // 20% chance to render the item
        grid.push(
          <motion.img
            className='z-10'
            key={`${col}-${row}`}
            src={getBackgroundImageStyle(backgroundData.backgroundSelection.backgroundForeground)}
            alt="Leaf"
            initial={{ y: '-100%', opacity: 1, x: `${spacingX * col}%` }}
            animate={{ y: '1000%', opacity: 1 }}
            transition={{
              delay: row * 2,
              duration: movementDuration,
              ease: 'linear',
              repeat: Infinity,
              repeatDelay: 3 // Add repeatDelay to create a smoother transition
            }}
            style={{
              width: '20%', // Adjust size of leaves as needed
              height: 'auto',
              position: 'absolute',
              left: `${spacingX * col}%`,
             
              top: '0%',
              zIndex: 0,
              opacity: 1
            }}
          />
        );}
      }
    }

    return grid;
  };

  return (
    <div className="relative w-full h-full overflow-hidden" ref={containerRef}>
      {createImageGrid(backgroundData)}
    </div>
  );
};

export default RainAnimation;
