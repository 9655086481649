import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Header/Header";
import Tags from "./Tags/Tags";
import Data from "./Data/Data";
import Devices from "./Devices/Devices";
import { getUser } from '../service/AuthService';
import { HiCog, HiX } from "react-icons/hi";
import AccountSettings from "./Settings/AccountSettings";
import TagCreatedPopup from './TagCreatedPopup'; // Import the new component

const Dashboard = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const user = getUser();
  const [createdTags, setCreatedTags] = useState([]);
  const [collectedTags, setCollectedTags] = useState([]);
  const [homeTag, setHomeTag] = useState('');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [interactables, setInteractables] = useState([]);
  const [viewsList, setViewsList] = useState([]);
  const [totalViews, setTotalViews] = useState(0);
  const [deviceList, setDeviceList] = useState([]);
  const [isTagCreatedPopupOpen, setIsTagCreatedPopupOpen] = useState(false);
  const [tagname, setTagname] = useState('');

  useEffect(() => {
    if (!isLoaded) {
      getData();
      setIsLoaded(true);
    }

    const urldata = window.location.href.split("/");
    const tagcreated = urldata[4];
    const tagname = urldata[5];
    //console.log(tagname);

    if (tagcreated === 'created') {
      setTagname(tagname);
      setIsTagCreatedPopupOpen(true);
    }
  }, [isLoaded, user]);

  const getData = () => {
    const requestConfig = {
      headers: {
        'x-api-key': process.env.REACT_APP_KEY
      },
      username: user.username
    };

    axios.post('https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/mytags', requestConfig)
      .then(response => {
        setCreatedTags(response.data.createdTags);
        setCollectedTags(response.data.collectedTags);
        setHomeTag(response.data.homeTag);
        setInteractables(response.data.interactables);
        setViewsList(response.data.viewsList);
        setTotalViews(response.data.totalViews);
        setDeviceList(response.data.devices);

        //console.log(response);
      })
      .catch(error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          console.log(error.response.data.message);
        } else {
          console.log('Sorry, the server is down right now, please try again later.');
        }
      });
  };

  const isMobile = window.innerWidth <= 640; // Tailwind's default breakpoint for `sm`

  return (
    <div className="fixed inset-0 bg-zinc-900 overflow-y-auto">
      <Header />
      <div className="flex-grow h-screen w-full">
        {isMobile ? (
          <div className="flex flex-col">
            <div className="w-full flex justify-between items-center p-2">
              <h1 className="text-xl mx-3 font-semibold text-white">Welcome {user.username}</h1>
              <button className="mx-3 text-white rounded" onClick={() => setIsSettingsOpen(true)}>
                <HiCog size={30} />
              </button>
            </div>
            <div className="w-full aspect-square p-2">
              <div className="w-full h-full outline outline-1 bg-zinc-700 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                <Tags createdTags={createdTags} collectedTags={collectedTags} homeTag={homeTag} />
              </div>
            </div>
            <div className="w-full aspect-square p-2">
              <div className="w-full h-full outline outline-1 bg-zinc-700 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                <Data totalViews={totalViews} interactables={interactables} viewsList={viewsList} createdTags={createdTags} />
              </div>
            </div>
            <div className="w-full aspect-square p-2">
              <div className="w-full h-full outline outline-1 bg-zinc-700 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                <Devices Devices={deviceList} CreatedTags={createdTags} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full -my-4">
            <div className="w-4/5 flex justify-between items-center">
              <h1 className="text-xl mx-6 -mb-2 font-semibold text-blue-300">Welcome {user.username}</h1>
              <button className="mx-6 -mb-2 text-blue-300 rounded" onClick={() => setIsSettingsOpen(true)}>
                <HiCog size={30} />
              </button>
            </div>
            <div className="flex flex-col w-4/5 max-w-4/5 sm:flex-row h-3/6">
              <div className="w-full sm:w-1/2 h-2/6 sm:h-full p-4">
                <div className="w-full h-full outline outline-1 bg-zinc-700 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                  <Tags createdTags={createdTags} collectedTags={collectedTags} homeTag={homeTag} />
                </div>
              </div>
              <div className="w-full sm:w-1/2 h-2/6 sm:h-full p-4">
                <div className="w-full h-full outline outline-1 bg-zinc-700 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                  <Data totalViews={totalViews} interactables={interactables} viewsList={viewsList} createdTags={createdTags} />
                </div>
              </div>
            </div>
            <div className="w-4/5 h-2/5 p-4">
              <div className="w-full h-full outline outline-1 bg-zinc-700 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                <Devices Devices={deviceList} CreatedTags={createdTags} />
              </div>
            </div>
          </div>
        )}
      </div>
      {isSettingsOpen && (
        <div className="fixed inset-0 bg-black my-2 bg-opacity-50 flex items-center justify-center">
          <div className="bg-zinc-700 w-11/12 h-5/6 p-1 outline outline-blue-300 rounded-lg relative" style={{ boxShadow: '0 0 10px #67E8F9' }}>
            <button className="absolute top-0 right-0 m-1 p-1 text-blue-300 bg-zinc-950 z-50 hover:text-gray-600 rounded-lg shadow-lg" onClick={() => setIsSettingsOpen(false)}>
              <HiX size={33} />
            </button>
            {/* Modal content goes here */}
            <AccountSettings />
            {/* Add your settings content here */}
          </div>
        </div>
      )}
      {isTagCreatedPopupOpen && <TagCreatedPopup tagname={tagname} onClose={() => setIsTagCreatedPopupOpen(false)} />}
    </div>
  );
};

export default Dashboard;
