import React from 'react';


const productsData = [
    {
      id: 1,
      name: "TagIt Sticker",
      description: "This is the description for stickers.",
      image: "Sticker1.png",
      price: 4.99,
    },
    {
      id: 2,
      name: "TagIt Band",
      description: "This is the description for the sports band.",
      image:  "TagitSportsBand3.png",
      price: 15.99,
    }, 
    {
      id: 3,
      name: "TagIt UFO Keychain",
      description: "This is the description for stickers.",
      image: "ufo.png",
      price: 8.99,
    },
 

    // Add more product objects as needed

    
  ];

  

  
  
  export default productsData;

  /**
   *     {
      id: 10,
      name: "TagIt AI QR ",
      description: "This is the description for stickers.",
      image: "Sticker1.png",
      price: 1.99,
    },
   */