import React, { useState, useEffect } from 'react';
import LinkComponent from '../../TagReader/Link/LinkComponent';
import ButtonStyle from '../ButtonStyle';
import { HiXMark } from "react-icons/hi2";
import TextComponent from '../../TagReader/Text/TextComponent';

function TextModuleCreator({ onSave, initialData, onCancel, recentButtonStyles }) {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const scaleHeight = Math.min(window.innerHeight / 900, 1);
      setScale(scaleHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [selectedButtonStyleId, setSelectedButtonStyleId] = useState(0);
  const [buttonColor, setButtonColor] = useState('#99ffdd');
  const [fontColor, setFontColor] = useState('#99ffdd');
  const [selectedFontClassName, setSelectedFontClassName] = useState('font-kanit');
  const [textBoxValue, setTextBoxValue] = useState('Enter some text here...');

  useEffect(() => {
    if (initialData) {
      setTextBoxValue(initialData.textBoxValue || 'Enter some text here...');
      setSelectedButtonStyleId(initialData.buttonStyleId || 0);
      if (initialData.ButtonStyle) {
        setButtonColor(initialData.ButtonStyle.buttonColor);
        setFontColor(initialData.ButtonStyle.fontColor);
        setSelectedFontClassName(initialData.ButtonStyle.fontClassName);
        setSelectedButtonStyleId(initialData.ButtonStyle.buttonStyleId);
      }
    } else {
      setTextBoxValue('Enter some text here...');
      setSelectedButtonStyleId(0);
      setButtonColor('#99ffdd');
      setFontColor('#99ffdd');
      setSelectedFontClassName('font-kanit');
    }
  }, [initialData]);

  const handleFontColorChange = (newColor) => {
    setFontColor(newColor);
  };

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
  };

  const handleFontChange = (e) => {
    setSelectedFontClassName(e.target.value);
  };

  const handleSubmit = () => {
    const ButtonStyleData = {
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        fontColor: fontColor,
        fontClassName: selectedFontClassName,
        containerType: 'regular'
      },
      type: 'text',
      textBoxValue: textBoxValue,
      id: initialData?.id,
    };
    onSave(ButtonStyleData);
  };

  const handleTextChange = (e) => {
    setTextBoxValue(e.target.value);
  };

  const buttonSelection = (e) => {
    setSelectedButtonStyleId(Number(e.target.value));
  };

  const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

  const previewStyle = {
    backgroundColor: '#333',
    backgroundImage: `url("${svgGrid}")`,
    backgroundSize: '20px 20px',
    height: '200px',
    width: '100%',
    marginBottom: '20px',
  };

  return (
    <div className="fixed inset-0 rounded bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40 flex items-center justify-center">
      <div className="mx-auto border border-cyan-400 outline outline-1 overflow-y-auto overflow-x-hidden scrollbar-custom outline-cyan-400 shadow-lg rounded-md -mb-12 h-75vh w-10/12  sm:w-2/5 1k:w-3/12 bg-zinc-700 text-zinc-50 relative">
        <div className="text-center">
          <div style={{ ...previewStyle, position: 'relative' }}>
            <button
              className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150"
              onClick={onCancel}
            >
              <HiXMark className="text-white" size={23} />
            </button>
            <div className='flex justify-center items-center w-full h-full overflow-y-auto'>
              <div className='w-4/5 flex flex-col justify-start items-center p-2' style={{ maxHeight: 'calc(100% - 10px)',  pointerEvents :'none' }}>
                <TextComponent data={{
                  ButtonStyle: {
                    buttonStyleId: selectedButtonStyleId,
                    buttonColor: buttonColor,
                    fontColor: fontColor,
                    fontClassName: selectedFontClassName,
                    containerType: 'regular'
                  },
                  type: 'text',
                  textBoxValue: textBoxValue,
                  id: initialData?.id,
                }} />
              </div>
            </div>
          </div>

          <div>
            <textarea
              value={textBoxValue}
              onChange={handleTextChange}
              className="w-full h-32 p-2 mt-4 bg-white text-black border border-gray-300 rounded-md"
              placeholder="Enter some text here..."
            />
          </div>

          <ButtonStyle
            selectedButtonStyleId={selectedButtonStyleId}
            buttonSelection={buttonSelection}
            buttonColor={buttonColor}
            fontColor={fontColor}
            handleFontColorChange={handleFontColorChange}
            handleColorChange={handleColorChange}
            selectedFontClassName={selectedFontClassName}
            handleFontChange={handleFontChange}
            recentButtonStyles={recentButtonStyles}
          />

          <div className="flex justify-end items-center p-4">
            <button
              className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded transition duration-150 ease-in-out"
              onClick={handleSubmit}
            >
              Add Tag Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextModuleCreator;
