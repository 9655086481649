import React, { useState } from "react";
import { HiX } from "react-icons/hi";

function SimpleProduct({ product, addToCart }) {
  console.log(product);
  const [deviceClicked, setDeviceClicked] = useState(null);

  const handleClosePopup = () => {
    setDeviceClicked(null);
  };

  return (
    <div
      key={product.id}
      className="w-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-60 lg:h-60 xl:w-68 xl:h-68 2k:h-80 2k:w-80 h-24"
      style={{
        boxShadow: "0 0 20px 10px rgba(103, 232, 249, 0.5)", // Adjusted glow effect
        borderRadius: "12px", // Ensuring the image has rounded corners if needed
        background: "white", // Optional: Background color for better glow visibility
      }}
    >
      <button onClick={() => setDeviceClicked(product)}>
        <img
          src={require("../assets/" + product.image)}
          alt={product.name}
          className="rounded-lg"
        />
      </button>
      <h1 className="text-xl font-semibold my-2 text-white">{product.name}</h1>

      <div className="flex">
        <span className="font-bold mb-1 text-gray-800 text-white">
          ${product.price}
        </span>
      </div>

      {product.id === 2 ? (
        <div className="bg-cyan-600 rounded-md outline outline-1 outline-cyan-300 text-white w-full py-2 text-center">
          Coming Soon
        </div>
      ) : (
        <button
          className="bg-zinc-950 rounded-md outline outline-1 outline-cyan-300 hover:bg-blue-600 text-white w-full py-2"
          onClick={() => addToCart(product)}
        >
          Add to Cart
        </button>
      )}

      {deviceClicked && (
        <div>
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div
              className="relative flex flex-col items-center p-4 bg-zinc-950 rounded-lg h-5/6 w-11/12 sm:w-4/12"
              style={{
                boxShadow:
                  "0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9",
              }}
            >
              <button
                className="absolute top-2 right-2 m-2 p-1.5 text-cyan-300 outline outline-1 outline-cyan-300 bg-zinc-950 z-50 hover:text-gray-600 rounded-md shadow-lg"
                onClick={handleClosePopup}
              >
                <HiX size={20} />
              </button>
              <div className="w-full h-1/3 outline outline-1 outline-cyan-300 rounded-md p-4 flex justify-center items-center bg-zinc-800">
                <img
                  src={require("../assets/" + product.image)}
                  alt={product.name}
                  className="h-40 1k:h-60 p-2 rounded-lg"
                />
              </div>

              <div className="w-full mt-4 flex justify-end">
                <h1 className="text-white p-4 text-xl outline outline-1 -mx-0 outline-cyan-300 rounded-l-md">
                  {product.price}
                </h1>
                {product.id === 2 ? (
                <button
                  className={`text-white w-60 py-2 outline outline-1 outline-cyan-300 rounded-r-md`}
                  
                >
                 Coming Soon
                </button>
                ) : (
                <button
                  className={`text-white w-60 py-2 outline outline-1 outline-cyan-300 rounded-r-md`}
                  onClick={() => addToCart(product)}
                >
                  Add to Cart
                </button>
                )}
              </div>

              <div className="w-full h-3/6 text-white mt-4 flex flex-col items-center overflow-auto scrollbar-custom">
                <h1 className="p-3 text-3xl sm:text-4xl font-Orbitron text-blue-300">
                  {deviceClicked.name}
                </h1>
                <div className="mt-2 w-5/6 md:w-5/6 p-4">
                <p className="text-center text-xl font-bold">Tag with {deviceClicked.name}</p>
                <p className="text-center"> Scan or tap sharing has never been easier!</p>
                {product.id === 1 ? (<p >A 2x2 basic black and white Tagit QR code sticker with tagit logo. Pair it to any of your TagIt tags and swap it at any time.</p>)
                :product.id === 2 ? (<p >A NFC enabled wristband that lets you share your socials and more in as simple as a tap!</p>)
                :product.id === 3 ?(<p>A NFC enabled UFO Keychain that lets you share your socials and more in as simple as a tap!</p>):(<p></p>)}
                <br />
                <p className="text-center text-xl font-bold">Setup is simple: </p>
                <p> Easily swap your {deviceClicked.name} between any of your desired Tagit Tags at any time!</p>
                <p> You will recieve an activation code with your device, simply log in, scan the device, and enter the code. Then you can set your device to any of your created tags.  </p>
                <br></br>
           
              </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SimpleProduct;
