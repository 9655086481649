import React, { useState, useEffect } from 'react';
import ListOfButtons from '../ListOfButtons';
import CustomColorPicker from '../CustomColorPicker';
import ListOfFonts from '../ListOfFonts';
import { HiXMark } from "react-icons/hi2";
import ButtonStyle from '../ButtonStyle';
import RoundStyle from '../RoundStyle';
import ListOfRoundButtons from '../ListOfRoundButtons';
import PollComponent from '../../TagReader/Poll/PollComponent';

function PollModuleCreator({ onSave, initialData, onCancel, recentButtonStyles }) {
  const [scale, setScale] = useState(1);

  // Adjust scale based on window size
  useEffect(() => {
    const handleResize = () => {
      const scaleHeight = Math.min(window.innerHeight / 900, 1); // Assuming 900 is the height at which the component fits perfectly
      setScale(scaleHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set the scale

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [selectedButtonStyleId, setSelectedButtonStyleId] = useState(0); // ID of the selected button style
  const buttons = ListOfButtons(); // Get button styles

  const [buttonColor, setButtonColor] = useState('#99ffdd'); // Default color, adjust as needed
  
  const [fontColor, setFontColor] = useState('#99ffdd');

  const [selectedFontClassName, setSelectedFontClassName] = useState('font-kanit'); // Default font class name
  const fonts = ListOfFonts(); // Get the list of fonts
  const [selectedContainer, setSelectedContainer] = useState('regular');

  const [listItems, setListItems] = useState(initialData?.listItems || [{ type: '', data: '', votes: 0},{ type: '', data: '', votes: 0}]);
  

  function RadioOption({ name, value, label, checked, onChange }) {
    // Determine the background and text color based on the 'checked' prop
    const backgroundColorClass = checked ? 'bg-blue-500 text-white' : 'bg-gray-200';
    
    return (
      <label className={`px-4 py-2 border rounded ${backgroundColorClass} cursor-pointer text-black flex items-center mb-2`}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="hidden" // Hide the radio input
        />
        {label}
      </label>
    );
  }


  console.log("initialData", initialData);
  useEffect(() => {
    if (initialData) {
      setListItems(initialData.list || [{ type: '', data: '', votes: 0},{ type: '', data: '', votes: 0}]);
      setSelectedButtonStyleId(initialData.buttonStyleId || 0);
      if (initialData.ButtonStyle) { // Assuming ButtonStyle holds the style info
        setButtonColor(initialData.ButtonStyle.buttonColor);
        setFontColor(initialData.ButtonStyle.fontColor);
        setSelectedContainer(initialData.ButtonStyle.containerType);
        setSelectedFontClassName(initialData.ButtonStyle.fontClassName);
        setSelectedButtonStyleId(initialData.ButtonStyle.buttonStyleId);
        setTitle(initialData.title);
      }
    } else {
      // Reset to defaults if no initialData
      setListItems([{ type: '', data: '' }]);
      setSelectedButtonStyleId(0);
      setButtonColor('#99ffdd'); // Default color
      setFontColor('#99ffdd'); // Default color
      setSelectedFontClassName('font-kanit'); // Default font
    }
  }, [initialData]);
  
  const handleFontColorChange = (newColor) => {
    setFontColor(newColor);
  };

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
  };
  const handleFontChange = (e) => {
    setSelectedFontClassName(e.target.value);
  };
  const detectInputType = (input) => {
    if (input.startsWith("https://www.youtube.com") || input.startsWith("https://youtu.be")) {
      return 'video';
    } else if (input.startsWith("https://open.spotify.com") || input.startsWith("https://music.youtube.com")) {
      return 'music';
    } else if (input.startsWith("http://") || input.startsWith("https://")) {
      return 'link';
    } else {
      return 'text';
    }
  };

  const addItem = () => {
    if (listItems.length < 20) {
      setListItems([...listItems, { type: '', data: '', votes: 0 }]);
    }
  };

  const updateItem = (index, data) => {
    const newListItems = [...listItems];
    newListItems[index] = { type: detectInputType(data), data , votes: 0};
    setListItems(newListItems);
  };

  const removeItem = (index) => {
    const newListItems = listItems.filter((_, i) => i !== index);
    setListItems(newListItems);
  };

  // Modify the handleSubmit function to include the buttonColor in the onSave object
  const handleSubmit = () => {
    var ButtonStyleData = {};
    if (title === ''){
      ButtonStyleData = {
        ButtonStyle: {
          buttonStyleId: selectedButtonStyleId,
          buttonColor: buttonColor,
          fontColor: fontColor,
          fontClassName: selectedFontClassName,
          containerType:selectedContainer
        },
        // Include other relevant data here
        type: 'poll',
        title:title,
        list: listItems,
        voters:[],
        id: initialData?.id,
       
      };}
    else{
      console.log("there is a title");
     ButtonStyleData = {
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        fontColor: fontColor,
        fontClassName: selectedFontClassName,
        containerType:selectedContainer
      },
      // Include other relevant data here
      type: 'poll',
      title:title,
      list: listItems,
      voters:[],
      id: initialData?.id,
      
    };
    }
    onSave(ButtonStyleData);
  
    // Reset states if necessary
  };

  const buttonSelection = (e) => {
    setSelectedButtonStyleId(Number(e.target.value));
  };

  // Inline style for the preview area with SVG background
  const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

  const previewStyle = {
    backgroundColor: '#333', // Dark gray background
    backgroundImage: `url("${svgGrid}")`,
    backgroundSize: '20px 20px',
    height: '200px',
    width: '100%',
    
    marginBottom: '20px',
  };

  // Temporary placeholder for global button color
  const buttonColorGlobal = '#f0f0f0'; // Example color, adjust as needed

  return (
<div className="fixed inset-0 rounded bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40 flex items-center justify-center overflow-x-clip ">
<div className="mx-auto border border-cyan-400 outline outline-1 overflow-y-auto overflow-x-hidden scrollbar-custom outline-cyan-400 shadow-lg rounded-md -mb-12 h-75vh w-10/12  sm:w-2/5 1k:w-3/12 bg-zinc-700 text-zinc-50 relative">
        <div className="text-center">
          
        <div className='scrollbar-custom'  style={{ ...previewStyle, position: 'relative', overflowY:'scroll' }}> {/* Add position: 'relative' to the previewStyle */}
          <button 
              className="absolute z-50 top-0 right-0 mt-2 mr-2 bg-red-500 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150"
            onClick={onCancel}
          >
            <HiXMark className="text-whit  "   size={23}/>
          </button>
          <div className='flex justify-center items-center  overflow-y-auto scrollbar-custom'>
          <div className='w-4/5 flex flex-col justify-center   items-center p-2' style={{ pointerEvents :'none',}}>
            <PollComponent data={{
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        fontColor:fontColor,
        fontClassName: selectedFontClassName,
        containerType:selectedContainer
      },
      // Include other relevant data here
      type: 'poll',
      title:title,
      list: listItems,
      id: initialData?.id,
      
    }}></PollComponent>
    </div>
        </div>
      </div>



      <div>
            <h3 className="text-base font-semibold bg-transparent  w-full mx-8 text-left">Pose a question:</h3>
         
            <input 
              type="text" 
              value={title} 
              onChange={(e) => setTitle(e.target.value)}
              className="mt-2 p-2 border border-gray-300 text-black rounded w-5/6"
              placeholder="Enter question here "
            />
          </div>


<div className="flex flex-col items-center  w-full">
      <h3 className="text-base font-semibold bg-transparent py-3 w-full mx-8 text-left ml-28">Add Answers:</h3>
            {/* List items */}
            
            {listItems.map((item, index) => (
   <div key={index} className="flex justify-center p-1 w-5/6 items-center ">
   <input 
     type="text" 
     value={item.data} 
     onChange={(e) => updateItem(index, e.target.value)}
     className="p-2 border border-gray-300 text-black rounded-l w-5/6"
     placeholder="Enter text, link, YouTube video ID, or Spotify link"
   />
   <button 
     onClick={() => removeItem(index)} 
     className="  bg-blue-700 text-white hover:bg-blue-300 rounded-r p-2 -ml-1 align-middle h-full relative"
   
   >
     <HiXMark size={25}/>
   </button>
 </div>
 
            ))}
            {listItems.length < 20 && (
              <button onClick={addItem} className="my-2 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded">
                Add Answer
              </button>
            )}
          </div>


      
<ButtonStyle
  selectedButtonStyleId={selectedButtonStyleId}
  buttonSelection={buttonSelection}
  buttonColor={buttonColor} // Pass the actual button color state
  fontColor = {fontColor}
  handleFontColorChange = {handleFontColorChange} 
  handleColorChange={handleColorChange} // Pass the handler to change the button color
  selectedFontClassName={selectedFontClassName} // Pass the selected font class name state
  handleFontChange={handleFontChange}
  recentButtonStyles = {recentButtonStyles} // Pass the handler to change the font
/>


       
         <div className="flex justify-end items-center p-4">
          <button 
            className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded transition duration-150 ease-in-out"
            onClick={handleSubmit}
          >
            Add Tag Item
          </button>
        </div>
      
        </div>
      </div>
    </div>
  );
}

export default PollModuleCreator;
