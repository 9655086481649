import React, { useState, useEffect } from "react";
import productsData from "./productsData";
import Header from '../Header/Header';
import Cart from './Cart';
import { HiShoppingCart } from 'react-icons/hi';
import BannerComponent from "./BannerComponent";
import SimpleProduct from "./SimpleProduct";
import axios from "axios";
import VirtualProducts from "./VirtualProducts";

function StorePage() {
  const [cartItems, setCartItems] = useState({});
  const [cartVisible, setCartVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [virtualStock, setVirtualStock] = useState(false);


  useEffect(() => {
    if (!isLoaded) {
      getData();
      setIsLoaded(true);
    }
  }, [isLoaded]);

  const getData = () => {
    const requestConfig = {
      headers: {
        'x-api-key': process.env.REACT_APP_KEY
      },
    };

    axios.get('https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/getinventory', requestConfig)
      .then(response => {
        console.log(response);
        setVirtualStock(response.data.VirtualInventory);
      })
      .catch(error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          console.log(error.response.data.message);
        } else {
          console.log('Sorry, the server is down right now, please try again later.');
        }
      });
  };

const addToCart = (product) => {
  setCartItems(prevState => {
    if (product.id === 10) {
      // Special case for Tagit AI QR product
      const newServerID = product.ServerID;
      const newProductKey = `${product.id}-${newServerID}`;
      return {
        ...prevState,
        [newProductKey]: prevState[newProductKey]
          ? { item: { ...product }, quantity: (prevState[newProductKey].quantity || 0) + 1 }
          : { item: { ...product }, quantity: 1 }
      };
    } else if (product.id === 4) {
      // Special case for stickers
      const stickerKey = `${product.id}-${product.stickerServerID}`;
      return {
        ...prevState,
        [stickerKey]: prevState[stickerKey]
          ? { item: { ...product }, quantity: prevState[stickerKey].quantity + product.quantity }
          : { item: { ...product }, quantity: product.quantity }
      };
    } else {
      // Default case for other products
      return {
        ...prevState,
        [product.id]: prevState[product.id]
          ? { item: { ...product }, quantity: (prevState[product.id].quantity || 0) + 1 }
          : { item: { ...product }, quantity: 1 }
      };
    }
  });

  // Show cart for a limited time
  setCartVisible(true);
  setTimeout(() => {
    setCartVisible(false);
  }, 50000);
};


  const removeItemFromCart = (id, uniqueID) => {
    setCartItems(prevState => {
      const newState = { ...prevState };
      if (uniqueID) {
        delete newState[uniqueID];
      } else {
        delete newState[id];
      }
      return newState;
    });
  };
  const decreaseQuantity = (id, stickerServerID) => {
    const productKey = stickerServerID ? `${id}-${stickerServerID}` : id;
  
    setCartItems(prevState => {
      if (!prevState[productKey]) {
        // If the productKey is not found in the cart, return the previous state
        return prevState;
      }
  
      if (prevState[productKey].quantity > 1) {
        return {
          ...prevState,
          [productKey]: {
            ...prevState[productKey],
            quantity: prevState[productKey].quantity - 1
          }
        };
      } else {
        const newState = { ...prevState };
        delete newState[productKey];
        return newState;
      }
    });
  };
  
  
  const increaseQuantity = (id, stickerServerID) => {
    const productKey = stickerServerID ? `${id}-${stickerServerID}` : id;
  
    setCartItems(prevState => {
      if (!prevState[productKey]) {
        // If the productKey is not found in the cart, return the previous state
        return prevState;
      }
  
      return {
        ...prevState,
        [productKey]: {
          ...prevState[productKey],
          quantity: prevState[productKey].quantity + 1
        }
      };
    });
  };
  
  

  const setQuantity = (id, value) => {
    setCartItems(prevState => {
      if (prevState[id] && value >= 1) {
        return {
          ...prevState,
          [id]: { ...prevState[id], quantity: value }
        };
      }
      return prevState;
    });
  };


  return (
    <div className="fixed inset-0 bg-zinc-900 overflow-y-auto">
      <Header />
    
      <BannerComponent />
      <div className="flex flex-col justify-center items-center w-full 2k:w-9/12 lg:w-10/12 mx-auto p-6">
        <div className="w-full">
          <div className="flex justify-end mb-6 mx-3">
            <HiShoppingCart
              className="text-white p-1 cursor-pointer text-4xl sm:text-6xl rounded-lg outline outline-1 outline-cyan-300"
              style={{
                boxShadow: 'inset 0 0 10px #67e8f9, 0 0 7px #67e8f9',
              }}
              onClick={() => setCartVisible(prev => !prev)}
            />
          </div>
          <div className="mb-40">
            <div className="flex flex-col items-center">
              <h1 className="text-2xl sm:text-5xl mb-10 text-white font-Orbitron" style={{ textShadow: `-1px -1px 0 #67e8f9` }}>
                Planetary Merchandise:
              </h1>
              <div className="w-full max-w-screen-xl flex flex-wrap justify-center sm:gap-20 gap-3">
                {productsData.map((product) => (
                  <SimpleProduct key={product.id} product={product} addToCart={addToCart} />
                ))}
              </div>
            </div>
          </div>
          <div className="h-10"></div>
          <div>
            <h1 className="text-2xl sm:text-5xl text-white font-Orbitron" style={{ textShadow: `-1px -1px 0 #67e8f9` }}>
              Virtual Merchandise:
            </h1>
            <VirtualProducts VirtualInventory={virtualStock} addToCart={addToCart} cartItems={cartItems} />
          </div>
        </div>
        <Cart
          cartItems={cartItems}
          visibility={cartVisible}
          toggleCart={() => setCartVisible(prev => !prev)}
          removeItemFromCart={removeItemFromCart}
          decreaseQuantity={decreaseQuantity}
          increaseQuantity={increaseQuantity}
          setQuantity={setQuantity}
        />
      </div>
    </div>
  );
}

export default StorePage;
