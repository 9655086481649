import { useState, useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import { HiPause, HiPlay } from "react-icons/hi";

function AudioPlayer({ src, image, title, type, font }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

useEffect(() => {
  if (!src) {
    console.warn('Audio source URL is empty. WaveSurfer not initialized.');
    return;
  }

wavesurfer.current = WaveSurfer.create({
  container: waveformRef.current,
  waveColor: 'gray',
  progressColor: 'orange',
  cursorColor: '#333',
  barWidth: 1, // Reduced bar width
  barRadius: 1,
  cursorWidth: 1,
  height: 70, // You can also try adjusting this if height is an issue
  barGap: 1, // Reduced space between bars
  responsive: true,
});

    wavesurfer.current.load(src);

    wavesurfer.current.on('ready', () => {
      setIsPlaying(false); // Ensure playback is paused initially
    });

    wavesurfer.current.on('finish', () => {
      setIsPlaying(false);
    });

    return () => wavesurfer.current.destroy();
  }, [src]);

  const togglePlay = () => {
    if (isPlaying) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  if (type === 'regular') {
    return (
      <div className="flex items-center w-full h-full p-3 relative">
        <div className="absolute left-0 top-0 w-1/2 h-full overflow-hidden">
        {image ? (  
          <img src={image} alt="Module Content" className=" w-24 h-24" />
        ) : ( <div></div>)}
        </div>
        <button
          className="z-10 p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out"
          onClick={togglePlay}
        >
          {isPlaying ? <HiPause size={50} /> : <HiPlay size={50} />}
        </button>
        <div className="flex-1 scale-85 " id="waveform" ref={waveformRef} />
        <h1 className={`absolute bottom-0 left-1/2 ml-10 transform -translate-x-1/2 ${font}`}>{title}</h1>
      </div>
    );
  }

  
  else if (type === 'large') {
    return (
      <div className="flex flex-col items-center w-full h-full p-3 relative"> {/* Make the outer div relative */}
        {/* Conditional rendering for the image */}
        {image && (
          <img src={image} alt="Module Content" className="w-full h-full absolute top-0 left-0 object-cover" />
        )}
        {/* Position the play button over the image */}
        <button
          className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out"
          onClick={togglePlay}
        >
          {isPlaying ? <HiPause size={50} /> : <HiPlay size={50} />}
        </button>
        {/* Overlay the waveform over the image, similar positioning to the button but adjusted for below the button */}
        <div className="absolute z-10 bottom-0 left-0 w-full px-4">
          <div className="flex-1 my-2" id="waveform" ref={waveformRef}></div>
        </div>
        {/* Ensure title is visible and not overlaid, adjust its positioning as needed */}
        <h1 className={`z-10 mt-4 ${font}`}>{title}</h1>
      </div>
    );
  }
  
  
  
  

  else if (type === 'quicklink') {
    return (
      <div className=" h-12 ">
        
 
          <button
            className="  rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out"
            onClick={togglePlay}
          >
            {isPlaying ? <HiPause size={45} /> : <HiPlay size={45} />}
          </button>
    
        <div className="hidden" id="waveform" ref={waveformRef} />
        {/* No waveform for quicklink */}
      </div>
    );
  }

    else{
      return (
        <div className="flex items-center w-full h-full p-3 ">
          <button
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out"
            onClick={togglePlay}
          >
            {isPlaying ? <HiPause size={50} /> : <HiPlay size={50} />}
          </button>
          <img src={image} alt="Module Content" />
          <h1>{title}</h1>
          <div className="flex-1 p-2 px-4" id="waveform" ref={waveformRef} />
        </div>
      );
    }



  
}

export default AudioPlayer;
