import React, { useEffect } from 'react';
import 'aframe';
import arfile from '../../assets/AR/arFile1.glb';

export function ARViewer({ arFile = arfile, onClose }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/gh/AR-js-org/AR.js/aframe/build/aframe-ar.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex items-center justify-center">
      <button 
        className="absolute top-0 right-0 mt-4 mr-4 bg-red-500 hover:bg-red-700 text-white rounded-full h-10 w-10 flex items-center justify-center transition-colors duration-150"
        onClick={onClose}
      >
        X
      </button>
      <a-scene
        embedded
        arjs="sourceType: webcam; videoTexture: true; debugUIEnabled: false;"
        vr-mode-ui="enabled: false"
      >
        <a-marker preset="hiro">
          <a-entity
            gltf-model={`url(${arFile})`}
            scale="0.5 0.5 0.5"
            position="0 0 0"
          ></a-entity>
        </a-marker>
        <a-entity camera></a-entity>
      </a-scene>
    </div>
  );
}
