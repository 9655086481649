import React from 'react';
import ListOfBackgroundForegrounds from './ListOfBackgroundForegrounds';
import ImageUpload from './ImageUpload';

function BackgroundForegroundSelector({ background, setBackground }) {
  const backgroundImages = ListOfBackgroundForegrounds();

  const handleForegroundSelect = (id, imageFile) => {
    setBackground(prevState => ({
      ...prevState,
      backgroundSelection: {
        ...prevState.backgroundSelection,
        backgroundForeground: imageFile, // Set the new backgroundForeground
        selectedBgId: id, // Add this line to track the selected background ID
      }
    }));
  };
  const handleImageFileUpdate = (imageUrl) => {
    setBackground(prevState => ({
      ...prevState,
      backgroundSelection: {
        ...prevState.backgroundSelection,
        backgroundForeground: imageUrl,
      }
    }));
  };
  return (
    <div>
    <div className="flex overflow-x-auto p-2">
      {backgroundImages.map(bg => (
        <div key={bg.id} className="flex-shrink-0 p-2">
          <button 
            onClick={() => handleForegroundSelect(bg.id, bg.templateImage)} 
            className={`border ${background.backgroundSelection.backgroundForeground === bg.templateImage ? 'border-blue-500' : 'border-transparent'} rounded-md`}
          >
            <img 
              src={require(`../assets/backgroundForeground/${bg.templateImage}`)} 
              className="h-auto w-20 object-cover"  // Adjust width as needed
              style={{ aspectRatio: '1 / 1' }}  // Maintains square aspect ratio
            />
          </button>
        </div>
      ))}
    
      
    </div>
    {background.backgroundSelection.selectedBgId === 0 && (
        <div className="p-2 mt-4">
          <ImageUpload setImageFile={handleImageFileUpdate} enableCropping={false} />
        </div>
      )}
    </div>
  );
}

export default BackgroundForegroundSelector;
