
function ListOfBackgroundForegrounds() {

    var ListOfBackgroundForegrounds = [
        {id: 0 , imageUpload:true , templateImage: "1.png"},       
        {id: 1 , imageUpload:false , templateImage: "2.png"}, 
        //{id: 2 , imageUpload:false , templateImage: "3.svg"}, 
        {id: 2 , imageUpload:false , templateImage: "4.png"}, 
    ];
    
        return (ListOfBackgroundForegrounds)
          
        
      }
      
      export default ListOfBackgroundForegrounds;
      