import React, { useState, useEffect } from 'react';
import ListOfButtons from '../ListOfButtons';
import CustomColorPicker from '../CustomColorPicker';
import ListOfFonts from '../ListOfFonts';
import { HiXMark } from "react-icons/hi2";
import ButtonStyle from '../ButtonStyle';
import RoundStyle from '../RoundStyle';
import ListOfRoundButtons from '../ListOfRoundButtons';
import ListComponent from '../../TagReader/List/ListComponent';

function ListModuleCreator({ onSave, initialData, onCancel, recentButtonStyles }) {

  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const scaleHeight = Math.min(window.innerHeight / 900, 1);
      setScale(scaleHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [selectedButtonStyleId, setSelectedButtonStyleId] = useState(0);
  const buttons = ListOfButtons();

  const [buttonColor, setButtonColor] = useState('#99ffdd');
  const [fontColor, setFontColor] = useState('#99ffdd');
  const [selectedFontClassName, setSelectedFontClassName] = useState('font-kanit');
  const fonts = ListOfFonts();
  const [selectedContainer, setSelectedContainer] = useState('regular');
  const [listItems, setListItems] = useState(initialData?.listItems || [{ type: '', data: '' }]);

  function RadioOption({ name, value, label, checked, onChange }) {
    const backgroundColorClass = checked ? 'bg-blue-500 text-white' : 'bg-gray-200';
    return (
      <label className={`px-4 py-2 border rounded ${backgroundColorClass} cursor-pointer text-black flex items-center mb-2`}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="hidden"
        />
        {label}
      </label>
    );
  }

  useEffect(() => {
    if (initialData) {
      setListItems(initialData.list || [{ type: '', data: '' }]);
      setSelectedButtonStyleId(initialData.buttonStyleId || 0);
      if (initialData.ButtonStyle) {
        setButtonColor(initialData.ButtonStyle.buttonColor);
        setFontColor(initialData.ButtonStyle.fontColor);
        setSelectedContainer(initialData.ButtonStyle.containerType);
        setSelectedFontClassName(initialData.ButtonStyle.fontClassName);
        setSelectedButtonStyleId(initialData.ButtonStyle.buttonStyleId);
      }
    } else {
      setListItems([{ type: '', data: '' }]);
      setSelectedButtonStyleId(0);
      setButtonColor('#99ffdd');
      setFontColor('#99ffdd');
      setSelectedFontClassName('font-kanit');
    }
  }, [initialData]);

  const handleFontColorChange = (newColor) => {
    setFontColor(newColor);
  };

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
  };

  const handleFontChange = (e) => {
    setSelectedFontClassName(e.target.value);
  };

  const detectInputType = (input) => {
    if (input.startsWith("https://www.youtube.com") || input.startsWith("https://youtu.be")) {
      return 'video';
    } else if (input.startsWith("https://open.spotify.com") || input.startsWith("https://music.youtube.com")) {
      return 'music';
    } else if (input.startsWith("http://") || input.startsWith("https://")) {
      return 'link';
    } else {
      return 'text';
    }
  };

  const addItem = () => {
    if (listItems.length < 20) {
      setListItems([...listItems, { type: '', data: '' }]);
    }
  };

  const updateItem = (index, data) => {
    const newListItems = [...listItems];
    newListItems[index] = { type: detectInputType(data), data };
    setListItems(newListItems);
  };

  const removeItem = (index) => {
    const newListItems = listItems.filter((_, i) => i !== index);
    setListItems(newListItems);
  };

  const handleSubmit = () => {
    const ButtonStyleData = {
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        fontColor: fontColor,
        fontClassName: selectedFontClassName,
        containerType: selectedContainer
      },
      type: 'list',
      list: listItems,
      id: initialData?.id,
    };
    onSave(ButtonStyleData);
  };

  const buttonSelection = (e) => {
    setSelectedButtonStyleId(Number(e.target.value));
  };

  const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

  const previewStyle = {
    backgroundColor: '#333',
    backgroundImage: `url("${svgGrid}")`,
    backgroundSize: '20px 20px',
    height: '200px',
    width: '100%',
    marginBottom: '20px',
  };

  const buttonColorGlobal = '#f0f0f0';

  return (
    <div className="fixed inset-0 rounded bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40 flex items-center justify-center overflow-x-clip">
     <div className="mx-auto border border-cyan-400 outline outline-1 overflow-y-auto overflow-x-hidden scrollbar-custom outline-cyan-400 shadow-lg rounded-md -mb-12 h-75vh w-10/12  sm:w-2/5 1k:w-3/12 bg-zinc-700 text-zinc-50 relative">
        <div className="text-center">
          <div style={{ ...previewStyle, position: 'relative' }}>
            <button 
              className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150"
              onClick={onCancel}
            >
              <HiXMark className="text-white" size={23}/>
            </button>
            <div className='flex justify-center items-center w-full h-full p-2 overflow-y-auto scrollbar-custom'>
              <div className='w-4/5 flex flex-col justify-start items-center' style={{ maxHeight: 'calc(100% - 40px)', pointerEvents :'none' }}>
                <ListComponent data={{
                  ButtonStyle: {
                    buttonStyleId: selectedButtonStyleId,
                    buttonColor: buttonColor,
                    fontColor: fontColor,
                    fontClassName: selectedFontClassName,
                    containerType: selectedContainer
                  },
                  type: 'list',
                  list: listItems,
                  id: initialData?.id,
                }} />
                <div className='p-4' style={{ paddingBottom: '40px' }}></div> {/* Extra space here */}
              </div>
            </div>
          </div>

          <div className="mb-4 px-3 max-h-60 overflow-y-auto scrollbar-custom">
            {listItems.map((item, index) => (
              <div key={index} className="flex justify-center items-center mb-1">
                <input 
                  type="text" 
                  value={item.data} 
                  onChange={(e) => updateItem(index, e.target.value)}
                  className="p-1 border border-gray-300 text-black rounded w-5/6"
                  placeholder="Enter text, link, YouTube or Spotify link"
                />
                <button onClick={() => removeItem(index)} className="ml-2 text-red-500">
                  <HiXMark size={20}/>
                </button>
              </div>
            ))}
            {listItems.length < 20 && (
              <button onClick={addItem} className="my-2 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded">
                Add Item
              </button>
            )}
          </div>

          <ButtonStyle
            selectedButtonStyleId={selectedButtonStyleId}
            buttonSelection={buttonSelection}
            buttonColor={buttonColor}
            fontColor={fontColor}
            handleFontColorChange={handleFontColorChange}
            handleColorChange={handleColorChange}
            selectedFontClassName={selectedFontClassName}
            handleFontChange={handleFontChange}
            recentButtonStyles={recentButtonStyles}
          />

          <div className="flex justify-end items-center p-4">
            <button 
              className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded transition duration-150 ease-in-out"
              onClick={handleSubmit}
            >
              Add Tag Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListModuleCreator;
