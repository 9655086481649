import React, { useEffect, useState } from 'react';
import ListOfBackgrounds from '../TagCreator/ListOfBackgrounds';
import createAnimatedBackground from './Animations/AnimatedBackground';
import ListOfBackgroundColorFusors from '../TagCreator/ListOfBackgroundColorFusors';

const Background = ({ children, backgroundData }) => {
  const [currentBackground, setCurrentBackground] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    // Calculate and set the width and height of the container element
    const container = document.getElementById('background-container');
    if (container) {
      setContainerWidth(container.offsetWidth);
      setContainerHeight(container.offsetHeight);
    }
    
    // Your existing useEffect code to set the current background...
  }, [backgroundData]);


  useEffect(() => {
    const backgrounds = ListOfBackgrounds();
    const selectedBackground = backgrounds.find(
      bg => bg.backgroundID === backgroundData.backgroundSelection.backgroundID
    );
    setCurrentBackground(selectedBackground);
  }, [backgroundData]);

  const isWebURL = (url) => /^http/.test(url);
  const isColorCode = (value) => /^#/.test(value);

  const getBackgroundStyle = () => {
    if (!currentBackground) {
      return {};
    }

    // Handling Basic Color backgrounds
    if (currentBackground.backgroundType === 'Basic Color') {
      return { backgroundColor: backgroundData.backgroundColor1 };
    }

    // Handling Image backgrounds
    if (currentBackground.backgroundType === 'image' || currentBackground.backgroundBackground) {
      const backgroundValue = backgroundData.backgroundSelection.backgroundBackground;
      if (isWebURL(backgroundValue)) {
        return { backgroundImage: `url(${backgroundValue})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' };
      } else if (isColorCode(backgroundValue)) {
        return { backgroundColor: backgroundData.backgroundColor1 };
      } else {
        return { backgroundImage: `url(${require(`../assets/backgroundStyles/${backgroundValue}`)})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' };
      }
    }

    return {};
  };

 
  const renderFusorComponent = () => {
    if (currentBackground && currentBackground.backgroundType === 'MultiColor') {
      const backgroundColorFusors = ListOfBackgroundColorFusors();
      const fusorId = backgroundData.backgroundColorFuser;
      const selectedFusor = backgroundColorFusors.find(fusor => fusor.id === fusorId);
  
      if (selectedFusor) {
        const FusorComponent = selectedFusor.templateComponent;
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <FusorComponent colors={backgroundData.backgroundColorFusorColors} />
          </div>
        );
      }
    }
  
    return null;
  };
  


   // Function to check if screen is in landscape mode
  const isLandscape = () => containerWidth > containerHeight;
console.log("Width , height,", containerWidth, containerHeight)
console.log(isLandscape());
console.log(currentBackground);
  // Determine if it's desktop view based on aspect ratio instead of width
  const isDesktopView = isLandscape();
  // Apply specific styling if background type is 'image' and in desktop view
  const backgroundStyle = currentBackground && (currentBackground.backgroundType === 'image' || currentBackground.backgroundType === 'Motion') && isDesktopView
    ? {
        ...getBackgroundStyle(),
        width: '30%',
        height: '100%',
        margin: '0 auto',
        
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : {
        ...getBackgroundStyle(),
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      };


const glowEffectStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 5, // Ensure the glow layer is behind the content layer but above the black background
  backgroundImage: 'radial-gradient(circle, rgba(66, 153, 225, 0.7) 0%, rgba(0, 0, 0, 0) 100%)',
  pointerEvents: 'none', // Ensure the glow layer does not intercept pointer events
  mixBlendMode: 'screen', // Apply a screen blend mode to create a glow effect
};

// Style for children elements when background is neither image nor motion
const childrenStyle = !currentBackground || (currentBackground.backgroundType !== 'image' && currentBackground.backgroundType !== 'Motion') && isDesktopView
  ? {
    width: '30%',
    height: '100%',
    margin: '0 auto',
    display: 'fixed',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    }
  : {    width: '100%',
  height: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',};
  const animatedBackground = currentBackground && currentBackground.backgroundMotion
    ? createAnimatedBackground(backgroundData)
    : null;

  return (
    <div className='bg-black w-full h-full ' id='background-container'>
    <div className="absolute w-full h-full" style={glowEffectStyle}></div>
    <div className='relative z-10' style={backgroundStyle}>
      <div className="absolute w-full h-full overflow-hidden">
        {animatedBackground}
        {renderFusorComponent()}
      </div>
      <div style={childrenStyle}>{children}</div>
    </div>
  </div>
  );
};

export default Background;
