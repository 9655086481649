import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import yourBaseImage from '../assets/headercreate.png'; 
import productImage1 from '../assets/headerbackgroundtagitband.png';
import productImage2 from '../assets/headerbackgroundsticker.png';
import Preview from '../TagCreator/Preview';
function HeaderCreate() {
    const [currentImage, setCurrentImage] = useState(productImage1);
    const [isBlinking, setIsBlinking] = useState(false);
    const [modules, setModules] = useState([
        {
            id: 1721338551156,
            type: 'list',
            list: [
                {
                    type: 'text',
                    data: 'Welcome to TagIt',
                },
            ],
            ButtonStyle: {
                buttonColor: "#42ffe0",
                buttonStyleId: 1,
                containerType: "regular",
            },
        }
       
    ]);
    
    const [editingModule, setEditingModule] = useState(null);
  
    const [infoBar, setInfoBar] = useState({
       
    });
    
    const [profilePictureInfo, setProfilePictureInfo] = useState({
        generalLocation: 'top',
   
        displayProfilePicture: true,
        profilePictureLocation: 'center',
        backgroundOpacity: '30',
    });
    
      const [background, setBackground] = useState({
        backgroundSelection: {
          backgroundID:1,
          backgroundForeground:'2.png',
          backgroundBackground:'#2efff1',
      },
      backgroundColor1 : "#4d3c0f",
      backgroundColor1: "#000000",
      backgroundColorFuser: 0,
      backgroundColorFusorColors:  ['#42a4ff', '#7ae65c'],
        backgroundMotion:'rain',
        backgroundSpeed:'1x',
    
      });
    
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(current => (current === productImage1 ? productImage2 : productImage1));
        }, 7000); // Change image every 6 seconds

        const blinkInterval = setInterval(() => {
            setIsBlinking(true);
            setTimeout(() => setIsBlinking(false), 100); // Blink for a short duration
        }, Math.random() * 10000 + 5000); // Random interval for blinking

        return () => {
            clearInterval(interval);
            clearInterval(blinkInterval);
        };
    }, []);

    const imageVariants = {
        visible: { opacity: 1, scaleY: 1, originY: 1 },
        hidden: { opacity: 0, scaleY: 0, originY: .3 },
        blink: { opacity: 0 }
    };

    return (
        <div className='relative  w-full h-full'>
        
        <img 
            src={yourBaseImage} 
            alt='Header Dashboard' 
            className='relative w-full h-auto z-10' // Positioned relatively, above the glow
        />
        </div>
    );
}

export default HeaderCreate;
