import React, { useState } from 'react';
import { HiX } from "react-icons/hi";
import Device from './Device'; // Assuming Device component is imported from the correct path
import DevicePreview from './DevicePreview';

function Devices({ Devices, CreatedTags }) {
    const [deviceClicked, setDeviceClicked] = useState(null);

    const handleDeviceClick = (device) => {
        setDeviceClicked(device);
    };

    const handleClosePopup = () => {
        setDeviceClicked(null);
    };

    return (
        <div className="w-full h-full bg-zinc-950 rounded-lg p-4">
            <h1 className="text-lg ml-3 font-semibold text-white">Devices:</h1>
            <div className="flex h-5/6 w-full bg-zinc-950 rounded-md overflow-x-auto overflow-y-hidden scrollbar-custom">
                <div className="flex flex-nowrap">
                    {Devices.map((device, index) => (
                        <button
                            key={index}
                            onClick={() => handleDeviceClick(device)}
                            className="flex flex-col items-center justify-center bg-zinc-950 outline-blue-300 outline outline-1 rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-300 text-black hover:text-gray-50 font-base focus:outline-none focus:shadow-outline m-2"
                        >
                            <div>
                                <DevicePreview Device={device}/>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            {deviceClicked && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex p-4 justify-center items-center z-50">
                    <div className="relative flex overflow-auto scrollbar-custom p-4 bg-zinc-950 rounded-lg max-w-lg w-full  sm:h-85vh 1k:h-4/6 h-75vh 2k:h-4/6" style={{ boxShadow: '0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9, 0 0 3px #67E8F9' }}>
                        <button className="absolute top-0 right-0 m-2 p-1.5 text-blue-300 bg-zinc-950 z-50 hover:text-gray-600 rounded-l shadow-lg" onClick={handleClosePopup}>
                            <HiX size={33} />
                        </button>
                        <Device Device={deviceClicked} CreatedTags={CreatedTags} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Devices;
