import React, { useState } from 'react';
import { getUser } from "../../service/AuthService";
import { QRCode } from 'react-qrcode-logo';
import { HiSave } from "react-icons/hi";
import axios from 'axios';





function CollectedClickedTag(data) {
    console.log('Data from clicked: ', data);
    const user = getUser();
 

    const handleDeleteTag = () => {
        const homeTagSetAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/deletecollectedtag';
        console.log('deleting tag')
          const requestBody = {
            TagCollector: user.username,
            TagName:data.ClickedTag.TagName
          };
        
          axios.post(homeTagSetAPIURL, requestBody).then((response) => {
            console.log("HERE IS THE RESPONSE FOR YOUR TAG")
            console.log(response);
            window.location.reload();
          }).catch((error) =>{
            if(error.response.status === 401 || error.response.status === 403){
              console.log(error.response.data.message);
            }else{
              console.log('Sorry, the server is down right now, please try again later.');
              console.log(error.response.data.message);
            }
          })
        
        };

    const handleCopyLink = () => {
        if(data.ClickedTag.TagName === data.HomeTag){
        navigator.clipboard.writeText(`tagit.link/${user.username}`);
        }
        else {    navigator.clipboard.writeText(`tagit.link/${user.username}/${data.ClickedTag.TagName}`); }
    };


    const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/></svg>`)}`;

    const previewStyle = {
        backgroundColor: '#333', // Dark gray background
        backgroundImage: `url("${svgGrid}")`,
        backgroundSize: '20px 20px',
        height: '50%',
        width: '100%',
        marginBottom: '20px',
        position: 'relative' // Make the container relatively positioned
    };

    return (
        <div className="flex w-full h-full justify-center items-top">
            <div className="w-full">
                <div style={previewStyle} className='flex justify-center items-center'>
                    <QRCode
                        qrStyle="dots"
                        bgColor='#a8c4e3'
                        size={200}
                        ecLevel="H"
                        logoPadding={5}
                        logoHeight={30}
                        logoWidth={80}
                        removeQrCodeBehindLogo={true}
                        logoImage={require("../../assets/tagitblackwhite.png")}
                        value={`tagit.link/${data.ClickedTag.TagCreator}/${data.ClickedTag.TagName}`}
                        eyeRadius={5}
                    />
                    <button className='absolute -bottom-2 -right-2 bg-zinc-950 p-2  rounded-l shadow-lg '>
                        <HiSave className="text-blue-300 hover:text-gray-600" size={30} />
                    </button>
                </div>
                <div className="mt-4 flex items-center">
                    <input
                        type="text"
                        value={`tagit.link/${data.ClickedTag.TagCreator}/${data.ClickedTag.TagName}`}
                        readOnly
                        className="border text-white text-xl font-semibold bg-black outline-3 outline outline-cyan-500  p-1 w-full rounded-l-lg"
                      
                    />
                    <button
                        onClick={handleCopyLink}
                        className=" p-1  rounded-r-lg border text-white text-xl font-semibold bg-sky-500 outline-3 outline hover:bg-cyan-800  outline-cyan-500"
                        style={{ boxShadow: '0 0 6px #67E8F9 ' }}
                    >
                        Copy
                    </button>

                </div>
              
                <button
                        onClick={() => handleDeleteTag()}
                        className="mt-4 p-1 border text-white text-xl font-semibold bg-red-500  outline-3 outline hover:bg-red-800  outline-cyan-500   w-full rounded-lg"
                        style={{ boxShadow: '0 0 7px #67E8F9 ' }}
                    >
                    Delete tag
                </button>
            </div>
        </div>
    );
}

export default CollectedClickedTag;
