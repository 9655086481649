import './Home.css';

import React, { useEffect, useState } from 'react';
import Header from "../Header/Header";
import ufo1 from '../assets/GIFTest15.gif';
import ufo2 from '../assets/GIFTest16.gif';
import qrexample from '../assets/qrex.gif';
import deviceexample from '../assets/deviceex.gif';
import linkexample from '../assets/linkex.gif';
import homevideo from '../assets/Working2.mp4';
import { motion } from 'framer-motion';
import leaf from '../assets/tagittagleaf4.png';
import TractorBeam from './TractorBeam';

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= window.innerHeight);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const createImageGrid = () => {
    const grid = [];
    const columnCount = Math.floor(window.innerWidth / (isMobile ? 100 : 200)); // Adjust column count based on screen width
    const rowCount = 15; // Number of rows (fewer for mobile)
    const movementDuration = 20; // Duration for each image to move across the screen
    const spacingX = 100 / columnCount; // Even spacing for X position


    for (let col = 0; col < columnCount; col++) {
      for (let row = 0; row < rowCount; row++) {
        if (Math.random() < 0.2) { // 20% chance to render the item
          grid.push(
            <motion.img
              className='z-10'
              key={`${col}-${row}`}
              src={leaf}
              alt="Leaf"
              initial={{ y: '-150%', opacity: 1, x: `${spacingX * col}%` }}
              animate={{y: isMobile ? '800%' : '880%', opacity: row === rowCount - 1 ? [1, .99, 0.7,.6, .1] : .00 }}
              transition={{
                delay: row * 2,
                duration: movementDuration,
                ease: 'linear',
                repeat: Infinity,
                repeatDelay: 2 // Add repeatDelay to create a smoother transition
              }}
              style={{
                width: isMobile ? '15%' : '4%', // Bigger size for leaves on mobile
                height: 'auto',
                position: 'absolute',
                left: `${spacingX * col}%`,
                top: '0%',
                zIndex: 0,
                opacity: 1
              }}
            />
          );
        }
      }
    }

    return grid;
  };

  const imageGrid = createImageGrid();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= window.innerHeight);
    };

    // Preload images
    const preloadImages = [ufo1, ufo2].map((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        if (preloadImages.every(img => img.complete)) {
          setImagesLoaded(true);
        }
      };
      return img;
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  return (
    <div className="fixed inset-0 h-full bg-black overflow-y-auto flex flex-col items-center scrollbar-custom">
      <Header />
      <div className='w-full min-h-full flex-grow flex justify-center items-center relative bg-black z-30'>
        <div className="relative flex justify-center items-center w-full h-full">
          <img 
            className={`max-h-screen h-full ${isMobile ? 'w-full' : 'w-auto'} object-contain`} 
            src={imagesLoaded ? (isMobile ? ufo2 : ufo1) : ufo2} 
            alt={isMobile ? "UFO 2" : "UFO 1"} 
          />
          <motion.div
           
            className="absolute h-40 sm:h-40 md:h-40 lg:h-56 rounded-lg transform -translate-x-1/2 -translate-y-1/2"
            alt="Band"
            initial={{ rotate: 0, y: 0 }}
            animate={{ rotate: [1, 3, 1], y: [0, 2, 4, 5, 3, 1] }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "loop"
            }}
          ><TractorBeam></TractorBeam></motion.div>
        </div>
        < a href='https://tagit.link/newaccount'
          className="text-white text-center sm:text-xl font-Orbitron w-60 py-4 my-1 sm:my-12 outline outline-1 bg-cyan-700 hover:bg-cyan-800 outline-cyan-300 rounded-md absolute bottom-4 left-1/2 transform -translate-x-1/2 z-30"
          style={{
            boxShadow: 'inset 0 0 10px #67e8f9, 0 0 7px #67e8f9',
          }}
        >
          Sign up
        </a>
      </div>
      <div className="w-full relative sm:-my-5 min-h-max text-center text-white">
        <svg 
          className={`absolute top-0 left-0 z-30 w-full ${isMobile ? 'h-2' : 'h-8'}`} 
          viewBox={isMobile ? "0 0 100 1" : "0 0 100 12"} 
          preserveAspectRatio="none"
          style={{
            fill: 'none',
            stroke: '#22d3ee',
            strokeWidth: 1,
            filter: 'drop-shadow(0 0 5px #22d3ee)',
          }}
        >
          {isMobile ? (
            <line x1="0" y1="0.01" x2="100" y2="0.05" />
          ) : (
            <polyline points="0,8 25,8 50,8 75,8 100,8" />
          )}
        </svg>
        <div className="relative w-full z-10 bg-black mt-6">
          {/* Content goes here */}
          <div className='absolute w-full h-full z-0 '>{imageGrid}</div>
          <div className='relative p-10   w-full h-full   z-30  rounded-lg flex items-center justify-center'> 
          
          <div className='w-11/12 sm:w-8/12  bg-zinc-800 rounded-md outline outline-1 outline-cyan-300' style={{ boxShadow: '0 0 10px #67e8f9, 0 0 7px #67e8f9', maxWidth: '1200px' }}>
          <video 
    className='rounded-md' 
    autoPlay 
    loop 
    muted 
    playsInline 
    style={{ width: '100%', height: 'auto' }}
  >
    <source src={homevideo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>

          </div>
        </div>

        <div className="w-full text-center p-10 mb-8">
    <h2 className="text-4xl sm:text-5xl font-Orbitron text-white pb-2 border-b-4 border-cyan-300 inline-block">
      Ways to Share:
    </h2>
  </div>
        <div className="relative w-full h-full   z-30 rounded-lg flex items-start justify-center ">





  <div className="w-11/12 sm:w-6/12 rounded outline outline-1 outline-cyan-300 p-6">

    {/* Section 1 */}
    <div className="w-full flex flex-col sm:flex-row items-center justify-between mb-10 pb-6 border-b border-cyan-300">
      {/* Left Section: Image */}
      <div className="w-full sm:w-1/3 mb-6 sm:mb-0 sm:mr-6 sm:border-r justify-center items-center flex outline outline-1 outline-cyan-300 sm:border-cyan-300">
        <img src={linkexample} alt="TagIt Example 1" className="w-4/5 h-auto rounded-md" />
      </div>
      {/* Right Section: Text */}
      <div className="w-full sm:w-2/3">
        <h1 className="text-5xl font-Orbitron">Links</h1>
        <br />
        <p className="p-4 text-xl sm:text-2xl font-Orbitron leading-relaxed">
         Set your Link-In-Bio to your TagIt HomeTag and swap content at any point. Quickly link everything that matters to you all in one place and share your way.  
        </p>
      </div>
    </div>

    {/* Section 2 */}
    <div className="w-full flex flex-col sm:flex-row items-center justify-between mb-10 pb-6 border-b border-cyan-300">
      {/* Left Section: Image */}
      <div className="w-full sm:w-1/3 mb-6 sm:mb-0 sm:mr-6 sm:border-r justify-center items-center flex outline outline-1 outline-cyan-300 sm:border-cyan-300">
        <img src={qrexample} alt="TagIt Example 1" className="w-4/5 h-auto rounded-md" />
      </div>
      {/* Right Section: Text */}
      <div className="w-full sm:w-2/3">
        <h1 className="text-5xl font-Orbitron">QR Codes</h1>
        <br />
        <p className="p-4 text-xl sm:text-2xl font-Orbitron leading-relaxed">
          Download and customize QR codes to each of your TagIt tags directly from your dashboard, or add a little style with ARTQR sold in our shop.
        </p>
      </div>
    </div>

    {/* Section 3 */}
    <div className="w-full flex flex-col sm:flex-row items-center justify-between">
      {/* Left Section: Image */}
      <div className="w-full sm:w-1/3 mb-6 sm:mb-0 sm:mr-6 sm:border-r justify-center items-center flex outline outline-1 outline-cyan-300 sm:border-cyan-300">
        <img src={deviceexample} alt="TagIt Example 1" className="w-4/5 h-auto rounded-md" />
      </div>
      {/* Right Section: Text */}
      <div className="w-full sm:w-2/3">
        <h1 className="text-5xl font-Orbitron">NFC Tap</h1>
        <br />
        <p className="p-4 text-xl sm:text-2xl font-Orbitron leading-relaxed">
          Connect with a tap. Simply tap your ufo keychain and beam your socails directly into NFC enabled devices.
        </p>
      </div>
    </div>

  </div>




  


  
</div>



      </div>
    </div>
  );
}

export default Home;
