import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import LoginPopover from '../service/LoginPopover';
import { getUser } from '../service/AuthService';
import { Elements } from '@stripe/react-stripe-js';

import axios from 'axios';
import loadinggiff from '../assets/loading.gif';

const checkoutAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/create-payment';

function FullScreenCheckout({ exit, cartItems, setUserSession }) {
  const [login, setLogin] = useState(false);
  const [guestCheckout, setGuestCheckout] = useState(false);
  const [user, setUser] = useState(null);

  var UserName = 'null';


  useEffect(() => {
    setUser(getUser());
   
  }, []);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const handleLogin = () => {
    setLogin(true);
  };

  const handleGuestCheckout = () => {
    setGuestCheckout(true);
    stripeCheckout();
  };


  // Handler to close the login screen
  const closeLogin = () => {
    setLogin(false);
    setUser(getUser());
    UserName = user.username;
    stripeCheckout();
    console.log("HERE IS THE INTENDED RESULT");
  };

  function  stripeCheckout(){
    console.log(cartItems);
    console.log("here are the items :");
    console.log(UserName);

    
    const requestBody = {
      name:UserName,
      cart:cartItems
    }

    axios.post(checkoutAPIUrl, requestBody, ).then((response) => {
      console.log(response.data);
      var targetUrl = response.data.checkoutLink;
      window.location.href = targetUrl;
    }).catch((error) => {
      if(error.response.status === 401 || error.response.status === 403){
       alert(error.response.data.message);
      }else{
        alert('Sorry, the server is down right now, please try again later.');
      }

    })
    
  }

  if (user!=null){
    console.log("USER NOT NULL");
    UserName=user.username;
    stripeCheckout();
  }

  return (
    <motion.div
      className="fixed top-0 left-0 w-full h-screen bg-white z-50 flex items-center justify-center"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={{ type: "spring", stiffness: 60, damping: 20 }}
    >
      {user === null && !login && !guestCheckout && (
        <div className="border p-4">
          <h1 className="text-3xl font-bold mb-4">Checkout</h1>
          <button className="mr-2 p-2 bg-blue-500 text-white" onClick={handleLogin}>Log In</button>
          <button className="p-2 bg-green-500 text-white" onClick={handleGuestCheckout}>Checkout as Guest</button>
        </div>
      )}

      {login && (
        <LoginPopover onLoginSuccess={closeLogin} />
      )}

      {guestCheckout && (
        <div id="loadingg" class="mt-8"><img class="max-w-full max-h-400" src={loadinggiff} alt="Loading" /></div>
      )}

      {user !== null && (
        <div id="loadingg" class="mt-8"><img class="max-w-full max-h-400" src={loadinggiff} alt="Loading" /></div>
      )}

      <button className="p-2 bg-red-500 text-white absolute bottom-4 right-4" onClick={exit}>Close Checkout</button>
    </motion.div>
  );
}

export default FullScreenCheckout;
