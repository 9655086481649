import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getUser } from '../service/AuthService';
import countries from './countries'; 
import CustomColorPicker from './CustomColorPicker';


function InfoBarEditor({ infoBar, setInfoBar }) {
  const [showLocationForm, setShowLocationForm] = useState(false);
  const [isInfoBarExpanded, setIsInfoBarExpanded] = useState(false);

  useEffect(() => {
    if ( infoBar.displayLocation != 'none') {
      setIsInfoBarExpanded(true);
    }
  }, [infoBar]);

  const canAccessLocationForm = true;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('location.')) {
      const locationKey = name.split('.')[1];
      setInfoBar(prev => ({
        ...prev, 
        location: { ...prev.location, [locationKey]: value }
      }));
    } else {
      setInfoBar(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleCheckboxChange = () => {
    setShowLocationForm(prev => {
      if (prev) { // If the checkbox is currently checked
        // Only reset the location part of the state
        setInfoBar(prevInfoBar => ({
          ...prevInfoBar,
          location: {
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: 'United States'
          }
        }));
      }
      return !prev;
    });
  };
  
  const clearFormValues = () => {
    setInfoBar({
      name: '',
      title: '',
      phone: '',
      email: '',
      infoBarColor:'#FFFFFF',
      infoBarButtonColor:'#1a1a1a',
      displayLocation:"none",
      displayType:"buttons",
      location: {
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States'
      },
      // Include any other fields you want to reset
    });
  };

  const toggleInfoBar = () => {
    setIsInfoBarExpanded(prev => {
      if (prev) {
        // If we're turning the toggle off, clear all form values and set displayLocation to "none"
        clearFormValues();
        setInfoBar(prevInfoBar => ({
          ...prevInfoBar,
          displayLocation: "none" // Update displayLocation to "none" when InfoBar is collapsed
        }));
      } else {
        // Optional: Set a default displayLocation when the InfoBar is expanded
        // This step depends on your application's requirements
        setInfoBar(prevInfoBar => ({
          ...prevInfoBar,
          displayLocation: "top" // or "bottom" or any default value you prefer
        }));
      }
      return !prev;
    });
  };
  const handleOpacityChange = (e) => {
    const { value } = e.target;
    setInfoBar(prev => ({
      ...prev,
      backgroundOpacity: value
    }));
  };
  
  const handleColorChange = (color, name) => {
    setInfoBar(prev => ({
      ...prev,
      [name]: color,
    }));
  };

  const toggleSwitchVariants = {
    on: { x: "100%" },
    off: { x: "0%" }
  };


      const RadioOption = ({ name, value, label }) => (
        <label className={`px-4 py-2 text-black border rounded ${infoBar[name] === value ? 'bg-blue-500 ' : 'bg-gray-200'} cursor-pointer`}>
          <input
            type="radio"
            name={name}
            value={value}
            checked={infoBar[name] === value}
            onChange={handleChange}
            className="hidden"
          />
          {label}
        </label>
      );
      const contentVariants = {
        hidden: {
          opacity: 0,
          maxHeight: 0, // Collapse the content
          overflow: 'hidden', // Prevent showing any part of the content when collapsed
          transition: {
            opacity: { duration: 0.2 }, // Faster fade out to match collapsing animation
            maxHeight: { duration: 0.5 }
          }
        },
        visible: {
          opacity: 1,
          maxHeight: 1000, // Sufficiently high to accommodate content, adjust based on actual content
          transition: {
            opacity: { delay: 0.1, duration: 0.3 }, // Delayed fade in for smoother appearance
            maxHeight: { duration: 0.5 }
          }
        },
      };
   
      return (
        <div className="info-bar-editor p-4 bg-zinc-700 outline-1 outline-cyan-400 outline text-zinc-50 rounded shadow">
        <div className="flex justify-between items-center "> {/* Adjusted spacing */}
          <h2 className="text-lg font-semibold">Info Bar</h2>
          <div
            className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${isInfoBarExpanded ? 'bg-blue-500' : 'bg-blue-500'}`}
            onClick={toggleInfoBar}
          >
            <motion.div className="bg-white w-6 h-6 rounded-full shadow"
              animate={isInfoBarExpanded ? "on" : "off"}
              variants={toggleSwitchVariants}
              transition={{ type: "spring", stiffness: 700, damping: 30 }}
            ></motion.div>
          </div>
        </div>
    
  
        <motion.div
          variants={contentVariants}
          initial="hidden"
          animate={isInfoBarExpanded ? "visible" : "hidden"}
          className="space-y-4" // Ensure spacing between child elements
        >
      {isInfoBarExpanded && (
               <>

    <CustomColorPicker
        label="Info Bar Color"
        value={infoBar.infoBarColor}
        onChange={(color) => handleColorChange(color, 'infoBarColor')}
      />
  {/* Opacity Slider */}
  <div className="flex items-center space-x-2 my-4">
            <label htmlFor="backgroundOpacity" className="">Info Bar Opacity:</label>
            <input
              id="backgroundOpacity"
              type="range"
              name="backgroundOpacity"
              min="0"
              max="100"
              value={infoBar.backgroundOpacity || 100} // Default to 100 if not set
              onChange={handleOpacityChange}
              className="w-full"
            />
            <span>{infoBar.backgroundOpacity || 100}%</span>
          </div>
{(showLocationForm || infoBar.displayType === 'buttons') && (
    <CustomColorPicker
   
    label="Buttons Color"
    value={infoBar.infoBarButtonColor}
    onChange={(color) => handleColorChange(color, 'infoBarButtonColor')}
  />
)}

          {/* Display Location Radio Buttons */}
          <div className="flex  items-center space-x-2">
            <span className="">Display Bar Position:</span>
            <RadioOption name="displayLocation" value="top" label="Top" />
            <RadioOption name="displayLocation" value="bottom" label="Bottom" />
          </div>

          {/* Display Style Radio Buttons */}
          <div className="flex items-center space-x-2">
            <span className="text-zinc-50">Display Style:</span>
            <RadioOption name="displayType" value="simple" label="Simple" />
            <RadioOption name="displayType" value="buttons" label="Buttons" />
          </div>

          {/* Name Input */}
          <input
            className="w-full p-2 border text-black border-gray-300 rounded"
            type="text"
            name="name"
            value={infoBar.name}
            onChange={handleChange}
            placeholder="Name"
          />

          {/* Title Input */}
          <input
            className="w-full p-2 border text-black border-gray-300 rounded"
            type="text"
            name="title"
            value={infoBar.title}
            onChange={handleChange}
            placeholder="Title"
          />

          {/* Phone Input */}
          <input
            className="w-full p-2 border text-black border-gray-300 rounded"
            type="tel"
            name="phone"
            value={infoBar.phone}
            onChange={handleChange}
            placeholder="Phone"
          />

          {/* Email Input */}
          <input
            className="w-full p-2 border text-black border-gray-300 rounded"
            type="email"
            name="email"
            value={infoBar.email}
            onChange={handleChange}
            placeholder="Email"
          />

          {/* Conditional Checkbox and Location Inputs */}
          {canAccessLocationForm && (
            <div>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={showLocationForm}
                  onChange={handleCheckboxChange}
                />
                <span>Enter a location</span>
              </label>

              {showLocationForm && (
                <>
                  <input
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    type="text"
                    name="location.street"
                    value={infoBar.location.street}
                    onChange={handleChange}
                    placeholder="Street Address"
                  />
                  <input
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    type="text"
                    name="location.city"
                    value={infoBar.location.city}
                    onChange={handleChange}
                    placeholder="City"
                  />
                  <input
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    type="text"
                    name="location.state"
                    value={infoBar.location.state}
                    onChange={handleChange}
                    placeholder="State"
                  />
                  <input
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    type="text"
                    name="location.postalCode"
                    value={infoBar.location.postalCode}
                    onChange={handleChange}
                    placeholder="Postal Code"
                  />
          <select
            className="w-full p-2 border text-black border-gray-300 rounded"
            name="location.country"
            value={infoBar.location.country}
            onChange={handleChange}
          >
            {countries.map(({ name, code }) => (
              <option key={code} value={name}>{name}</option>
            ))}
          </select>

                </>
              )}
            </div>
          )}

          {/* Optionally show a message if the user does not have access */}
          {!canAccessLocationForm && (
            <div className="text-red-500">
             
            </div>
          )}
        </>
      )}
       </motion.div>
    </div>
  );
}

export default InfoBarEditor;
