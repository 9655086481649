import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ListOfBackgrounds from './ListOfBackgrounds';
import img from '../assets/TagitCards.png';
import BackgroundForegroundSelector from './BackgroundForegroundSelector';
import BackgroundBackgroundSelector from './BackgroundBackgroundSelector';
import BackgroundColorFuserSelector from './BackgroundColorFuserSelector';
import CustomColorPicker from './CustomColorPicker';
import ListOfBackgroundColorFusors from './ListOfBackgroundColorFusors';
import ListOfBackgroundImages from './ListOfBackgroundImages';
import leaf from '../assets/backgroundForeground/2.png'



const createImageGrid = () => {
  const grid = [];
  const gridSize = 5; // For a 5x5 grid
  const movementDuration = 25; // Duration for each image to move across the screen

  // You can adjust these initial offsets and delays as needed
  const initialOffsets = ['-200%', '200%', '-200%', '200%', '-200%', '200%', '-200%', '200%', '-200%', '200%', '-200%'];
  const delays = [0, 3,6,10,12,15,10,];

  for (let row = 0; row < gridSize; row++) {
    for (let col = 0; col < gridSize; col++) {
      const index = row * gridSize + col;
      grid.push(
        <motion.img
          key={index}
          src={leaf}
          alt="Leaf"
          initial={{ y: '-400%', x: initialOffsets[index % initialOffsets.length] }}
          animate={{ y: '1000%', x: initialOffsets[index % initialOffsets.length] }}
          transition={{
            delay: delays[index % delays.length],
            duration: 10,
            ease: 'linear',
            repeat: Infinity
          }}
          style={{
            width: '30%',
            height: '30%',
            position: 'absolute',
            left: `${col * 20}%`,
            top: `${row * 20}%`,
            zIndex: 0,
            opacity: 1
          }}
        />
      );
    }
  }

  return grid;
};
  
  

// Custom hook to cycle through the images
function useCycleImages(images, interval = 3000) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((currentIndex) => (currentIndex + 1) % images.length);
    }, interval);
    return () => clearInterval(timer);
  }, [images.length, interval]);

  return images[index];
}


function BackgroundSelector({ background, setBackground }) {
  const backgrounds = ListOfBackgrounds();
  const [selectedBackgroundId, setSelectedBackgroundId] = useState(background.backgroundSelection.backgroundID);

  const handleSelect = (backgroundId) => {
    setSelectedBackgroundId(backgroundId);
    const selectedBackground = backgrounds.find(bg => bg.backgroundID === backgroundId);
  
    setBackground(prevState => ({
      ...prevState,
      backgroundSelection: {
        ...prevState.backgroundSelection,
        backgroundID: selectedBackground.backgroundID,
        
        // Include other properties from selectedBackground that you want to update
      },
      // Do not reset other attributes; keep their current state
    }));
  };
// In BackgroundSelector component
const updateFusorColors = (fusorId, colors) => {
  setBackground(prevState => ({
    ...prevState,
    backgroundColorFusorColors: colors
  }));
};
  const handleInputChange = (event, attribute) => {
    setBackground(prevState => ({
      ...prevState,
      [attribute]: event.target.value
    }));
  };
  const motionOptions = [ "rain","scroll down", "scroll right","scroll left and right"];
  const speedOptions = ["-1x", "1x", "1.5x", "2x"];

  const selectedBackground = backgrounds.find(bg => bg.backgroundID === selectedBackgroundId);
  const backgroundColorFusors = ListOfBackgroundColorFusors();
  const selectedFusor = backgroundColorFusors.find(fusor => fusor.id === -1);
  const FusorComponent = selectedFusor.templateComponent;
  const imageGrid = createImageGrid();
  const backgroundImages = ListOfBackgroundImages().slice(2); // Skip the first two images
  const currentImage = useCycleImages(backgroundImages, 5000); // Change image every 5 seconds

  // Animation variants for Framer Motion
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };






  return (
   <div className="overflow-x-auto bg-zinc-700 outline-1 outline-cyan-400 outline shadow text-zinc-50 rounded p-2">
    <div className='px-2'>
     <h1 className="text-lg font-semibold">Background Selection:</h1>
     <br></br>
     
     </div>
  <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-wrap sm:justify-center">
    
  {backgrounds.map(bg => (
  <label 
    key={bg.backgroundID} 
    className={`flex flex-col items-center border border-gray-900 rounded-md p-2 transition-colors duration-300 hover:bg-blue-300 cursor-pointer ${selectedBackgroundId === bg.backgroundID ? 'bg-blue-500' : ''}`}
  >
    <input 
      className="hidden " 
      type="radio" 
      defaultChecked={selectedBackgroundId === bg.backgroundID} 
      name="backgroundSelector" 
      onClick={() => handleSelect(bg.backgroundID)} 
      value={bg.backgroundID} 
    />
    <div className={`radio-btns w-16 h-16 sm:w-20 sm:h-20 rounded-md shadow-md transition-colors duration-300 ${selectedBackgroundId === bg.backgroundID ? 'bg-blue-700' : 'hover:bg-blue-700'} text-black font-base focus:outline-none focus:shadow-outline`}>
      {
        {
          'Basic Color': (
            <div style={{ backgroundColor: background.backgroundColor1, width: '100%', height: '100%' }} className='rounded-md shadow-md'></div>
          ),
          'MultiColor': (
            // Assuming MultiColor is a gradient or similar
            <div className='shadow-md rounded-md overflow-hidden' style={{width: '100%', height: '100%'}}>
              <FusorComponent colors={['#fcff47', '#13a444']} />
            </div>

          ),
          'Image': (
            <div className='shadow-md rounded-md overflow-hidden' style={{width: '100%', height: '100%'}} >
            <AnimatePresence initial={false}>
              <motion.div
                key={currentImage.id}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                className=""
              >
                {currentImage.backgroundType === "image" && (
                  <img
                    src={require(`../assets/backgroundStyles/${currentImage.templateImage}`)} // Adjust path as needed
                    alt={currentImage.name}
                    className=" -my-10 "
                  />
                )}
                {/* Handle other types (color, etc.) as needed */}
              </motion.div>
            </AnimatePresence>
          </div>
          ),
          'Motion': (
            <div 
              style={{ backgroundColor: background.backgroundColor1, width: '100%', height: '100%' }} 
              className='rounded-md shadow-md overflow-hidden relative' >
             
              <img
                    src={require(`../assets/tagItemButtons/cloudysky.gif`)} // Adjust path as needed
                    alt={currentImage.name}
                    className="  "
                  />
                  
                  <div style={{
    position: 'absolute',
    top: '40%', // Start the grid at 20% from the top, effectively blocking off the top 20%
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden' // Ensure that any content outside this area is clipped
  }}>
                   {imageGrid}
                </div>
                   
            </div>
          ),
          
        }[bg.name || 'Basic Color'] // Default to 'Basic Color' if bg.type is undefined
      }
    </div>
    <p className="text-xs sm:text-sm py-1 px-2 text-center">{bg.name}</p>
  </label>
))}

    </div>  
    <div className='justify-center flex items-center p-4'>
      {/* Color pickers */}
      {selectedBackground?.backgroundColor1 && (
        <div className=' p-2 mr-2'>
        <CustomColorPicker
        label="Background Color"
        value={background.backgroundColor1}
        onChange={(newValue) => handleInputChange({ target: { value: newValue } }, 'backgroundColor1')}
        
      />
      </div>
      )}

     

      {/* Dropdown for motion */}
      {selectedBackground?.backgroundMotion && (
     <div className="relative inline-block text-gray-700 m-2">
     <select
       value={background.backgroundMotion}
       onChange={(e) => handleInputChange(e, 'backgroundMotion')}
       className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-black"
     >
       {motionOptions.map((option, index) => (
         <option key={index} value={option}>{option}</option>
       ))}
     </select>
     <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
       <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
         <path d="M5.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.576 0 0.436 0.445 0.408 1.197 0 1.615l-4.695 4.502c-0.533 0.481-1.408 0.481-1.942 0l-4.695-4.502c-0.408-0.418-0.436-1.17 0-1.615z"/>
       </svg>
     </div>
   </div>
   
      )}
</div>

      {/* Horizontal scrolling selectors */}
      
      <div className='px-6'>
      {selectedBackground?.backgroundForeground && (
      <div>
        Animated Image:
      <div className="flex overflow-x-auto">
   
<BackgroundForegroundSelector background={background} setBackground={setBackground} />

      </div>
      </div>
 )}


      {/* Horizontal scrolling selectors */}
      {selectedBackground?.backgroundBackground && (
          <div>
          Background Image:
      <div className="flex overflow-x-auto">
   
<BackgroundBackgroundSelector background={background} setBackground={setBackground} />

      </div>
      </div>
 )}
</div>
       {/* Horizontal scrolling selectors */}
       {selectedBackground?.backgroundColorFuser && (
            <div>
            Design:
      <div className="flex overflow-x-auto">
   
      <BackgroundColorFuserSelector
  background={background}
  setBackground={setBackground}
  updateFusorColors={updateFusorColors}
/>

      </div>
      </div>
 )}
    </div>
  );
}

export default BackgroundSelector;
