import React, { useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { getUser } from '../service/AuthService';
import Cropper from 'react-easy-crop';

function ImageUpload({ setImageFile, enableCropping, enableSquareCrop }) {
  const [uploading, setUploading] = useState(false);
  const user = getUser();
  const [isCroppingCompleted, setIsCroppingCompleted] = useState(false);
  const [currentImg, setCurrentImg] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const imageUploadAPIURL = process.env.REACT_APP_IMAGE_UPLOAD;
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const cropShape = enableSquareCrop ? "rect" : "round"; // Use "rect" for square cropping
  const aspectRatio = 1;
  const handleCropComplete = useCallback(async () => {
    try {
      // Generate a filename for the cropped image
      const now = new Date();
      const dateWithMillis = now.toISOString();
      const filename = `${user.username}.croppedImage.${dateWithMillis}.webp`;
  
      const croppedImageBlob = await getCroppedImg(currentImg, croppedAreaPixels, 'image/webp', 0.9);
      const croppedFile = new File([croppedImageBlob], filename, { type: 'image/webp' });

      // Proceed to get a presigned URL and upload the cropped image
      uploadCroppedImage(croppedFile, filename, 'image/webp');
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, currentImg, user.username]);
  
  const uploadCroppedImage = useCallback((croppedFile, filename, filetype) => {
    // First, request a presigned URL for the cropped image
    const requestBody1 = {
      filename: filename,
      filetype: filetype
    };
  
    const requestConfig1 = {
      headers: { 'x-api-key': process.env.REACT_APP_KEY }
    };
  
    axios.post(imageUploadAPIURL, requestBody1, requestConfig1)
      .then(response => {
        const presignedUrl = response.data.uploadurl;
        const requestConfig2 = { headers: { 'Content-Type': filetype } };
  
        // Then, upload the cropped file to the presigned URL
        axios.put(presignedUrl, croppedFile, requestConfig2)
          .then(() => {
            // Assuming the response doesn't directly contain the image URL,
            // Use the base part of the presigned URL as the image URL.
            const imageUrl = presignedUrl.split("?")[0];
            setImageFile(imageUrl);
            setIsCroppingCompleted(true); 
            setCurrentImg(imageUrl);
          })
          .catch(error => {
            console.error(`Cropped file upload failed: ${error}`);
          });
      })
      .catch(error => {
        console.error(`Failed to get presigned URL: ${error}`);
      });
    
  }, [imageUploadAPIURL, setImageFile]);
  

  console.log(enableCropping);
  const imageUpload = (files) => {
    const fileInput = files[0];

    if (fileInput && fileInput.type.startsWith("image/")){
      const now = new Date();
      const dateWithMillis = now.toISOString();
      const type = fileInput.type.split("/");
      const extension = type[type.length - 1];
      const filename = `${user.username}.image.${dateWithMillis}.${extension}`;

      const requestConfig1 = {
        headers: { 'x-api-key' : process.env.REACT_APP_KEY }
      };
      const requestBody1 = {
        filename: filename,
        filetype: fileInput.type
      };

      axios.post(imageUploadAPIURL, requestBody1, requestConfig1)
        .then(response => {
          const presignedUrl = response.data.uploadurl;
          const requestConfig = { headers: { 'Content-Type': fileInput.type } };

          axios.put(presignedUrl, fileInput, requestConfig)
            .then(response => {
              const imageUrl = response.config.url.split("?")[0];
              setImageFile(imageUrl);
            
              setCurrentImg(imageUrl);
            })
            .catch(error => {
              console.error(`File upload failed: ${error}`);
            });
        })
        .catch(error => {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            console.log(error.response.data.message);
          } else {
            console.log('Sorry, the server is down right now, please try again later.');
          }
        });
    } else {
      alert("This wasn't an image file. Please try again.");
    }
  };



  // Function to handle image upload for cropping
  const onDropForCropping = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Assuming single file upload
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setCurrentImg(reader.result));
      reader.readAsDataURL(file);
      setIsCroppingCompleted(false); // Reset cropping completion state
    }
  }, []);

  return (
    <div className="p-4 bg-white rounded shadow-md">
    {enableCropping ? (
      // Dropzone for cropping
      <Dropzone onDrop={onDropForCropping} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="p-4 border-dashed border-2 border-gray-300 rounded cursor-pointer hover:border-gray-400">
            <input {...getInputProps()} />
            <p className="text-center text-gray-600 mt-2">Click or drop image here to crop</p>
          </div>
        )}
      </Dropzone>
    ) : (
      // Default Dropzone without cropping
      <Dropzone onDrop={imageUpload} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="p-4 border-dashed border-2  border-gray-300 rounded cursor-pointer hover:border-gray-400">
            <input {...getInputProps()} />
            <p className="text-center text-gray-600 mt-2">Click or drop image here</p>
          </div>
        )}
      </Dropzone>
    )}

    {uploading && <div className="loading-bar">Uploading...</div>}

    {enableCropping && currentImg && !isCroppingCompleted && (
      <>
     <section className="cropper-container flex flex-col items-center justify-center mt-4" style={{ height: '400px'  }}>
          <div className="cropper z-20" style={{ height: '400px'  }} >
        <Cropper
          image={currentImg}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          cropShape={cropShape}
          showGrid={false}
        />
        </div>
        <button onClick={handleCropComplete} className="mt-4 z-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Crop & Upload
        </button>
        </section>
      </>
    )}

{isCroppingCompleted && (
  <div className="mt-4 flex items-center justify-center">
    <img src={currentImg} alt="Cropped and Uploaded" className={`max-w-full h-auto ${enableSquareCrop ? '' : 'rounded-full'}`} />
  </div>
)}


      {!enableCropping && currentImg && (
        <div className="mt-4 flex items-center justify-center">
          <img src={currentImg} alt="Uploaded" className="max-w-full h-auto" />
        </div>
      )}
    </div>
  );
}

export default ImageUpload;


async function getCroppedImg(imageSrc, pixelCrop) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous'; // Ensure CORS compliance
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');

      // Correctly calculate the scaling
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/webp', 0.9); // Convert to webp with quality 0.9
    };

    image.onerror = reject;
  });
}
