import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import yourBaseImage from '../assets/headerdashboardbase.png'; 
import yourTagImage1 from '../assets/headerdashboardsingletag.png';
import yourTagImage2 from '../assets/headerdashboardsingletag2.png';
import headerBackgroundGlow from '../assets/headerbackgroundglow.png'; // Import the glowing background image

function HeaderDashboard() {
    const [tagsType1, setTagsType1] = useState([]);
    const [tagsType2, setTagsType2] = useState([]);

    const updateTags = (tags, setTags, imageType) => {
        const shouldAddTag = Math.random() < 0.5; // 50% chance to add or remove a tag

        if (shouldAddTag && tags.length < 5) {
            // Add a new tag
            const newTag = { id: tags.length, x: 56 * tags.length, imageType: imageType };
            setTags([...tags, newTag]);
        } else if (!shouldAddTag && tags.length > 0) {
            // Remove the last tag
            setTags(tags.slice(0, -1));
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const imageType = Math.random() < 0.5 ? 'tag1' : 'tag2'; // Choose image type randomly
            if (imageType === 'tag1') {
                updateTags(tagsType1, setTagsType1, imageType);
            } else {
                updateTags(tagsType2, setTagsType2, imageType);
            }
        }, 3000); // Perform action every 3 seconds

        return () => clearInterval(interval);
    }, [tagsType1, tagsType2]);

    const renderTags = (tags, imageSrc) => {
        return tags.map(tag => (
            <motion.img
                key={tag.id}
                src={imageSrc}
                alt='Dashboard Tag'
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                style={{ position: 'absolute', left: `${tag.x}px`, top: '0px' }}
                className='z-20'
            />
        ));
    };

    return (
        <div className='relative w-full h-full'>
        <img 
            src={headerBackgroundGlow} 
            alt='Glow Background' 
            className='absolute w-full h-full top-0 left-0 z-0' // Positioned absolutely, behind other elements
        />
        <img 
            src={yourBaseImage} 
            alt='Header Dashboard' 
            className='relative w-full h-auto z-10' // Positioned relatively, above the glow
        />
        {renderTags(tagsType1, yourTagImage1)}
        {renderTags(tagsType2, yourTagImage2)}
    </div>
    );
}

export default HeaderDashboard;
