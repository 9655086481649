import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import band from '../assets/TagitSportsBand3.png';
import castle from '../assets/castle.png';


const images = [band, castle];

function TractorBeam() {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000); // Change image every 3 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative h-56" >
            <AnimatePresence mode="wait">
                <motion.img
                    key={currentImage}
                    src={images[currentImage]}
                    className="  md:h-24 1k:h-44 h-32 my-15 object-contain "
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: .3 }}
                />
            </AnimatePresence>
        </div>
    );
}

export default TractorBeam;
