import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from './Header/Header';
import { setUserSession, getUser, getToken } from "./service/AuthService";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const loginAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/login';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); 
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();
    if (username.trim() === '' || password.trim() === '') {
      setErrorMessage("Both username and password are required");
      return;
    }
    setErrorMessage(null);
    document.getElementById("loading").hidden = false;
    document.getElementById("loginPage").hidden = true;

    const requestConfig = {
      headers: {
        'x-api-key': process.env.REACT_APP_KEY
      }
    }
    const requestBody = {
      username: username,
      password: password
    }

    axios.post(loginAPIUrl, requestBody, requestConfig).then((response) => {
      setUserSession(response.data.user, response.data.token, rememberMe);
      navigate('/dashboard');
    }).catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Sorry, the server is down right now, please try again later.');
      }
      document.getElementById("loginPage").hidden = false;
      document.getElementById("loading").hidden = true;
    });
  }

  useEffect(() => {
    const user = getUser();
    const token = getToken();
    if (user && token) {
      setUserSession(user, token);
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <div className="fixed inset-0 bg-zinc-900 overflow-y-auto">
      <Header />
      <div className="flex-grow h-screen w-full">
        <div id="Setup1" className="bg-transparent rounded-xl min-h-screen flex flex-col -my-10 items-center justify-center">
          <form id="loginPage" className="text-white p-12 shadow-lg bg-zinc-700 outline outline-1 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }} onSubmit={submitHandler}>
            <h5 className="text-2xl font-bold mb-4">Login</h5>
            <div className="mb-4">
              <label className="block text-black-700 mb-2" htmlFor="username">
                Username
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" value={username} onChange={event => setUsername(event.target.value)} />
            </div>
            <div className="mb-6">
              <label className="block text-black-700 mb-2" htmlFor="password">
                Password
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" value={password} onChange={event => setPassword(event.target.value)} />
            </div>
            <div className="mb-6">
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox" checked={rememberMe} onChange={event => setRememberMe(event.target.checked)} />
                <span className="ml-2">Remember Me</span>
              </label>
            </div>
            <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-20 mx-3 rounded focus:outline-none focus:shadow-outline my-6" type="submit" value="Login" />
            <div> New to tagit? <a className="hover:text-blue-700 py-3 px-2 rounded text-blue-500 font-semibold" href="/newaccount">Create account</a> </div>
          </form>

          {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
          <div id="loading" hidden className="mt-8">
            <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
