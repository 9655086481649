import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { getUser, resetUserSession }  from '../service/AuthService';
import logoUrl from '../assets/tagitlogo2.png';
import { HiMenu } from "react-icons/hi";
import leaf from '../assets/tagittagleaf3.png';

import HeaderDashboard from './HeaderDashboard';
import { HiUser } from "react-icons/hi2";
import { BsDoorOpen } from "react-icons/bs";
import headerBackgroundGlow2 from '../assets/headerbackgroundglow2.png'; 
import HeaderShop from './HeaderShop';
import HeaderCreate from './HeaderCreate';
function Header() {
  const [nav, setNav] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

const createImageGrid = () => {
  const grid = [];
  const gridSize = 5; // For a 5x5 grid
  const movementDuration = 20; // Duration for each image to move across the screen

  // You can adjust these initial offsets and delays as needed
  const initialOffsets = ['-90%', '90%', '-100%', '-90%', '-80%', '100%', '300%', '-300%', '300%', '-300%', '300%', '-300%'];
  const delays = [0, 6, 15, 17, 25, 35, 3, 9, 12, 23, 29, 39];

  for (let row = 0; row < gridSize; row++) {
    for (let col = 0; col < gridSize; col++) {
      const index = row * gridSize + col;
      grid.push(
        <motion.img
          key={index}
          src={leaf}
          alt="Leaf"
          initial={{ y: '-700%', x: initialOffsets[index % initialOffsets.length] }}
          animate={{ y: '3000%', x: initialOffsets[index % initialOffsets.length] }}
          transition={{
            delay: delays[index % delays.length],
            duration: movementDuration,
            ease: 'linear',
            repeat: Infinity
          }}
          style={{
            width: '20%',
            height: '20%',
            position: 'absolute',
            left: `${col * 20}%`,
            top: `${row * 20}%`,
            zIndex: 0,
            opacity: 0.5
          }}
        />
      );
    }
  }

  return grid;
};
  
  
  
  

  const navigate = useNavigate(); 
  const logoutHandler = () => {
    resetUserSession();
    navigate('/login');
  }

  const user = getUser();
  const isLoggedIn = !!user; // Check if user is logged in

  // Animation variants for the hidden view
  const variants = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0 }
  };

  // Event Handlers
  const handleMouseEnter = () => {
    setNav(true);
  };

  const handleMouseLeave = () => {
    setNav(false);
  };
  const handleNav = () => {
    setNav(!nav);
    setMenuOpen(!menuOpen);
    document.body.classList.toggle('nav-open');
  };
  const imageGrid = createImageGrid();

  return (
    <div className='flex py-6'>
      <div 
        className="fixed top-0 left-0 right-0 bg-zinc-950 outline outline-1 outline-cyan-400 z-50"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="mx-auto px-4 py-2 sm:py-2 flex justify-between items-center flex-shrink-0">
         
            <div className="flex items-center flex-shrink-0">
              <a href="/" className="flex items-center">
                <img className="h-6 sm:h-5 px-0" src={logoUrl} alt="Logo"  />
              </a>
              <ul className="hidden sm:flex ml-4 space-x-4 font-ariel font-semibold text-gray-600">
              <li>
                <a className="hover:bg-cyan-900 rounded py-3 px-3 text-blue-300 " href="/tagcreator">Create</a>
              </li>
              <li>
                <a className="hover:bg-cyan-900 rounded py-3 px-3 text-blue-300 " href="/dashboard">Dashboard</a>
              </li>
              <li>
                <a className="hover:bg-cyan-900 rounded py-3 px-3 text-blue-300 " href="/store">Shop</a>
              </li>
              </ul>
            </div>
            <div className="flex items-center">
           
            <HiMenu   
              onClick={handleNav}
              className={`text-blue-400 hover:bg-cyan-900 rounded cursor-pointer transition duration-800 ease-in-out transform ${menuOpen ? 'relative' : ''}`}
              size={32}
            />
          </div>
        </div>
        </div>

        <motion.div
      initial="hidden"
      animate={nav ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.8 }}
      className={`${nav ? 'block' : 'hidden'} fixed top-0 left-0 right-0 z-40`}
      style={{ background: 'linear-gradient(to bottom, rgba(103, 232, 249, 0.9) 0%, rgba(59, 130, 246, 0.9) 70%, transparent 100%)' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
 <div className="max-w-6xl mx-auto py-4 my-11 flex justify-center scale-85 items-center z-10">
 <ul className="flex flex-wrap justify-center items-center gap-4  sm:flex-row ">
      <li>



      <motion.button
        whileHover={{ scale: 1.1 }} // Adjust scale value as needed
        transition={{ type: "spring", stiffness: 300 }}
        className="relative sm:w-48 sm:h-52 w-36 h-36 bg-blue-400 hover:bg-blue-600 text-black font-semibold rounded-md flex flex-col items-center justify-center overflow-hidden outline outline-1 outline-black"
  onClick={() => navigate('/dashboard')}
>
  <div className="absolute top-0 left-0 w-full h-full ">
    <div>
      
    </div>
    <div className=' '>
      <div className=" scale-15 flex items-center justify-center z-20 -m-59 sm:-m-52" style={{ pointerEvents: 'none' }}  >
      <HeaderDashboard/>
      </div>
    </div>
  </div>

  <div 
    className="text-lg md:text-2xl  -mb-30 sm:-mb-32 z-30 text-white"
    style={{ textShadow: '0 0 50px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 60px #fff' }}
  >
    Dashboard
  </div>
</motion.button>


      </li>
      <li>


      <motion.button
        whileHover={{ scale: 1.1 }} // Adjust scale value as needed
        transition={{ type: "spring", stiffness: 300 }}
        className="relative sm:w-48 sm:h-52 w-36 h-36 bg-blue-400 hover:bg-blue-600 text-black font-semibold rounded-md flex flex-col items-center justify-center overflow-hidden outline outline-1 outline-black"
  onClick={() => navigate('/tagcreator')}
>
  <div className="absolute top-0 left-0 w-full h-full ">
    <div>
      {imageGrid}
    </div>
    <img 
            src={headerBackgroundGlow2} 
            alt='Glow Background' 
            className='absolute  w-full h-full top-0 left-0 z-0' // Positioned absolutely, behind other elements
        />
    <div className=' '>
      <div className=" scale-80  rounded-md bg-transparent  flex items-center justify-center  " style={{ pointerEvents: 'none' }} >
       <HeaderCreate/>
      </div>
    </div>
  </div>

  <div 
    className="text-lg md:text-2xl  -mb-30 sm:-mb-32 z-30 text-white"
    style={{ textShadow: '0 0 50px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 60px #fff' }}
  >
    Create
  </div>
</motion.button>








      </li>


      <li>



      <motion.button
        whileHover={{ scale: 1.1 }} // Adjust scale value as needed
        transition={{ type: "spring", stiffness: 300 }}
        className="relative sm:w-48 sm:h-52 w-36 h-36 bg-blue-400 hover:bg-blue-600 text-black font-semibold rounded-md flex flex-col items-center justify-center overflow-hidden outline outline-1 outline-black"
  onClick={() => navigate('/store')}
>
  <div className="absolute top-0 left-0 w-full h-full ">
    <div>
     
    </div>
    <div className=' '>
      <div className="  flex items-center justify-center z-20 " style={{ pointerEvents: 'none' }} >
      
       <HeaderShop/>
      </div>
    </div>
  </div>

  <div 
    className="text-lg md:text-2xl  -mb-30 sm:-mb-32 z-30 text-white"
    style={{ textShadow: '0 0 50px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 60px #fff' }}
  >
    Shop
  </div>
</motion.button>




      </li>



    


      {/* Other buttons for different sections */}
      {user ? (



        <li>
       <motion.button
  whileHover={{ scale: 1.1 }} // Adjust scale value as needed
  transition={{ type: "spring", stiffness: 300 }}
  className="relative sm:w-48 sm:h-52 w-36 h-36 bg-blue-400 hover:bg-blue-600 text-black font-semibold rounded-md flex flex-col items-center justify-center overflow-hidden outline outline-1 outline-black"   onClick={logoutHandler}>
    
    <div className="absolute top-0 left-0 w-full h-full ">
    <div>
      
    </div>
    <div className=' '>
      <div className="  flex items-center justify-center "  >
      <BsDoorOpen size={80} className=' my-5 sm:my-12 text-blue-100' />
  
      </div>
    </div>
  </div>

  <div 
    className="text-lg md:text-2xl -mb-30 sm:-mb-32 z-30 text-white"
    style={{ textShadow: '0 0 50px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 60px #fff' }}
  >
    Log Out
  </div>
  
  </motion.button>
        </li>



      ) : (


        <li>
         <motion.button
  whileHover={{ scale: 1.1 }} // Adjust scale value as needed
  transition={{ type: "spring", stiffness: 300 }}
  className="relative sm:w-48 sm:h-52 w-36 h-36 bg-blue-400 hover:bg-blue-600 text-black font-semibold rounded-md flex flex-col items-center justify-center overflow-hidden outline outline-1 outline-black"  onClick={() => navigate('/login')}>
    
    <div className="absolute top-0 left-0 w-full h-full ">
    <div>
     
    </div>
    <div className=' '>
      <div className="flex items-center justify-center " style={{ pointerEvents: 'none' }} >
      <HiUser size={80} className=' my-5 sm:my-12  text-blue-100' />
      </div>
    </div>
  </div>

  <div 
    className="text-lg md:text-2xl  -mb-30 sm:-mb-32 z-30 text-white"
    style={{ textShadow: '0 0 50px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 60px #fff' }}
  >
    Log In
  </div>
    
    </motion.button>
        </li>


      )}
  
    </ul>
  </div>
</motion.div>

      
    </div>
  );
}

export default Header;
