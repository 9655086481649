import React from 'react';
import CustomColorPicker from '../../TagCreator/CustomColorPicker';
import ImageUpload from '../../TagCreator/ImageUpload';

function QRCodeCustomizer({ setQRBackground, setQRForeground, qrForeground, qrBackground, setCenterImage, centerImage, handleDownload, setLogoHeight, setLogoWidth }) {
  return (
    <div className="w-full h-full flex flex-col items-center">
      <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4 sm:space-y-0 space-y-4 mb-8 my-8">
        <CustomColorPicker label={'Background'} value={qrBackground} onChange={setQRBackground} />
        <CustomColorPicker label={'Foreground'} value={qrForeground} onChange={setQRForeground} />
      </div>
    
      <button
        onClick={handleDownload}
        className="bg-green-500 text-white px-4 py-2 rounded"
      >
        Download
      </button>
    </div>
  );
}

export default QRCodeCustomizer;
