import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getUser, getToken, setUserSession, removeUserSession } from '../service/AuthService';
import Loading from '../Loading';
import Header from '../Header/Header';
const deviceReadUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/deviceread';
const devicePairUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/devicepair';
const loginAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/login';
var urldata = window.location.href.split("/");
var deviceidglobal = urldata[4];
console.log("data 4 " + urldata[4]);
var deviceDataGlobal;
var userglobal = "";

async function datahandling() {
    function getData() {
        console.log("deviceidglobal", deviceidglobal);
        return new Promise((resolve) => {
            const requestConfig = {
                headers: {
                    'x-api-key': process.env.REACT_APP_KEY
                }
            }
            const requestBody = {
                deviceid: deviceidglobal
            }

            axios.post(deviceReadUrl, requestBody, requestConfig).then((response) => {
                const TagData = response.data.device;
                deviceDataGlobal = TagData;
                resolve(TagData);
            }).catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    console.log(error.response.data.message);
                } else {
                    console.log('Sorry, the server is down right now, please try again later.');
                    console.log(error.response.data.message);
                }
            })
        });
    }

    let tagData = await (getData());
    console.log("tagdata:", tagData);
    if (tagData.deviceowner === 'null' || tagData.deviceowner === '') {
        return (false);
    } else {
        return (true);
    }
}

function pairDevice(activationCode) {
  console.log("deviceidglobal", deviceidglobal);
  return new Promise((resolve) => {
      const requestConfig = {
          headers: {
              'x-api-key': process.env.REACT_APP_KEY
          }
      }
      const requestBody = {
          deviceid: deviceidglobal,
          deviceowner: userglobal,
          activationCode: activationCode // Include the activation code in the request body
      }

      axios.post(devicePairUrl, requestBody, requestConfig).then((response) => {
          const TagData = response.data.device;
          deviceDataGlobal = TagData;
          window.location.href = '/dashboard';
          resolve(TagData);
      }).catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
              console.log(error.response.data.message);
          } else {
              console.log('Sorry, the server is down right now, please try again later.');
              console.log(error.response.data.message);
          }
      })
  });
}


function DeviceFunctions() {
    const navigate = useNavigate();
    const user = getUser();
    const name = user !== 'undefined' && user ? user.username : '';
    userglobal = name;

    var [deviceowned, setDeviceOwned] = useState();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activationCode, setActivationCode] = useState('');

    const submitHandler = (event) => {
        event.preventDefault();
        if (username.trim() === '' || password.trim() === '') {
            setErrorMessage("Both username and password are required");
            return;
        }
        setErrorMessage(null);
        setIsLoading(true);

        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_KEY
            }
        }
        const requestBody = {
            username: username,
            password: password
        }

        axios.post(loginAPIUrl, requestBody, requestConfig).then((response) => {
            setUserSession(response.data.user, response.data.token, rememberMe);
            window.location.reload();
        }).catch((error) => {
            setIsLoading(false);
            if (error.response.status === 401 || error.response.status === 403) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Sorry, the server is down right now, please try again later.');
            }
        })
    }

    useEffect(() => {
        const checkDeviceOwnership = async () => {
            var isowned = await (datahandling());
            console.log(isowned);
            setDeviceOwned(isowned);
        }

        checkDeviceOwnership();

        const user = getUser();
        const token = getToken();
        if (user && token) {
            setUserSession(user, token);
           
        }
    }, [navigate]);

    if (isLoading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
                <Loading />
            </div>
        );
    }

    if (deviceowned === true) {
        console.log("global device data", deviceDataGlobal);
        var sharedtagcreator = deviceDataGlobal.sharedtagcreator;
        var sharedtagname = deviceDataGlobal.sharedtagname;
        var url = 'http://tagit.link/' + sharedtagcreator + '/' + sharedtagname;
        window.location.href = url;
        return (<div></div>);
    }

    if (deviceowned === false) {
        console.log("global device data : ", deviceDataGlobal);
        if (name === '') {
            return (
              <div className="fixed inset-0 bg-zinc-900 overflow-y-auto">
      <Header />
      <div className="flex-grow h-5/6  w-full">
                <div id="" className="bg-transparent rounded-xl min-h-full flex flex-col items-center justify-center">
                    <form id="loginPopover" className="text-white p-12 shadow-lg bg-zinc-700 outline outline-1 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}  onSubmit={submitHandler}>
                        <h5 className="text-2xl font-bold mb-4">Login</h5>
                        <div className="mb-4">
                            <label className="block text-black-700 mb-2" htmlFor="username">
                                Username
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" value={username} onChange={event => setUsername(event.target.value)} />
                        </div>
                        <div className="mb-6">
                            <label className="block text-black-700 mb-2" htmlFor="password">
                                Password
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" value={password} onChange={event => setPassword(event.target.value)} />
                        </div>
                        <div className="mb-6">
                            <label className="inline-flex items-center">
                                <input type="checkbox" className="form-checkbox" checked={rememberMe} onChange={event => setRememberMe(event.target.checked)} />
                                <span className="ml-2">Remember Me</span>
                            </label>
                        </div>
                        <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-20 mx-3 rounded focus:outline-none focus:shadow-outline my-6" type="submit" value="Login" />
                        <div> New to tagit? <a className="hover:text-blue-700 py-2 px-2 rounded text-blue-500 font-semibold" href="/newaccount">Create account</a> </div>
                    </form>
                    {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
                </div>
                </div></div>
            )
          } else {
            return (
                <div className="fixed inset-0 bg-zinc-900 overflow-y-auto">
                    <Header />
                    <div className="flex-grow h-5/6 w-full">
                        <div id="" className="bg-transparent rounded-xl min-h-full flex flex-col items-center justify-center">
                            <div className="text-white p-12 shadow-lg bg-zinc-700 outline outline-1 outline-blue-300 rounded-lg" style={{ boxShadow: '0 0 6px #67E8F9' }}>
                                <h1>Hello {name}</h1>
                                <h1>Enter the activation code to pair your {deviceDataGlobal.devicetype}</h1>
                                <input
                                    type="text"
                                    placeholder="Enter Activation Code"
                                    value={activationCode}
                                    onChange={e => setActivationCode(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                                />
                                <button
                                    onClick={() => {
                                        if (activationCode.trim() === '') {
                                            alert("Please enter the activation code.");
                                            return;
                                        }
                                        pairDevice(activationCode);
                                    }}
                                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                >
                                    Pair
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
                <Loading />
            </div>
        );
    };
}

export default DeviceFunctions;
