import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header/Header';
import { useNavigate } from "react-router-dom";
import loading from './assets/loading.gif';

const registerUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/register';

function PageSetup1(){
    const [name, setName] = useState('');
    const [email, setEmail]= useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    const submitHandler = (event) =>{
        event.preventDefault();
        if(username.trim()==='' || email.trim()==='' || name.trim==='' || password.trim()===''){
            setMessage('All fields are required');
            return;
        }
        setMessage(null);
        document.getElementById("loading").hidden = false;
        document.getElementById("register").hidden = true;
        console.log('submitButton is pressed! ');
        const requestConfig = {
            headers: {
                'x-api-key' : process.env.REACT_APP_KEY
            }
        }
    const requestBody = {
        username: username,
        email: email,
        name: name,
        password: password
    }
    axios.post(registerUrl,requestBody,requestConfig).then(response =>{
        setMessage('Registeration Successful');
        navigate('/login');
    }).catch(error =>{
        if (error.response.status === 401 || error.response.status === 403){
            setMessage(error.response.data.message);
            document.getElementById("register").hidden = false;
            document.getElementById("loading").hidden = true;
        }else {
            setMessage('sorry... the server is down, please try again later.');
            document.getElementById("register").hidden = false;
            document.getElementById("loading").hidden = true;
        }
    })
    }

    
    return(

<div id="Setup1" className="flex flex-col items-center justify-center  py-5 min-h-screen">
    <form id='register' className="w-80 bg-white p-8 rounded-lg shadow-lg" onSubmit={submitHandler}>
        <h5 className="text-3xl text-center font-bold text-black mb-5">Create your account</h5>
        <div className="my-2">
            <label htmlFor="name" className="text-gray-700 mb-2 block">Enter your name:</label>
            <input 
                type="text" 
                id="name"
                placeholder="Name" 
                className="appearance-none border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                value={name} 
                onChange={event => setName(event.target.value)}/>
        </div>
        <div className="my-2">
            <label htmlFor="email" className="text-gray-700 mb-2 block">Enter your email:</label>
            <input 
                type="text" 
                id="email"
                placeholder="Email" 
                className="appearance-none border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                value={email} 
                onChange={event => setEmail(event.target.value)}/>
        </div>
        <div className="my-2">
            <label htmlFor="username" className="text-gray-700 mb-2 block">Enter your username:</label>
            <input 
                type="text" 
                id="username"
                placeholder="Username" 
                className="appearance-none border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                value={username} 
                onChange={event => setUsername(event.target.value)}/>
        </div>
        <div className="my-2">
            <label htmlFor="password" className="text-gray-700 mb-2 block">Enter your password:</label>
            <input 
                type="password" 
                id="password"
                placeholder="Password" 
                className="appearance-none border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                value={password} 
                onChange={event => setPassword(event.target.value)}/>
        </div>
        <input 
            type="submit" 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full my-3"
            value="Create account" 
        />
        <div className="text-center mt-3 text-sm">
            Already have an account? 
            <a 
                className="hover:text-blue-700 text-blue-500  font-semibold" 
                href="/login">
                Log in
            </a> 
        </div>
        {message && <p className='text-red-500 font-medium mt-3'>{message}</p>}
    </form>
    <div className="flex items-center justify-center w-full mt-5">
        <div id="loading" className="p-8 hidden">
            <img className="max-w-full max-h-96" src={loading} alt="Loading" />
        </div>
    </div>
</div>



    )
}

function CreateAccount(){

}



function NewAccount() {

    
    return (
    <div>
    <Header></Header>
    {PageSetup1()}
    </div>

    )
      
    
  }
  
  export default NewAccount;
  