import React, { useState } from 'react';
import Header from "../../Header/Header";
import { getUser } from '../../service/AuthService';
import OrderList from './OrderList';

function AccountSettings() {
    const user = getUser();
    const [activeTab, setActiveTab] = useState('account');

    return (
        <div className='relative w-full h-full bg-zinc-700 overflow-hidden flex items-center justify-center'>
            <div className="flex flex-col rounded-lg h-full w-full">
                <div className="p-4 sm:p-8 rounded w-full h-full flex flex-col sm:flex-row">
                    <div className="flex sm:flex-col w-full sm:w-1/3  text-white my-4">
                        <button
                            className={`w-1/3 sm:w-full py-2 sm:py-4 border-b-2 text-xs sm:text-lg font-semibold ${activeTab === 'account' ? 'bg-blue-300' : ''}`}
                            onClick={() => setActiveTab('account')}
                        >
                            Account
                        </button>
                        <button
                            className={`w-1/3 sm:w-full py-2 sm:py-4 text-xs border-b-2 sm:text-lg font-semibold ${activeTab === 'subscriptions' ? 'bg-blue-300' : ''}`}
                            onClick={() => setActiveTab('subscriptions')}
                        >
                            Subscriptions
                        </button>
                        <button
                            className={`w-1/3 sm:w-full py-2 sm:py-4 text-xs border-b-2 sm:text-lg font-semibold ${activeTab === 'orders' ? 'bg-blue-300' : ''}`}
                            onClick={() => setActiveTab('orders')}
                        >
                            Orders
                        </button>
                    </div>
                    <div className="w-full p-2 flex flex-col items-center justify-center sm:relative">
                        {activeTab === 'account' && (
                            <div className="flex flex-col bg-zinc-700 p-6 w-full h-full rounded-lg">
                                <div className="flex flex-col sm:flex-row flex-wrap justify-start items-center border-b-2 py-4 border-blue-300">
                                    <h2 className="text-xl sm:text-sm md:text-xl font-medium w-full sm:w-1/4 md:w-1/4 mb-2 sm:mb-0 text-white">Name:</h2>
                                    <p className="text-xl sm:text-sm md:text-xl w-full sm:w-3/4 md:w-3/4 text-white">{user.name}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row flex-wrap justify-start items-center border-b-2 py-4 border-blue-300">
                                    <h2 className="text-xl sm:text-sm md:text-xl font-medium w-full sm:w-1/4 md:w-1/4 mb-2 sm:mb-0 text-white">Username:</h2>
                                    <p className="text-xl sm:text-sm md:text-xl w-full sm:w-3/4 md:w-3/4 text-white">{user.username}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row flex-wrap justify-start items-center py-4">
                                    <h2 className="text-xl sm:text-sm md:text-xl font-medium w-full sm:w-1/4 md:w-1/4 mb-2 sm:mb-0 text-white">Email:</h2>
                                    <p className="text-xl sm:text-sm md:text-xl w-full sm:w-3/4 md:w-3/4 text-white overflow-auto">{user.email}</p>
                                </div>
                            </div>
                        )}
                        {activeTab === 'subscriptions' && (
                            <div className="flex h-full w-full flex-col">
                                <div className='flex items-center justify-center'>
                                    <button className="px-4 py-2 bg-green-500 w-3/4 hover:bg-green-700 text-white rounded" onClick={() => window.location.href='/subscriptions'}>Manage subscriptions</button>
                                </div>
                            </div>
                        )}
                        {activeTab === 'orders' && (
                            <div className="flex w-full h-full flex-col overflow-y-auto">
                                 <h1 className="text-xl my-2 font-bold text-white">Order ID:</h1>
                                <OrderList />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountSettings;
