import React from 'react';

const TextReviewList = ({ texts }) => {
  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-xl font-semibold mb-4">Texts for Review</h2>
      <ul>
        {texts.map((text, index) => (
          <li key={index} className="mb-4 p-4 border rounded shadow">
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TextReviewList;