import axios from "axios";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import TagReader from "./TagReader";
import loading from '../assets/loading.gif'; // Import the loading GIF
import { getUser } from "../service/AuthService";

function TagScanner() {
    const [tagData, setTagData] = useState(null);
    const [loadingData, setLoadingData] = useState(true); // Loading state
    const [tagNotFound, setTagNotFound] = useState(false); // State for tag not found
    const user = getUser();
    const navigate = useNavigate(); // Initialize the useNavigate hook
    var tagreader = '';

    if(user != null){
        tagreader = user.username;
        //console.log("user here: ", user);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urldata = window.location.href.split("/");
                const tagcreator = urldata[3];
                const tagname = urldata[4];
                
                // Check if tagcreator is 'account', and redirect if true
                if (tagcreator === 'account'){
                    navigate('/dashboard'); // Redirect to /dashboard
                }
                if (tagname === 'account'){
                    navigate('/dashboard'); // Redirect to /dashboard
                }
                //console.log(tagcreator);
                const data = await getData(tagcreator, tagname, tagreader);

                if (!data || !data.data || !data.data.Tag || !data.data.Tag.TagContents) {
                    setTagNotFound(true); // Set tag not found if data is not as expected
                } else {
                    setTagData(data);
                }
                setLoadingData(false); // Set loading to false when data is fetched
            } catch (error) {
                console.error('Error fetching data:', error);
                setTagNotFound(true); // Set tag not found in case of error
                setLoadingData(false); // Set loading to false in case of error
            }
        };

        fetchData();
    }, [navigate]); // Add navigate to the dependency array

    const tagReaderStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: '0',
        padding: '0',
    };

    return (
        // Check if data is still loading, if so, display loading page
        loadingData ? (
            <div className="w-full h-full flex justify-center items-center bg-white">
                <img src={loading} alt="Loading..." style={{ width: '100px', height: '100px' }} />
            </div>
        ) : tagNotFound ? (
            // If tag is not found, display message
            <div className="w-full h-full flex justify-center items-center bg-white">
                <h1>Tag not found</h1>
            </div>
        ) : (
            // Once data is loaded, display TagReader component
            <TagReader tagData={tagData?.data?.Tag?.TagContents} style={tagReaderStyle} />
        )
    );
}

export default TagScanner;

async function getData(tagcreator, tagname, tagreader) {
    const getTagAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/gettag';

    try {
        const response = await axios.post(getTagAPIURL, {
            TagCreator: tagcreator,
            TagName: tagname,
            TagCollector: tagreader
        }, {
            headers: {
                'x-api-key': process.env.REACT_APP_KEY
            }
        });

        return response;
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            console.log(error.response.data.message);
        } else {
            console.log('Sorry, the server is down right now, please try again later.');
            console.log(error.response ? error.response.data.message : error.message);
        }
        throw error; // Rethrow the error to be caught by the caller
    }
}
