import React from 'react';
import CustomColorPicker from './CustomColorPicker'; // Adjust the path as necessary
import ListOfFonts from './ListOfFonts'; // Ensure this is correctly imported
import ListOfButtons from './ListOfButtons'; // Ensure this is correctly imported

function ButtonStyle({ selectedButtonStyleId, buttonSelection, buttonColor, fontColor, handleFontColorChange, handleColorChange, selectedFontClassName, handleFontChange, recentButtonStyles }) {
  const fonts = ListOfFonts(); // Get the list of fonts
  const buttons = ListOfButtons(); // Get button styles
  
  // Create a unique identifier based on font, color, and style and filter duplicates
  const uniqueIdentifier = style => `${style.fontClassName}-${style.buttonColor}-${style.buttonStyleId}`;

  const uniqueAndFilteredRecentStyles = recentButtonStyles
    .filter(style => style.containerType !== "round") // Exclude 'round' containerType
    .reduce((acc, current) => {
      const identifier = uniqueIdentifier(current);
      if (!acc.find(item => uniqueIdentifier(item) === identifier)) {
        acc.push(current);
      }
      return acc;
    }, []);
    
  const selectRecentStyle = (style) => {
    buttonSelection({ target: { value: style.buttonStyleId } });
    handleColorChange(style.buttonColor);
    if (handleFontColorChange && fontColor !== undefined) handleFontColorChange(style.fontColor);
    if (handleFontChange && selectedFontClassName !== undefined) handleFontChange({ target: { value: style.fontClassName } });
  };

  return (
    <div>
      <h3 className="text-base font-semibold bg-transparent w-full mx-8 text-left">Select Tag Item Shape:</h3>
      <div className='flex items-center justify-center'>
        <div className='flex overflow-auto scrollbar-custom p-2 w-5/6'>
          {buttons.map((button) => (
            <label className="labelexpanded mx-2" key={button.id}>
              <input
                id={`buttonOptions-${button.id}`}
                className="buttonOptions"
                type="radio"
                defaultChecked={button.id === selectedButtonStyleId}
                checked={button.id === selectedButtonStyleId}
                name="buttonSelector"
                onClick={buttonSelection}
                value={button.id}
              />
              <div className="radio-btns-buttons mt-1">
                <div className="examplebutton w-32 h-10" style={{ backgroundColor: buttonColor, ...button.style }}>
                  {/* Button preview */}
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className="flex justify-center p-1 items-center mr-4">
          <CustomColorPicker
              label="Main Color"
              value={buttonColor}
              onChange={handleColorChange}
          />
        </div>
      <div className='flex justify-center items-center w-full'>

       
  
        {selectedFontClassName !== undefined && handleFontChange && (
          <div className="flex p-2 items-center">
            <span className="mr-2">Font:</span>
            <select
                value={selectedFontClassName}
                onChange={handleFontChange}
                className="border border-gray-300 rounded text-black p-2">
                {fonts.map((font) => (
                    <option key={font.id} value={font.className} className={font.className}>
                        {font.name}
                    </option>
                ))}
            </select>
          </div>
        )}
              {fontColor !== undefined && handleFontColorChange && (
          <div className="flex items-center mr-4">
            <CustomColorPicker
                label="Font Color"
                value={fontColor}
                onChange={handleFontColorChange}
            />
          </div>
        )}
      </div>
      {uniqueAndFilteredRecentStyles && uniqueAndFilteredRecentStyles.length > 0 && (
        <div className="w-full">
          <h3 className="text-base font-semibold bg-transparent mx-8 text-left">Recent Styles:</h3>
          <div className='flex overflow-auto p-2 w-full'>
            {uniqueAndFilteredRecentStyles.map((style, index) => {
              const buttonStyle = buttons.find(button => button.id === style.buttonStyleId)?.style;

              return (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => selectRecentStyle(style)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '8px',
                  }}
                >
                  <div
                    className={`examplebutton w-32 h-10 ${style.fontClassName}`}
                    style={{
                      backgroundColor: style.buttonColor,
                      ...buttonStyle,
                    }}
                  >
                    {/* Optional style name or ID display */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ButtonStyle;
