


function ListOfBackgrounds() {

    var ListOfBackgrounds = [
        {backgroundID:0, name: "Basic Color", backgroundType:"Basic Color", backgroundColor1:true,backgroundColor2:false, backgroundMotion: false,backgroundSpeed:false, backgroundForeground:false,backgroundBackground:false,backgroundColorFuser:false},
        {backgroundID:1, name: "MultiColor", backgroundType:"MultiColor",  backgroundColor1:false,backgroundColor2:false, backgroundMotion: false,backgroundSpeed:false, backgroundForeground:false,backgroundBackground:false,backgroundColorFuser:true},       
        {backgroundID:2, name: "Image", backgroundType:"image",  backgroundColor1:false,backgroundColor2:false, backgroundMotion: false,backgroundSpeed:false, backgroundForeground:false,backgroundBackground:true,backgroundColorFuser:false},
        {backgroundID:3, name:"Motion", backgroundType:"Motion", backgroundColor1:true,backgroundColor2:true, backgroundMotion: true,backgroundSpeed:true, backgroundForeground:true,backgroundBackground:true,backgroundColorFuser:false },
        
        
    ];
    
        return (ListOfBackgrounds)
          
        
      }
      
      export default ListOfBackgrounds;
      








