import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CartItem from './CartItem';
import FullScreenCheckout from './FullScreenCheckout'; // Import the full-screen checkout component
import { HiOutlineX } from 'react-icons/hi';

function Cart({ cartItems, visibility, toggleCart, removeItemFromCart, decreaseQuantity, increaseQuantity, setQuantity }) {
  const [showCheckout, setShowCheckout] = useState(false); // New state for controlling the visibility of the checkout component
  
  const variants = {
    hidden: { x: '100%' },
    visible: { x: 0 },
    fullScreen: { width: "100%", height: "100vh", top: 0, right: 0 }
  };

  const totalPrice = Object.values(cartItems).reduce((total, { item, quantity }) => total + item.price * quantity, 0);
  const roundedTotalPrice = parseFloat(totalPrice.toFixed(2));
  
  function Checkout() {
    setShowCheckout(true);
    console.log(cartItems);
  }

  useEffect(() => {
    // Re-render the component whenever cartItems changes
  }, [cartItems]);

  return (
    <AnimatePresence>
      {!showCheckout && visibility && (
        <motion.div
          className="fixed top-0 right-0 h-full w-96 bg-zinc-950 text-white p-8 overflow-y-auto z-50 outline outline-1 outline-cyan-300"
          initial="hidden"
          animate={visibility ? "visible" : "hidden"}
          variants={variants}
          transition={{ type: "spring", stiffness: 60, damping: 20 }}
          exit="hidden"
        >
          <div>
            <HiOutlineX size={30} className="absolute top-6 right-6 cursor-pointer z-50" onClick={toggleCart}/>
            <h2 className="text-2xl font-semibold mb-6">Shopping Cart</h2>
            {Object.values(cartItems).map(({ item, quantity }) => (
              <CartItem
                key={item.uniqueID}
                item={item}
                quantity={quantity}
                removeItem={removeItemFromCart}
                decreaseQuantity={decreaseQuantity}
                increaseQuantity={increaseQuantity}
                setQuantity={setQuantity}
              />
            ))}
            <h2 className="text-2xl font-semibold mb-6">Total: ${roundedTotalPrice}</h2>
            <button className="bg-green-500 hover:bg-green-600 text-white font-bold w-full py-2 px-4 rounded-lg shadow-lg transition-colors duration-200 ease-in outline outline-1 outline-white" onClick={Checkout}>Checkout</button>
            <p className='p-10'></p>
          </div>
        </motion.div>
      )}

      {showCheckout && (
        <FullScreenCheckout
          exit={() => setShowCheckout(false)}
          cartItems={cartItems} // Pass the cartItems to FullScreenCheckout
        />
      )}
    </AnimatePresence>
  );
}

export default Cart;
