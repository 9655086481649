import React from 'react';
import ListOfBackgroundImages from './ListOfBackgroundImages';
import ImageUpload from './ImageUpload';

function BackgroundBackgroundSelector({ background, setBackground }) {
  const backgroundImages = ListOfBackgroundImages();

  console.log("Background", background);

  const handleBackgroundSelect = (id, imageFile, isColor) => {
    setBackground(prevState => ({
      ...prevState,
      backgroundSelection: {
        ...prevState.backgroundSelection,
        backgroundBackground: isColor ? prevState.backgroundColor1 : imageFile,
        selectedBgId2: id,
      }
    }));
  };

  const handleImageFileUpdate = (imageUrl) => {
    setBackground(prevState => ({
      ...prevState,
      backgroundSelection: {
        ...prevState.backgroundSelection,
        backgroundBackground: imageUrl,
      }
    }));
  };

  // Depending on backgroundID, conditionally slice the first element
  const displayedImages = background.backgroundSelection.backgroundID === 2
    ? backgroundImages.slice(1)
    : backgroundImages;

  return (
    <div className='w-full'>
      <div className="flex overflow-x-auto p-2">
        {displayedImages.map(bg => (
          <div key={bg.id} className="flex-shrink-0 p-2">
            <button 
              onClick={() => handleBackgroundSelect(bg.id, bg.templateImage, bg.backgroundType === 'color')} 
              className={`border ${background.backgroundSelection.backgroundBackground === bg.templateImage ? 'border-blue-500' : 'border-transparent'} rounded-md`}
            >
              {bg.backgroundType === 'color' ? (
                <div 
                  style={{ 
                    backgroundColor: background.backgroundColor1, 
                    width: '80px', 
                    height: '160px', 
                    borderRadius: '0px'  // Adjust for slight rounding
                  }}
                ></div>
              ) : (
                <img 
                  src={require(`../assets/backgroundStyles/${bg.templateImage}`)} 
                  alt={bg.name} 
                  className="h-auto w-20 object-cover"
                  style={{ aspectRatio: '1 / 2' }}
                />
              )}
            </button>
          </div>
        ))}
      </div>

      {background.backgroundSelection.selectedBgId2 === 1 && (
        <div className="p-2 mt-4">
          <ImageUpload setImageFile={handleImageFileUpdate} enableCropping={false} />
        </div>
      )}
    </div>
  );
}

export default BackgroundBackgroundSelector;
