import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import leaf from '../assets/tagittagleaf2.png'; // Adjust the path accordingly
import productsData from './productsData';
import Header from '../Header/Header';
const loginAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/getorderstatus';

const delays = [
    0, 6, 15, 17, 25, 35, 3, 9, 12, 23,  39, 
    4, 7, 10, 18, 26, 31, 8, 5, 14, 24, 30, 33, 
    2, 11, 16, 19, 21, 36, 13, 27, 32, 20, 22, 28, 
    37, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90
    // You can continue adding delays here
  ];

function OrderTracker() {
    
    const [order, setOrder] = useState(null);
    const { orderID } = useParams();

    useEffect(() => {
        async function fetchOrder() {
            const requestConfig = {
                headers: {
                    'x-api-key' : process.env.REACT_APP_KEY
                }
            }

            const requestBody = {
                OrderID: orderID
            }

            axios.post(loginAPIUrl, requestBody, requestConfig)
                .then((response) => {
                    const orderData = response.data;
                    console.log(orderData);
                    setOrder(orderData);
                })
                .catch((error) => {
                    console.log(error.response);
                });
        }

        fetchOrder();
    }, [orderID]);  // Added orderID as a dependency to make sure the effect runs again only if orderID changes


    return (
        <motion.div className='fixed w-full h-full right-0 top-0 bg-blue-300 overflow-hidden flex items-center justify-center'>
            {
        [
            '-90%', '90%', '-100%', '90%', '-80%', '100%', '300%', '-300%', '300%', '-300%', '300%', '-300%',
            '400%', '-400%', '400%', '-400%', '500%', '-500%', '600%', '-600%', '700%', '-700%',
            '800%', '-800%', '900%', '-900%', '1000%', '-1000%', '1100%', '-1100%', '1200%', '-1200%', '1300%', '-1300%', '1400%', '-1400%', '1500%', '-1500%',
            // You can continue adding offsets here
          ].map((initialOffset, index) => (
          <motion.img
            className='z-0 opacity-50'
            key={index}
            src={leaf}
            alt="Flying Image"
            initial={{ y: '-750%', x: initialOffset, zIndex: 0 }}
            animate={{ y: '3000%', x: initialOffset }}
            transition={{
              delay: delays[index % delays.length], 
              duration: 50,
              ease: 'linear',
              repeat: Infinity
            }}
            style={{ width: '100px', height: '100px', position: 'absolute', opacity: '50%' }}
          />
        ))
      }
            {
                // ... (keep the map function for the leaves animation as is)
            }
            <Header></Header>
<div className='flex items-center justify-center w-full h-full -mb-28 p-1'>     
<div className=" w-11/12 h-5/6 z-20 bg-white rounded-lg overflow-y-auto p-6 shadow-md">
    
    {order ? (
        <div className="space-y-6">
            <header className="border-b pb-2">
                <h1 className="text-3xl font-bold mb-2">Order Details</h1>
            </header>

            <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-6">
                <div className="flex-1 space-y-3">
                    <div className="mb-2">
                        <h2 className="text-xl font-medium mb-1">Tracking Number</h2>
                        <p className="text-lg font-semibold text-blue-600">{order.trackingNumber}</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-medium mb-1">Order Status</h2>
                        <p className={`text-lg font-semibold ${order.orderStatus === 'Delivered' ? 'text-green-600' : 'text-yellow-500'}`}>
                            {order.orderStatus}
                        </p>
                    </div>
                </div>



                <div className="flex-1 p-10">
      <h2 className="text-xl font-medium mb-2">Items Ordered:</h2>
      {Object.values(order.items).map((orderItem, index) => {
        const product = productsData.find(p => p.id === orderItem.item.id);
        if (!product) return null; // If product not found in the productData, skip rendering.

        return (
          <div key={index} className="flex items-center mb-4">
            <img src={require(`../assets/${product.image}`)} alt={product.name} className="w-16 h-16 object-cover rounded mr-4"/> {/* adjust the path as necessary */}
            <div className="flex-1">
              <h3 className="text-lg font-semibold">{product.name}</h3>
              <p>Quantity: {orderItem.quantity}</p>
            </div>
          </div>
        );
      })}
    </div>
            </div>

            <div className="border-t pt-4 mt-4 text-gray-600">
                <p>Thank you for choosing us for your purchase!</p>
                <p>We are committed to providing the best experience for our customers. Should you have any questions or concerns about your order, please don't hesitate to reach out to our customer support team. We're here to help!</p>
            </div>

        </div>
    ) : (
        <div className="flex h-full w-full items-center justify-center text-xl font-semibold text-gray-500">
            Loading...
        </div>
    )}
</div>

</div>

        </motion.div>
    );
}

export default OrderTracker;
