import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../../service/AuthService';

const loginAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/getmyorders';

function OrderList() {
    const [orders, setOrders] = useState([]);
    const user = getUser();

    useEffect(() => {
        async function fetchOrders() {
            try {
                const requestConfig = {
                    headers: {
                        'x-api-key': process.env.REACT_APP_KEY,
                    },
                };
                const requestBody = { username: user.username };
                
                axios.post(loginAPIUrl, requestBody, requestConfig)
                    .then(response => {
                        const sortedOrders = response.data.Orders;
                        setOrders(sortedOrders);
                    })
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status === 403) {
                            console.log(error.response);
                        } else {
                            console.log(error.response);
                        }
                    });
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        }

        fetchOrders();
    }, []);

    return (
        <div className="flex flex-col sm:h-5/6 h-96 overflow-y-auto">
            <ul>
                {orders.slice().reverse().map(order => (
                    <li key={order} className=" p-2 border-b cursor-pointer text-blue-300 hover:bg-cyan-600">
                        <a href={`/order/${order}`}>
                            {order}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default OrderList;
