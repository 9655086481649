
import React, { useState } from 'react';
import axios from 'axios';
import loadinggif from '../assets/loading.gif';

import { setUserSession } from "./AuthService";

const loginAPIUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/login';

function LoginPopover({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // New state to track loading

  const submitHandler = (event) => {
    event.preventDefault();

    if(username.trim() === '' || password.trim() === '' ){
      setErrorMessage("Both username and password are required");
      return;
    }
    
    setErrorMessage(null);
    setLoading(true); // Set loading to true when login process starts

    const requestConfig = {
      headers: {
        'x-api-key' : process.env.REACT_APP_KEY
      }
    }
    
    const requestBody = {
      username: username,
      password: password
    }

    axios.post(loginAPIUrl, requestBody, requestConfig).then((response) => {
      setUserSession(response.data.user, response.data.token);
      onLoginSuccess(); // Call the callback function after successful login
      setLoading(false); // Set loading to false after login process ends
    }).catch((error) => {
      if(error.response.status === 401 || error.response.status === 403){
        setErrorMessage(error.response.data.message);
      }else{
        setErrorMessage('Sorry, the server is down right now, please try again later.');
      }
      setLoading(false); // Set loading to false after login process ends
    })
    
  }

  return (
    <div id = "loginPopover" className="bg-transparent rounded-xl min-h-screen flex flex-col items-center justify-center">
      {loading ? (
        <div id="loadingg" class="mt-8"><img class="max-w-full max-h-400" src={loadinggif} alt="Loading" /></div>
      ) : (
        <>
          <form id="loginPage" className="bg-white p-8 rounded-lg shadow-lg" onSubmit={submitHandler}>
          <h5 className="text-2xl font-bold mb-4">Login</h5>
        <div className="mb-4">
          <label className="block text-black-700 mb-2" htmlFor="username">
            Username
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" value={username} onChange={event => setUsername(event.target.value)} />
        </div>
        <div className="mb-6">
          <label className="block text-black-700 mb-2" htmlFor="password">
            Password
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" value={password} onChange={event => setPassword(event.target.value)} />
        </div>
        <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-20 mx-3 rounded focus:outline-none focus:shadow-outline my-6" type="submit" value="Login" />
        <div> New to tagit? <a className="hover:text-blue-700 py-2 px-2 rounded text-blue-500 font-semibold" href="/newaccount">Create account</a> </div>
      
          </form>
          {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
        </>
      )}
    </div>
  );
}

export default LoginPopover;
