import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ImageUpload from './ImageUpload'; // Import the ImageUpload component
import CustomColorPicker from './CustomColorPicker';

function ProfilePictureInfoEditor({ profilePictureInfo, setProfilePictureInfo, infoBarSettings  }) {
  const [showDisplayPictures, setShowDisplayPictures] = useState(false);
  const [matchInfoBar, setMatchInfoBar] = useState(false); // State to track the checkbox

  useEffect(() => {
    if ( profilePictureInfo.displayProfilePicture != false) {
      setShowDisplayPictures(true);
    }
  }, [profilePictureInfo]);

  useEffect(() => {
    if (matchInfoBar) {
      // When checkbox is checked, match InfoBar settings
      handleChange('infoBarColor', infoBarSettings.infoBarColor);
      handleChange('backgroundOpacity', infoBarSettings.backgroundOpacity);
    }
  }, [matchInfoBar, infoBarSettings]);

  const toggleDisplayPictures = () => {
    const newShowDisplayPictures = !showDisplayPictures;
    setShowDisplayPictures(newShowDisplayPictures);
    // Update displayProfilePicture state along with toggle
    setProfilePictureInfo(prevState => ({
      ...prevState,
      displayProfilePicture: newShowDisplayPictures,
    }));
  };
  // Function to handle changes in profile picture info
  const handleChange = (name, value) => {
    setProfilePictureInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleLocationChange = (newLocation) => {
    setProfilePictureInfo({
      ...profilePictureInfo,
      generalLocation: newLocation
    });
  };
  function RadioOption({ name, value, label, checked, onChange }) {
    // Determine the background and text color based on the 'checked' prop
    const backgroundColorClass = checked ? 'bg-blue-500 text-white' : 'bg-gray-200';
    
    return (
      <label className={`px-4 py-2 border rounded ${backgroundColorClass} cursor-pointer flex items-center mb-2`}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="hidden" // Hide the radio input
        />
        {label}
      </label>
    );
  }
  
  const handleProfilePictureUpdate = (imageUrl) => {
    setProfilePictureInfo(prevState => ({
      ...prevState,
      profilePicture: imageUrl, // Update the profilePicture field
    }));
  };

  const toggleSwitchVariants = {
    on: { x: "100%" },
    off: { x: "0%" }
  };

  // Variants for the dropdown animation
  const dropdownVariants = {
    hidden: {
      opacity: 0,
      maxHeight: 0,
      overflow: 'hidden',
      transition: {
        opacity: { duration: 0.2 },
        maxHeight: { duration: 0.5 }
      }
    },
    visible: {
      opacity: 1,
      maxHeight: 1000, // Adjust based on content size
      transition: {
        opacity: { delay: 0.1, duration: 0.3 },
        maxHeight: { duration: 0.5 }
      }
    },
  };

  return (
    <div className="bg-zinc-700 outline-1 outline-cyan-400 outline text-zinc-50  rounded shadow">
      {/* Toggle bar at the top */}
      <div className="flex justify-between items-center p-4">
        <h1 className="text-lg font-semibold">Profile Picture</h1>
        <div 
          className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${showDisplayPictures ? 'bg-blue-500' : 'bg-blue-500'}`} 
          onClick={toggleDisplayPictures}
        >
          <motion.div
            className="bg-white w-6 h-6 rounded-full shadow-md"
            animate={showDisplayPictures ? "on" : "off"}
            variants={toggleSwitchVariants}
            transition={{ type: "spring", stiffness: 700, damping: 30 }}
          ></motion.div>
        </div>
      </div>

      {/* Dropdown section for general and profile picture location options */}
      <motion.div
        initial="hidden"
        animate={showDisplayPictures ? "visible" : "hidden"}
        variants={dropdownVariants}
        className="overflow-hidden"
      >
        <div className="p-4 bg-zinc-700 text-black">
          {/* IMAGE UPLOAD COMPONENT */}
          {showDisplayPictures && (
            <ImageUpload setImageFile={handleProfilePictureUpdate} enableCropping={true} />
          )}
          <br></br>
        {/* Fancy Radio Buttons for General Location */}
<div className="flex items-center space-x-2">
  <RadioOption 
    name="generalLocation" 
    value="top" 
    label="Top"
    checked={profilePictureInfo.generalLocation === 'top'}
    onChange={() => handleLocationChange('top')}
  />
  <RadioOption 
    name="generalLocation" 
    value="bottom" 
    label="Bottom"
    checked={profilePictureInfo.generalLocation === 'bottom'}
    onChange={() => handleLocationChange('bottom')}
  />
</div>
{/* Radio Buttons for Profile Picture Location */}
<div className="flex items-center space-x-2">
  {['left', 'center', 'right'].map((position) => (
    <RadioOption 
      key={position}
      name="profilePictureLocation" 
      value={position} 
      label={position.charAt(0).toUpperCase() + position.slice(1)} // Capitalize the first letter
      checked={profilePictureInfo.profilePictureLocation === position}
      onChange={(e) => setProfilePictureInfo({ ...profilePictureInfo, profilePictureLocation: e.target.value })}
    />
  ))}
</div>


  {/* Color Picker for Profile Picture Background */}
      <CustomColorPicker
          label="Profile Picture Background Color"
          value={profilePictureInfo.infoBarColor || '#FFFFFF'} // Default white
          onChange={(color) => handleChange('infoBarColor', color)}
        />
   {/* Match InfoBar Settings Checkbox */}
   <div className="flex items-center text-white h-12  mb-4">
          <input
          
            id="matchInfoBar"
            type="checkbox"
            checked={matchInfoBar}
            onChange={(e) => setMatchInfoBar(e.target.checked)}
            className="mr-2 scale-150  "
          />
          <label htmlFor="matchInfoBar">Match InfoBar Color Settings</label>
        </div>
        {/* Opacity Slider */}
        <div className="flex items-center text-white my-4">
          <label className="mr-2">Opacity:</label>
          <input
            type="range"
            min="0"
            max="100"
            value={profilePictureInfo.backgroundOpacity || 100} // Default to 100 if not set
            onChange={(e) => handleChange('backgroundOpacity', e.target.value)}
            className="w-full"
          />
 <span className="ml-2">{profilePictureInfo.backgroundOpacity || 100}%</span>
        </div>
        </div>
      </motion.div>
    </div>
  );
}

export default ProfilePictureInfoEditor;
