import React, { useState, useEffect } from 'react';
import Background from "./Background";
import InfoBar from "./InfoBar";
import ProfilePicture from "./ProfilePicture";
import LinkComponent from './Link/LinkComponent';
import ImageComponent from './Image/ImageComponent';
import VideoComponent from './Video/VideoComponent';
import AudioComponent from './Audio/AudioComponent';
import ListComponent from './List/ListComponent';
import PollComponent from './Poll/PollComponent';
import TextComponent from './Text/TextComponent';
import ARComponent from './AR/ARComponent';
// Assuming InfoBar component accepts className as a prop for styling
function TagReader({ tagData, style, className, mode }) {
  console.log("Tagdata: ", tagData);

  const [modules, setModules] = useState([]);
  const [quicklinkTop, setQuickLinkTop] = useState([]);
  const [quicklinkBottom, setQuickLinkBottom] = useState([]);
  const [quicklinkLeft, setQuickLinkLeft] = useState([]);
  const [quicklinkRight, setQuickLinkRight] = useState([]);
  const isProfilePictureAtBottom = tagData.profilePictureInfo?.displayProfilePicture && tagData.profilePictureInfo?.generalLocation === 'bottom';
  const isInfoBarAtBottom = tagData.infoBar && tagData.infoBar.displayLocation === 'bottom';

  var styleadd = {
    pointerEvents:'auto',
  };
  if (mode === 'preview'){
    styleadd = {
      pointerEvents :'none',
    };
  }

 

  useEffect(() => {
    // Initialize temporary arrays to hold sorted modules
    const tempModules = [];
    const tempQuicklinkTop = [];
    const tempQuicklinkBottom = [];
    const tempQuicklinkLeft = [];
    const tempQuicklinkRight = [];

    // Iterate through tagData.modules and sort them based on their container type
    tagData.modules.forEach(module => {
      switch(module.ButtonStyle.containerType) {
        case "regular":
          tempModules.push(module);
          break;
        case "quicklink":
          {
            switch(module.ButtonStyle.buttonStyleId) {
              case 0:
                tempQuicklinkTop.push(module);
                break;
              case 1:
                tempQuicklinkBottom.push(module);
                break;
              case 2:
                tempQuicklinkLeft.push(module);
                break;
              case 3:
                tempQuicklinkRight.push(module);
                break;
            }
          }
          
          break;
  
        default:
          // Handle any modules that don't match the specified types
          tempModules.push(module);
          break;
      }
      
    });

    // Update state with the sorted modules
    setModules(tempModules);
    setQuickLinkTop(tempQuicklinkTop);
    setQuickLinkBottom(tempQuicklinkBottom);
    setQuickLinkLeft(tempQuicklinkLeft);
    setQuickLinkRight(tempQuicklinkRight);
  }, [tagData]); // Depend on tagData so this effect runs whenever tagData changes



// Function to render modules (assuming 'modules' should be centered)
const renderModules = () => {
  return modules.map((module, index) => {
    // Check the module type and render the appropriate component


    if (module.type === "link") {
      return     <div key={index} className=" py-2"> {/* Adjust padding as needed */}
      <LinkComponent data={module} />
    </div>;
    }
    if (module.type === "image") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <ImageComponent data={module} />
    </div>;
    }
    if (module.type === "video") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <VideoComponent data={module} />
    </div>;
    }

    if (module.type === "audio") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <AudioComponent data={module} />
    </div>;
    }
    if (module.type === "list") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <ListComponent data={module} />
    </div>;
    }
    if (module.type === "poll") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <PollComponent data={module} />
    </div>;
    }
    if (module.type === "text") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <TextComponent data={module} />
    </div>;
    }
    if (module.type === "ar") {
      return     <div key={index} className="py-2"> {/* Adjust padding as needed */}
      <ARComponent data={module} />
    </div>;
    }
    // Add additional conditions for other module types here
  });
};

// Functions for rendering quicklinks at their designated positions

//TOP
const renderQuicklinkTop = () => {

  return quicklinkTop.map((module, index) => { 

    if (module.type === "link") {
    return <div key={index} className="p-2">
    <LinkComponent key={index} data={module} /></div>;
    }
    if (module.type === "audio") {
      return     <div key={index} className=" "> {/* Adjust padding as needed */}
      <div className=' scale-80'>
      <AudioComponent data={module} />
      </div>
    </div>;
    }
  
  
  }
  
);};
//BOTTOM
const renderQuicklinkBottom = () => {
  return quicklinkBottom.map((module, index) => { 

    if (module.type === "link") {
    return <div key={index} className="p-2">
    <LinkComponent key={index} data={module} /></div>;
    }
    
    if (module.type === "audio") {
      return     <div key={index} className=" "> {/* Adjust padding as needed */}
      <div className=' scale-80'>
      <AudioComponent data={module} />
      </div>
    </div>;
    }
  
  }
  
);};
//LEFT
const renderQuicklinkLeft = () => {
  return quicklinkLeft.map((module, index) => { 
    
    if (module.type === "link") {
    return <div key={index} className="p-2">
    <LinkComponent key={index} data={module} /></div>;
    }
    if (module.type === "audio") {
      return     <div key={index} className=" "> {/* Adjust padding as needed */}
      <div className=' scale-80'>
      <AudioComponent data={module} />
      </div>
    </div>;
    }
  }

  
);};
//RIGHT
const renderQuicklinkRight = () => {
  return quicklinkRight.map((module, index) => { 
    
    if (module.type === "link") {
    return <div key={index} className="p-2">
    <LinkComponent key={index} data={module} /></div>;
    }
    
    if (module.type === "audio") {
      return     <div key={index} className=" "> {/* Adjust padding as needed */}
      <div className=' scale-80'>
      <AudioComponent data={module} />
      </div>
    </div>;
    }
  
  }
  
);};


console.log(modules);
console.log(quicklinkTop);

  const renderInfoBar = () => {
    if (tagData && tagData.infoBar) {
      const positionClass = tagData.infoBar.displayLocation === 'top' ? 'sticky top-0' : 'sticky bottom-0';
      return <InfoBar infoBarData={tagData.infoBar} className={positionClass} />;
    }
    return null;
  };

  const renderProfilePicture = () => {
    if (tagData && tagData.profilePictureInfo && tagData.profilePictureInfo) {
      
      return <ProfilePicture profilePictureData={tagData.profilePictureInfo} />;
    }
    return null;
  };


  const isBottomElementPresent = isProfilePictureAtBottom || isInfoBarAtBottom;

  return (
 // Assuming the surrounding div has enough height and is positioned relatively
<div className={`w-full h-full  overflow-hidden ${className}`} style={style}>
  <Background className="w-full h-full" backgroundData={tagData.background}>
    <div className="z-20 w-full h-full flex flex-col " >
      {/* Fixed or Sticky top bar */}
      {tagData.profilePictureInfo.displayProfilePicture && tagData.profilePictureInfo.generalLocation === 'top' && (
        <div className="sticky top-0 z-50">
          {renderProfilePicture()}
        </div>
      )}
      {tagData.infoBar && tagData.infoBar.displayLocation === 'top' && (
        <div className="sticky top-0 z-50">
          {renderInfoBar()}
        </div>
      )}

      {/* Scrollable middle content */}
      <div className="middle flex-1 overflow-auto scrollbar-custom">
        <div className="flex flex-col h-full">
          <div className="flex-1 w-full flex flex-col">
            {/* Your main content here */}
            <div className="w-full flex justify-center">{renderQuicklinkTop()}</div>

            <div className="flex-1 w-full flex">
              {/* Render left quicklinks */}
              <div className="flex flex-col justify-center">{renderQuicklinkLeft()}</div>

              {/* Main content area for modules */}
              <div className="flex-grow py-2 px-8 " style={styleadd}>
                {renderModules()}
                {isBottomElementPresent && <div className='h-1/3'></div>}

              </div>

              {/* Render right quicklinks */}
              <div className="flex flex-col justify-center">{renderQuicklinkRight()}</div>
            </div>

         
          </div>
        </div>
      </div>

      {/* Handle both at the bottom */}
      {isProfilePictureAtBottom && isInfoBarAtBottom ? 
      (
      <div className="w-full absolute bottom-0 z-50 flex flex-col">
        {/* Render bottom quicklinks */}
        <div className="w-full flex justify-center">{renderQuicklinkBottom()}</div>
          {renderProfilePicture()}
          {renderInfoBar()}
          
        </div>
      ) 
      : 
      (<>
      {isProfilePictureAtBottom && (
      <div className="w-full absolute bottom-0 z-50">
      {/* Render bottom quicklinks */}
      <div className="w-full flex justify-center">{renderQuicklinkBottom()}</div>
        {renderProfilePicture()}
      </div>
      )}
      {isInfoBarAtBottom && (
        <div className="w-full absolute bottom-0 z-50">
        {/* Render bottom quicklinks */}
        <div className="w-full flex justify-center">{renderQuicklinkBottom()}</div>
          {renderInfoBar()}
        </div>
        )}
        </>
        )}
        {!isProfilePictureAtBottom && !isInfoBarAtBottom && (
        <div className="w-full absolute bottom-0 z-50">
          <div className="w-full flex justify-center">{renderQuicklinkBottom()}</div>
        </div>
        )}
    </div>
  </Background>
</div>

  );
}

  
  export default TagReader;
  