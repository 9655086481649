import React from 'react';
import ListOfButtons from '../../TagCreator/ListOfButtons';
import AudioPlayer from './AudioPlayer';
function AudioComponent({data}) {
const listOfButtons = ListOfButtons();

var linkDivSetup = <div></div>
console.log("Data!",data);
// Utility function to get Spotify embed link
const getSpotifyEmbedLink = (spotifyLink) => {
  let embedLink = spotifyLink.replace("open.spotify.com", "open.spotify.com/embed");
  return embedLink;
};

const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor,
  };
  var linkDivSetup = <div></div>

  if (data.ButtonStyle.containerType === "regular"){

  if(data.audioType === "spotify"){
    return(linkDivSetup = 
      <div id='tagModule' className=" flex  p-2 items-center justify-center w-full  outline outline-2 " style={Object.assign({}, baseStyles, additionalStyles)}>
        <iframe
          src={getSpotifyEmbedLink(data.url)}
          width="100%"
          height="80"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe>   
      </div>);

  }
  else{

  
    return (linkDivSetup = 
      <div id='tagModule' className=" flex  p-2 items-center justify-center w-full  outline outline-2" style={Object.assign({}, baseStyles, additionalStyles)}>
        <AudioPlayer src={data.url} image={data.image} title={data.title} font={data.ButtonStyle.fontClassName} type={"regular"} />
      </div>);
      }

  }



  else if (data.ButtonStyle.containerType === "large"){
    return(
      <div id='tagModule' className="flex p-2 items-center justify-center w-full h-full md:w-full md:h-64  outline outline-2" style={Object.assign({}, baseStyles, additionalStyles)}>
      <AudioPlayer src={data.url} image={data.image} title={data.title} font={data.ButtonStyle.fontClassName}  type={"large"}/>
    </div>
    );
  }


  else if (data.ButtonStyle.containerType === "quicklink"){
    return(
    <div id='tagModule'  className="inline-flex items-center justify-center rounded-full p-2 outline outline-black outline-2 " style={Object.assign({},  additionalStyles)}>
    <div className=' scale-75'>
    <AudioPlayer src={data.url} image={data.image} type={"quicklink"} />
    </div>
  </div>
    );
    
  }

  else {
    return(
    <div id='tagModule' className=" flex  p-2 items-center justify-center w-full  outline outline-2 " style={Object.assign({}, baseStyles, additionalStyles)}>
    <AudioPlayer src={data.url} image={data.image} title={data.title} font={data.ButtonStyle.fontClassName}  type={"regular"}/>

  </div>
    );
  }
}


export default AudioComponent;




