import React, { useState, useEffect } from 'react';
import ListOfBackgroundColorFusors from './ListOfBackgroundColorFusors';

function BackgroundColorFuserSelector({ background, setBackground, updateFusorColors }) {
  const backgroundImages = ListOfBackgroundColorFusors();
  
  // Find fusor with ID 0 or use the first fusor as a fallback
  const defaultFusor = backgroundImages.find(fusor => fusor.id === 0) || backgroundImages[0];
  
  // Initialize state with the found defaultFusor ID
  const [selectedFusorId, setSelectedFusorId] = useState(defaultFusor.id);
  
 
  const defaultColors = Array(defaultFusor.templateComponent.numberOfColors).fill('#ffffff');
  const [fusorColors, setFusorColors] = useState(defaultColors);

  //console.log("FUsor colors: ", fusorColors);
  useEffect(() => {
    handleBackgroundSelect(defaultFusor);
  }, []);

  const handleBackgroundSelect = (fusor) => {
    setSelectedFusorId(fusor.id);

    const initialColors = fusor.templateComponent.numberOfColors === fusorColors.length ? fusorColors : Array(fusor.templateComponent.numberOfColors).fill('#ffffff');
    setFusorColors(initialColors);
//console.log("initialColors:",initialColors);
    setBackground(prevState => ({
      ...prevState,
      backgroundColorFuser: fusor.id,
      backgroundColorFusorColors: initialColors
    }));
    updateFusorColors(fusor.id, initialColors);
  };

  const handleColorChange = (colorIndex, newColor) => {
    const updatedColors = [...fusorColors];
    updatedColors[colorIndex] = newColor;
    setFusorColors(updatedColors);
    //console.log(updatedColors);
    updateFusorColors(selectedFusorId, updatedColors);
  };

  const renderColorPickers = (fusor) => {
    if (selectedFusorId !== fusor.id) return null;

    const numberOfColors = fusor.templateComponent.numberOfColors || 0;
    let pickers = [];

    for (let i = 0; i < numberOfColors; i++) {
      pickers.push(
        <input 
          key={i} 
          type="color" 
          value={fusorColors[i] || '#ffffff'}
          onChange={(e) => handleColorChange(i, e.target.value)}
        />
      );
    }

    return <div>Select colors: {pickers}</div>; 
  };

  const renderFusorPreview = (fusor) => {
   
    const FusorComponent = fusor.templateComponent;
     //console.log(FusorComponent);
    return <FusorComponent colors={fusorColors} />;
  };

  return (
    <div className="flex overflow-x-auto p-2">
 {backgroundImages.slice(1).map(bg => (
  <div key={bg.id} className="flex-shrink-0 p-2">
    <button 
      onClick={() => handleBackgroundSelect(bg)} 
      className={`border ${selectedFusorId === bg.id ? 'border-blue-500' : 'border-transparent'} rounded-md`}
    >
      <div className='w-48'>
        {renderFusorPreview(bg)}
      </div>
      <span>{bg.name}</span>
    </button>
    {renderColorPickers(bg)}
  </div>
))}

    </div>
  );
}

export default BackgroundColorFuserSelector;
