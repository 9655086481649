import React from 'react';
import ListOfButtons from '../../TagCreator/ListOfButtons';
function VideoComponent({data}) {
const listOfButtons = ListOfButtons();
var linkDivSetup = <div></div>
console.log("Data!",data);

const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor
};


function getEmbedLink(watchLink) {
    let videoId;
    let embedLink;
    if (watchLink && watchLink.includes("v=")) {
        videoId = watchLink.split("v=")[1];
        let ampersandPosition = videoId.indexOf("&");
        if (ampersandPosition !== -1) {
            videoId = videoId.substring(0, ampersandPosition);
        }
        embedLink = "https://www.youtube.com/embed/" + videoId;
    } else if (watchLink && watchLink.includes("youtu.be")) {
        videoId = watchLink.split("/").pop();
        embedLink = "https://www.youtube.com/embed/" + videoId;
    }
    return embedLink;
}
const videolink = data.video;
const embedLink = getEmbedLink(videolink);



var linkDivSetup = <div></div>
return (linkDivSetup = 
    <div id='tagModule' className="inline-flex items-center justify-center outline outline-2 w-full" style={Object.assign({}, baseStyles, additionalStyles)}>
    <div className='inline-flex '>
        <div className="aspect-w-16 aspect-h-9">
            <iframe
                className="justify-center items-center w-full p-2"
                src={embedLink}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ objectFit: 'cover' }}
            ></iframe>
        </div>
    </div>
    </div>

  );
}


export default VideoComponent;




