import React, { useState } from 'react';

import TextReviewList from './TextReviewList';
import Header from './Header/Header';

const HelpPage = () => {
  const [submittedTexts, setSubmittedTexts] = useState([]);

  const handleTextSubmit = (text) => {
    setSubmittedTexts([...submittedTexts, text]);
  };

  return (
    <div className="p-4">
       <Header></Header>
      
      <TextReviewList texts={submittedTexts} />
    </div>
  );
};

export default HelpPage;