import React, { useState } from "react";
import TotalViews from "./TotalViews";
import Interactables from "./Interactables";
import ViewsList from "./ViewsList";

function Data({ totalViews, viewsList, interactables, createdTags }) {
    const [activeComponent, setActiveComponent] = useState("viewsList");

    const handleSlide = (direction) => {
        if (direction === "right" && activeComponent === "interactables") {
            setActiveComponent("viewsList");
        } else if (direction === "right" && activeComponent === "viewsList") {
            setActiveComponent("interactables");
        }
    };

    const handleClickCircle = (component) => {
        setActiveComponent(component);
    };

    return (
        <div className="w-full h-full outline outline-1 bg-zinc-950 outline-blue-300 rounded-lg p-4">
            <h1 className="text-lg  ml-3 mb-3 font-semibold text-white">Data & Analytics:</h1>
          
            <div className="flex w-full h-full">
                <div className="w-1/3 h-5/6 p-2">
                    <TotalViews totalViews={totalViews} />
                </div>
                <div className="w-2/3 h-5/6 p-2 relative " >
                    
                    <div
                        className={`absolute inset-0 transition-transform ${activeComponent === "viewsList" ? "translate-x-0" : "translate-x-full"}`}
                        style={{ display: activeComponent === "viewsList" ? "block" : "none" }}
                    >
                      
                        <ViewsList viewsList={viewsList} createdTags ={createdTags} totalViews={totalViews}/>
                      
                    </div>
                    <div
                        className={`absolute inset-0 transition-transform ${activeComponent === "interactables" ? "translate-x-0" : "translate-x-full"}`}
                        style={{ display: activeComponent === "interactables" ? "block" : "none" }}
                    >
                        <Interactables interactables={interactables}/>
                    </div>
               
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                        <span
                            onClick={() => handleClickCircle("viewsList")}
                            className={`w-2 h-2 rounded-full cursor-pointer ${activeComponent === "viewsList" ? "bg-white" : "bg-gray-500"}`}
                        />
                        <span
                            onClick={() => handleClickCircle("interactables")}
                            className={`w-2 h-2 rounded-full cursor-pointer ${activeComponent === "interactables" ? "bg-white" : "bg-gray-500"}`}
                        />
                    </div>
                    <div className="flex h-full w-full ">
                        <div className="absolute bottom-0 h-1/6 w-full" onClick={(e) => handleSlide(e.clientX < window.innerWidth / 2 ? "left" : "right")}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Data;
