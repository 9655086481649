import React, { useState, useEffect } from 'react';
import { HiXMark } from "react-icons/hi2";
import ButtonStyle from '../ButtonStyle';
import RoundStyle from '../RoundStyle';
import ImageUpload from '../ImageUpload';
import ImageComponent from '../../TagReader/Image/ImageComponent';

function ImageModuleCreator({ onSave, initialData, onCancel, recentButtonStyles }) {
  const [scale, setScale] = useState(1);
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [selectedButtonStyleId, setSelectedButtonStyleId] = useState(0);
  const [fontColor, setFontColor] = useState('#99ffdd');
  const [buttonColor, setButtonColor] = useState('#99ffdd');

  // Adjust scale based on window size
  useEffect(() => {
    const handleResize = () => {
      const scaleHeight = Math.min(window.innerHeight / 900, 1);
      setScale(scaleHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (initialData) {
      setLink(initialData.url || '');
      setSelectedButtonStyleId(initialData.buttonStyleId || 0);
      if (initialData.ButtonStyle) {
        setButtonColor(initialData.ButtonStyle.buttonColor);
        setFontColor(initialData.ButtonStyle.fontColor);
        setSelectedButtonStyleId(initialData.ButtonStyle.buttonStyleId);
      }
    } else {
      setLink('');
      setSelectedButtonStyleId(0);
      setButtonColor('#99ffdd');
      setFontColor('#99ffdd');
    }
  }, [initialData]);

  const handleFontColorChange = (newColor) => {
    setFontColor(newColor);
  };

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
  };

  const handleSubmit = () => {
    const ButtonStyleData = {
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        containerType: "regular",
        fontColor: fontColor,
      },
      type: 'image',
      url: link,
      id: initialData?.id
    };

    onSave(ButtonStyleData);
  };

  const buttonSelection = (e) => {
    setSelectedButtonStyleId(Number(e.target.value));
  };

  const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

  const previewStyle = {
    backgroundColor: '#333',
    backgroundImage: `url("${svgGrid}")`,
    backgroundSize: '20px 20px',
    height: '200px',
    width: '100%',
    marginBottom: '20px',
    position: 'relative',
    padding: '3px'
  };

  return (
    <div className="fixed inset-0 rounded bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40 flex items-center justify-center overflow-x-clip">
      <div className="mx-auto border border-cyan-400 outline outline-1 overflow-y-auto overflow-x-hidden scrollbar-custom outline-cyan-400 shadow-lg rounded-md -mb-12 h-75vh w-10/12  sm:w-2/5 1k:w-3/12 bg-zinc-700 text-zinc-50 relative">
        <button
          className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150 z-50"
          onClick={onCancel}
        >
          <HiXMark className="text-white" size={23} />
        </button>
        <div className="text-center">
          <div className='p-4 overflow-auto scrollbar-custom' style={previewStyle}>
            <div className='flex justify-center items-center w-full h-full ' style={{ maxHeight: 'calc(100% - 40px)' }}>
              <div className='w-full h-full flex justify-center items-center'>
                <div className='p-2  w-4/5 h-full'  style={{ pointerEvents :'none',}}>
                  <ImageComponent data={{
                    ButtonStyle: {
                      buttonStyleId: selectedButtonStyleId,
                      buttonColor: buttonColor,
                      containerType: "regular",
                      fontColor :fontColor,
                    },
                    type: 'image',
                    url: link,
                    id: initialData?.id,
                    title: title,
                  }} />
                </div>
              </div>
            </div>
          </div>

          <h3 className="text-base font-semibold bg-transparent py-3 w-full mx-8 text-left">Upload Image:</h3>
          <div className='flex items-center justify-center p-3'>
            <ImageUpload setImageFile={setLink} enableCropping={false} />
          </div>

          <ButtonStyle
            selectedButtonStyleId={selectedButtonStyleId}
            buttonSelection={buttonSelection}
            buttonColor={buttonColor}
            fontColor={fontColor}
            handleFontColorChange={handleFontColorChange}
            handleColorChange={handleColorChange}
            recentButtonStyles={recentButtonStyles}
          />

          <div className="flex justify-end items-center p-4">
            <button
              className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded transition duration-150 ease-in-out"
              onClick={handleSubmit}
            >
              Add Tag Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageModuleCreator;
