import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { getUser } from '../../service/AuthService';

function AudioUpload({ setAudioFile }) {
  const [uploading, setUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(""); // State to track the uploaded file name
  const user = getUser();
  const audioUploadAPIURL = process.env.REACT_APP_AUDIO_UPLOAD;

  const uploadAudioFile = (files) => {
    const fileInput = files[0];

    if (fileInput && fileInput.type.startsWith("audio/")) {
      setUploading(true); // Indicate that upload is in progress
      setUploadedFileName(""); // Reset uploaded file name state

      const now = new Date();
      const dateWithMillis = now.toISOString();
      const type = fileInput.type.split("/");
      const extension = type[type.length - 1];
   
      const filename = `${user.username}.audio.${dateWithMillis}.${extension}`;
     
      const requestConfig = {
        headers: { 'x-api-key': process.env.REACT_APP_KEY }
      };
      const requestBody = {
        filename: filename,
        filetype: fileInput.type
      };

      axios.post(audioUploadAPIURL, requestBody, requestConfig)
        .then((response) => {
  
          if (response.data && response.data.uploadurl) {
            const presignedUrl = response.data.uploadurl;

            axios.put(presignedUrl, fileInput, { headers: { 'Content-Type': fileInput.type } })
              .then(() => {
                const audioUrl = presignedUrl.split("?")[0];
                setAudioFile(audioUrl); // Update parent component with the audio file URL
                setUploading(false); // Reset uploading state
                setUploadedFileName(fileInput.name); // Update state with the original file name
              })
              .catch(error => {
                console.error(`Audio file upload failed: ${error}`);
                setUploading(false); // Reset uploading state
              });
          } else {
            throw new Error('Presigned URL missing in response');
          }
        })
        .catch(error => {
          console.error(`Failed to get presigned URL: ${error}`);
          setUploading(false); // Reset uploading state
        });
    } else {
      alert("This file is not an audio. Please upload an audio file.");
      setUploading(false); // Ensure uploading state is reset on failure
    }
  };

  return (
    <div className="p-4 bg-white rounded text-black shadow-md">
      <Dropzone onDrop={uploadAudioFile} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="p-4 border-dashed border-2 border-gray-300 rounded cursor-pointer hover:border-gray-400">
            <input {...getInputProps()} />
            <p className="text-center text-gray-600 mt-2">Click or drop audio file here</p>
          </div>
        )}
      </Dropzone>

      {uploading && <div className="loading-bar">Uploading...</div>}
      {!uploading && uploadedFileName && <div className="mt-2 text-center">Uploaded file: {uploadedFileName}</div>}
    </div>
  );
}

export default AudioUpload;
