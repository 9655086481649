import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { QRCode } from 'react-qrcode-logo';

function DevicePreview({ Device, user, tag }) {
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Device.devicetype === 'sticker' || Device.devicetype === 'image') {
            setLoading(true);
            getDevice(Device.deviceid)
                .then((data) => {
                    setDeviceData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        }
    }, [Device]);

    if (Device.devicetype === 'sticker') {
        return (
            <div>
                {loading ? (
                    <div className='w-64 h-64 text-white'>Loading...</div>
                ) : (
                    <div className='w-64 h-64 text-white flex flex-col items-center justify-center'>
                        {deviceData && deviceData.image ? (
                            <img className='h-44 w-44 sm:h-20 sm:w-20 1k:h-48 1k:w-48 2k:h-52 2k:w-52   p-4 object-contain' src={deviceData.image} alt='Sticker' />
                        ) : (
                            <QRCode
                                qrStyle="dots"
                                bgColor='#93C5FD'
                                size={100}
                                ecLevel="H"
                                logoPadding={5}
                                logoHeight={15}
                                logoWidth={40}
                                removeQrCodeBehindLogo={true}
                                logoImage={require("../../assets/tagitblackwhite.png")}
                                value={`tagit.link/device/${Device.Id}`}
                                eyeRadius={5}
                            />
                        )}
                        <span className=''>Sticker</span>
                    </div>
                )}
            </div>
        );
    }
    if (Device.devicetype === 'image') {
        return (
            <div className='w-64 h-64  text-white flex flex-col items-center justify-center'>
                {deviceData && deviceData.image ? (
                    <img className='h-44 w-44 sm:h-20 sm:w-20 1k:h-48 1k:w-48 2k:h-52 2k:w-52  object-contain' src={deviceData.image} alt='Image' />
                ) : (
                    <span>No Image Available</span>
                )}
                <span className=''>Image</span>
            </div>
        );
    }

    if (Device.devicetype === 'wristband') {
        return (
            <div className='w-64 h-64 text-white flex flex-col items-center justify-center'>
                <img className='h-44 w-44 sm:h-20 sm:w-20 1k:h-48 1k:w-48 2k:h-52 2k:w-52  p-4 object-contain' src={require('../../assets/TagitSportsBand3.png')} alt='Wristband' />
                <span className=''>Wristband</span>
            </div>
        );
    }

    if (Device.devicetype === 'ufo') {
        return (
            <div className='w-64 h-64 text-white flex flex-col items-center justify-center'>
                <img className='h-44 w-44 sm:h-20 sm:w-20 1k:h-48 1k:w-48 2k:h-52 2k:w-52  p-4 object-contain' src={require('../../assets/ufo.png')} alt='Wristband' />
                <span className=''>UFO</span>
            </div>
        );
    }

    return (
        <div>
            Devicey
        </div>
    );
}

export default DevicePreview;

function getDevice(deviceid) {
    const deviceReadUrl = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/deviceread';

    return new Promise((resolve, reject) => {
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_KEY,
            },
        };
        const requestBody = {
            deviceid: deviceid,
        };

        axios.post(deviceReadUrl, requestBody, requestConfig)
            .then((response) => {
                const DeviceData = response.data.device;
                resolve(DeviceData);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        console.log(error.response.data.message);
                    } else {
                        console.log('Sorry, the server is down right now, please try again later.');
                        console.log(error.response.data.message);
                    }
                } else {
                    console.log('Network error');
                }
                reject(error);
            });
    });
}
