import React from 'react';

function Display({ colors }) {
  
  // Use provided colors or default to white if not enough colors are provided
  const [startColor, endColor] = colors.length >= 2 ? colors : ['#ffffff', '#ffffff'];

  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="display" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: startColor, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: endColor, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#display)" />
    </svg>
  );
}

Display.numberOfColors = 2;

export default Display;
