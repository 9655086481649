import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import yourBaseImage from '../assets/headerbackgroundshop.png'; 
import productImage1 from '../assets/headerbackgroundtagitband.png';
import productImage2 from '../assets/headerbackgroundsticker.png';

function HeaderShop() {
    const [currentImage, setCurrentImage] = useState(productImage1);
    const [isBlinking, setIsBlinking] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(current => (current === productImage1 ? productImage2 : productImage1));
        }, 7000); // Change image every 6 seconds

        const blinkInterval = setInterval(() => {
            setIsBlinking(true);
            setTimeout(() => setIsBlinking(false), 100); // Blink for a short duration
        }, Math.random() * 10000 + 5000); // Random interval for blinking

        return () => {
            clearInterval(interval);
            clearInterval(blinkInterval);
        };
    }, []);

    const imageVariants = {
        visible: { opacity: 1, scaleY: 1, originY: 1 },
        hidden: { opacity: 0, scaleY: 0, originY: .3 },
        blink: { opacity: 0 }
    };

    return (
        <div className='relative w-full h-full'>
            <img 
                src={yourBaseImage} 
                alt='Base Image' 
                className='relative w-full h-auto z-10' 
            />
            <motion.img 
                key={currentImage}
                src={currentImage}
                alt='Product Image'
                variants={imageVariants}
                initial="hidden"
                animate={isBlinking ? "blink" : "visible"}
                exit="hidden"
                transition={{ duration: isBlinking ? 0.05 : 0.3 }} // Faster transition for blink
                className='absolute top-0 left-0 w-full h-full z-20'
            />
        </div>
    );
}

export default HeaderShop;
