import React from 'react';

function Gradient2({ colors }) {
    const gradientId = "grad2"; // Ensure this is unique if using multiple gradients in the same view
    // Use provided colors or default to white if not enough colors are provided
    const defaultColors = ['#ffffff', '#ffffff', '#ffffff'];
    const [startColor, midColor, endColor] = colors.length >= 3 ? colors : defaultColors;

    return (
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: startColor, stopOpacity: 1 }} />
                    <stop offset="50%" style={{ stopColor: midColor, stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: endColor, stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <rect width="100%" height="100%" fill={`url(#${gradientId})`} />
        </svg>
    );
}

Gradient2.numberOfColors = 3;

export default Gradient2;
