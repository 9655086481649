import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUser } from "../service/AuthService";

const TagCreatedPopup = ({ tagname, onClose }) => {
  const user = getUser();
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');

  const handleNicknameSubmit = () => {
    const updateNickNameAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/updatenickname';
    const requestBody = {
      username: user.username,
      tagid: tagname,
      nickname: nickname
    };

    axios.post(updateNickNameAPIURL, requestBody).then((response) => {
      navigate('/dashboard');
      window.location.reload();
    }).catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        console.log(error.response.data.message);
      } else {
        console.log('Sorry, the server is down right now, please try again later.');
        console.log(error.response.data.message);
      }
    });

    setNickname('');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-zinc-700 w-11/12 max-w-lg p-6 outline outline-blue-300 rounded-lg relative" style={{ boxShadow: '0 0 10px #67E8F9' }}>
        <h2 className="text-white text-2xl">Give your new tag a name:</h2>
        <div className="mt-4 flex items-center">
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            maxLength="10"
            className="border p-2 w-full rounded-lg"
            placeholder="Enter a name"
          />
          <button
            onClick={handleNicknameSubmit}
            className="ml-2 p-2 bg-green-500 text-white rounded-lg hover:bg-green-700"
          >
            ✔️
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagCreatedPopup;
