
function ListOfFonts() {

    var ListOfFonts = [
         { id: 0 , name: 'Kanit', className: 'font-kanit' },
         { id: 1 , name: 'Lora', className: 'font-lora' },
         { id: 2 , name: 'Montserrat', className: 'font-montserrat' },
         { id: 3 , name: 'Mukta', className: 'font-mukta' },
         { id: 4 , name: 'Open Sans', className: 'font-openSans' },
         { id: 5 , name: 'Oswald', className: 'font-oswald' },
         { id: 6 , name: 'Poor Story', className: 'font-poorStory' },
         { id: 7 , name: 'Poppins', className: 'font-poppins' },
         { id: 8 , name: 'PT Sans Narrow', className: 'font-ptSansNarrow' },
         { id: 9 , name: 'Roboto', className: 'font-roboto' },
         { id: 10 ,name: 'Roboto Condensed', className: 'font-robotoCondensed' },
         { id: 11 , name: 'Roboto Mono', className: 'font-robotoMono' },
         { id: 12 ,name: 'Rubik Bubbles', className: 'font-rubikBubbles' },
         { id: 13 , name: 'Work Sans', className: 'font-workSans' },
         { id: 14 ,name: 'Xanh Mono', className: 'font-xanhMono' },
         { id: 15 ,name: 'Orbitron', className: 'font-Orbitron' },
       ];
     
       return (ListOfFonts)
           
         
     }
     
     export default ListOfFonts;
     