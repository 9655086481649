import React from 'react';
import moduleTypes from './moduleTypes';
import { HiXMark } from "react-icons/hi2";
function ModuleSelectionModal({ closeModal, onModuleSelect }) {
  const modulesByCategory = Object.keys(moduleTypes).reduce((acc, type) => {
    const category = moduleTypes[type].category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({
      type,
      ...moduleTypes[type],
    });
    return acc;
  }, {});

  return (
    <div className="fixed z-40 inset-0 -mb-10 bg-gray-600 bg-opacity-50 overflow-y-auto flex items-center justify-center p-4">
      <div className="bg-zinc-950 outline outline-1 outline-cyan-400 text-zinc-50 p-4 rounded-lg shadow-lg w-full sm:max-w-md max-w-lg">
        <div className="flex justify-between items-start">
          <h2 className="text-xl font-semibold">Select a Tag Item to add:</h2>
          <button 
            className="  bg-red-500 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150"
            onClick={closeModal}
          >
            <HiXMark className="text-whit  "   size={23}/>
          </button>
        </div>

        <div className="mt-4">
          {Object.keys(modulesByCategory).map((category) => (
            <div key={category} className="mb-4">
              <h3 className="text-lg font-semibold mb-2">{category}:</h3>
              <div className="flex flex-wrap justify-start items-center gap-2">
                {modulesByCategory[category].map(({ type, displayName, icon }) => (
                  <button 
                    key={type}
                    className="bg-black hover:bg-blue-700 outline outline-1 outline-cyan-400 text-white font-bold py-2 px-4 rounded-lg text-sm flex flex-col items-center justify-center gap-2"
                    style={{ minWidth: '90px', minHeight: '90px' }} // Adjusted for smaller screens
                    onClick={() => onModuleSelect(type)}
                  >
                    <img src={icon} alt="" className="w-16 h-16"/> {/* Adjusted image size */}
                    {displayName}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ModuleSelectionModal;
