import React, { useState, useEffect } from 'react';
import ListOfButtons from '../ListOfButtons';
import CustomColorPicker from '../CustomColorPicker';
import ListOfFonts from '../ListOfFonts';
import { HiXMark } from "react-icons/hi2";
import ButtonStyle from '../ButtonStyle';
import RoundStyle from '../RoundStyle';
import ListOfRoundButtons from '../ListOfRoundButtons';
import LinkComponent from '../../TagReader/Link/LinkComponent';
import AudioUpload from './AudioUpload';
import ImageUpload from '../ImageUpload';
import AudioComponent from '../../TagReader/Audio/AudioComponent';

function AudioModuleCreator({ onSave, initialData, onCancel, recentButtonStyles }) {
  const [scale, setScale] = useState(1);

  // Adjust scale based on window size
  useEffect(() => {
    const handleResize = () => {
      const scaleHeight = Math.min(window.innerHeight / 1100, 1); // Assuming 900 is the height at which the component fits perfectly
      setScale(scaleHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set the scale

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [selectedButtonStyleId, setSelectedButtonStyleId] = useState(0); // ID of the selected button style
  const buttons = ListOfButtons(); // Get button styles

  const [buttonColor, setButtonColor] = useState('#99ffdd'); // Default color, adjust as needed
  
  const [fontColor, setFontColor] = useState('#99ffdd');

  const [selectedFontClassName, setSelectedFontClassName] = useState('font-kanit'); // Default font class name
  const fonts = ListOfFonts(); // Get the list of fonts
  const [selectedContainer, setSelectedContainer] = useState('regular');
  const [audioType, setAudioType] = useState('upload');


  function RadioOption({ name, value, label, checked, onChange }) {
    // Determine the background and text color based on the 'checked' prop
    const backgroundColorClass = checked ? 'bg-blue-500 text-white' : 'bg-gray-200';
    
    return (
      <label className={`px-4 py-2 border rounded ${backgroundColorClass} cursor-pointer text-black flex items-center mb-2`}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="hidden" // Hide the radio input
        />
        {label}
      </label>
    );
  }
  useEffect(() => {
    if (audioType === 'spotify') {
      setSelectedContainer('regular'); // Lock container to 'regular' when 'spotify' is selected
    }
  }, [audioType]);

  console.log("initialData", initialData);
  useEffect(() => {
    if (initialData) {
      setLink(initialData.url || '');
      setImage(initialData.image || '');
      setTitle(initialData.title||'');
      setAudioType(initialData.audioType || 'upload')
      setSelectedButtonStyleId(initialData.buttonStyleId || 0);
      if (initialData.ButtonStyle) { // Assuming ButtonStyle holds the style info
        setButtonColor(initialData.ButtonStyle.buttonColor);
        setFontColor(initialData.ButtonStyle.fontColor);
        setSelectedContainer(initialData.ButtonStyle.containerType);
        setSelectedFontClassName(initialData.ButtonStyle.fontClassName);
        setSelectedButtonStyleId(initialData.ButtonStyle.buttonStyleId);
      }
    } else {
      // Reset to defaults if no initialData
      setLink('');
      setImage('');
      setTitle('');
      setSelectedButtonStyleId(0);
      setButtonColor('#99ffdd'); // Default color
      setFontColor('#99ffdd'); // Default color
      setSelectedFontClassName('font-kanit'); // Default font
    }
  }, [initialData]);
  
  const handleFontColorChange = (newColor) => {
    setFontColor(newColor);
  };

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
  };
  const handleFontChange = (e) => {
    setSelectedFontClassName(e.target.value);
  };
  // Modify the handleSubmit function to include the buttonColor in the onSave object
  const handleSubmit = () => {
    var ButtonStyleData = {};
    if (title === ''){
      ButtonStyleData = {
        ButtonStyle: {
          buttonStyleId: selectedButtonStyleId,
          buttonColor: buttonColor,
          fontColor: fontColor,
          fontClassName: selectedFontClassName,
          containerType:selectedContainer
        },
        // Include other relevant data here
        type: 'audio',
        audioType: audioType,
        url: link,
        image:image,
        id: initialData?.id,
        title:title
      };}
    else{
      console.log("there is a title");
     ButtonStyleData = {
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        fontColor: fontColor,
        fontClassName: selectedFontClassName,
        containerType:selectedContainer
      },
      // Include other relevant data here
      type: 'audio',
      audioType: audioType,
      url: link,
      image: image,
      id: initialData?.id,
      title: title,
    };
    }
    onSave(ButtonStyleData);
  
    // Reset states if necessary
  };

  const buttonSelection = (e) => {
    setSelectedButtonStyleId(Number(e.target.value));
  };

  // Inline style for the preview area with SVG background
  const svgGrid = `data:image/svg+xml;base64,${btoa(`<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="white" stroke-width="0.5"/>
  </svg>`)}`;

  const previewStyle = {
    backgroundColor: '#333', // Dark gray background
    backgroundImage: `url("${svgGrid}")`,
    backgroundSize: '20px 20px',
    height: '200px',
    width: '100%',
    height:'100%',
    marginBottom: '20px',
  };

  // Temporary placeholder for global button color
  const buttonColorGlobal = '#f0f0f0'; // Example color, adjust as needed

  return (
<div className="fixed inset-0 rounded bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40 flex items-center justify-center">
<div className="mx-auto border border-cyan-400 outline outline-1 overflow-y-auto overflow-x-hidden scrollbar-custom outline-cyan-400 shadow-lg rounded-md -mb-12 h-75vh w-10/12  sm:w-2/5 1k:w-3/12 bg-zinc-700 text-zinc-50 relative">

        <div className="text-center">
          
        <div className="p-4 overflow-auto" style={{ ...previewStyle, position: 'relative' }}> {/* Add position: 'relative' to the previewStyle */}
        <div className='p-2'>
          <button 
              className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 hover:bg-red-700 text-white rounded-full h-9 w-9 flex items-center justify-center transition-colors duration-150"
            onClick={onCancel}
          >
            <HiXMark className="text-whit  "   size={23}/>
          </button>
        <div className='flex justify-center items-center w-full h-full'>
            {/* Content of your preview will go here */}
            <div className=' w-4/5 flex justify-center items-center'  style={{ pointerEvents :'none',}}>
            <AudioComponent data={{
      ButtonStyle: {
        buttonStyleId: selectedButtonStyleId,
        buttonColor: buttonColor,
        fontColor:fontColor,
        fontClassName: selectedFontClassName,
        containerType:selectedContainer
      },
      // Include other relevant data here
      type: 'audio',
      audioType: audioType,
      url: link,
      image: image,
      id: initialData?.id,
      title: title,
    }}></AudioComponent>
    </div>
        </div>
        </div>
      </div>


{/* Radio Buttons for upload vs spotify */}
<div className="flex justify-center p-2 items-center space-x-2">
  {['upload', 'spotify'].map((type) => (
    <RadioOption 
      key={type}
      name="audioType" 
      value={type} 
      label={type.charAt(0).toUpperCase() + type.slice(1)} // Capitalize the first letter
      checked={audioType === type}
      onChange={(e) => setAudioType( e.target.value )}
    />
  ))}
</div>

{audioType === 'upload' ? (
          // Upload specific UI
          <div>
           <div className='w-full flex justify-center items-center p-2'>
    <div className='w-full flex justify-center items-center'>
        <div className='w-4/6'>
            <h3 className="text-base font-semibold bg-transparent py-3 w-full mx-8 text-left">Upload your audio file:</h3>
            <AudioUpload setAudioFile={setLink}/>
        </div>
    </div>
    {selectedContainer === 'quicklink' || 'large' && ( 
    <div className='w-full flex justify-center items-center'>
    <div className='w-4/6'>
            <h3 className="text-base font-semibold bg-transparent py-3 w-full mx-8 text-left" >Upload cover image:</h3>
            <ImageUpload enableCropping={true} enableSquareCrop={true} setImageFile={setImage}/>
        </div>
    </div>
    )}
</div>




    <div>
            <h3 className="text-base font-semibold bg-transparent py-3 w-full mx-8 text-left">Add a title:</h3>
            <text className=' p-2'>Title:</text> 
            <input 
              type="text" 
              value={title} 
              onChange={(e) => setTitle(e.target.value)}
              className="mt-2 p-2 border border-gray-300 text-black rounded w-5/6"
              placeholder="Enter title here (Optional)"
            />
          </div>





{/* Radio Buttons for Profile Picture Location */}
<div className="flex justify-center p-2 items-center space-x-2">
  {['regular', 'large', 'quicklink'].map((position) => (
    <RadioOption 
      key={position}
      name="containerType" 
      value={position} 
      label={position.charAt(0).toUpperCase() + position.slice(1)} // Capitalize the first letter
      checked={selectedContainer === position}
      onChange={(e) => setSelectedContainer( e.target.value )}
    />
  ))}
</div>
          </div>
        ) : (
          // Spotify specific UI
          <div>
            <h3 className="text-base font-semibold bg-transparent py-3 w-full mx-8 text-left">Spotify Link:</h3>
            <input 
              type="text" 
              value={link} 
              onChange={(e) => setLink(e.target.value)}
              className="mt-2 p-2 border border-gray-300 text-black rounded w-5/6"
              placeholder="Enter Spotify link here"
            />
          </div>
        )}




    


{selectedContainer === 'regular' || selectedContainer === 'large' ? (        
<ButtonStyle
  selectedButtonStyleId={selectedButtonStyleId}
  buttonSelection={buttonSelection}
  buttonColor={buttonColor} // Pass the actual button color state
  fontColor = {fontColor}
  handleFontColorChange = {handleFontColorChange} 
  handleColorChange={handleColorChange} // Pass the handler to change the button color
  selectedFontClassName={selectedFontClassName} // Pass the selected font class name state
  handleFontChange={handleFontChange}
  recentButtonStyles = {recentButtonStyles} // Pass the handler to change the font
/>

) : (
  <RoundStyle
  selectedButtonStyleId={selectedButtonStyleId}
  buttonSelection={buttonSelection}
  buttonColor={buttonColor} // Pass the actual button color state
  handleColorChange={handleColorChange} // Pass the handler to change the button color
  selectedFontClassName={selectedFontClassName} // Pass the selected font class name state
 
  recentButtonStyles = {recentButtonStyles} // Pass the handler to change the font
  />

  )}
          
         {/* Flex container for the action buttons */}
         <div className="flex justify-end items-center p-4">
          <button 
            className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded transition duration-150 ease-in-out"
            onClick={handleSubmit}
          >
            Add Tag Item
          </button>
        </div>
      
        </div>
      </div>
    </div>
  );
}

export default AudioModuleCreator;
