import React from "react";
import { Route, Routes, Router, useLocation } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard"
import Home from "./Home/Home";
import NewAccount from "./NewAccount";

import LoginPage from "./LoginPage";
import TagCreator from "./TagCreator/TagCreator2.0";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import TagScanner from "./TagReader/TagScanner";
import Store from "./Store/Store";
import Subscriptions from "./Store/subscriptions";
import DeviceFunctions from "./TagReader/DeviceFunctions";
import FontLoader from './FontLoader';
import CheckoutSuccess from "./Store/CheckoutSuccess";
import OrderTracker from "./Store/OrderTracker";
import Help from "./Help";
import TagEditor from "./TagCreator/TagEditor";

function App() {

  return (
    <div><FontLoader />
      <Routes>
        <Route path="/" element={<Home/>}/>
 
        <Route path="/tagcreator" element={
        <PrivateRoute>
        <TagCreator/>
        </PrivateRoute>
        }/>
        <Route path="/tageditor/*" element={
        <PrivateRoute>
        <TagEditor/>
        </PrivateRoute>
        }/>
        <Route path="/dashboard/*" element={
        <PrivateRoute>
        <Dashboard/>
        </PrivateRoute>
        }/>
        <Route path="/subscriptions" element={
        <PrivateRoute>
        <Subscriptions/>
        </PrivateRoute>
        }/>
        <Route path="/newaccount" element={
        <PublicRoute>
          <NewAccount/>
        </PublicRoute>
        }/>
        <Route path="/Store" element={<Store/>}/>
        <Route path="/CheckoutSuccess" element={<CheckoutSuccess/>}/>
       
        <Route path="/login" element={
        <PublicRoute>
        <LoginPage/>
        </PublicRoute>
        }/>
        <Route path="/device/*" element={<DeviceFunctions/>}/>
        <Route path="*" element={<TagScanner/>}/>
        <Route path="/Help" element={<Help/>}/>
        <Route path="/order/:orderID" element={<OrderTracker/>}/>
      </Routes>
      </div>
  );
};

export default App;