import React, { useState } from 'react';
import ListOfButtons from '../../TagCreator/ListOfButtons';
import PollVote from './PollVote';
import PollResults from './PollResults';

function PollComponent({ data }) {
  const [showResults, setShowResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // Add error state

  const listOfButtons = ListOfButtons();
  const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor,
  };

  const handleShowResults = () => {
    setShowResults(true);
  };

  return (
    <div 
      className="relative flex flex-col p-2 items-center justify-center w-full outline outline-2 overflow-hidden"  
      id="tagModule" 
      style={{ ...baseStyles, ...additionalStyles }}
    >
      {!showResults ? (
        <PollVote data={data} onShowResults={handleShowResults} setErrorMessage={setErrorMessage} /> // Pass setErrorMessage
      ) : (
        <PollResults data={data} />
      )}
      {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>} {/* Display error message */}
    </div>
  );
}

export default PollComponent;
