import React, { useEffect, useState } from "react";
import axios from "axios";
import { getUser } from '../../service/AuthService';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

function Interactables({ interactables }) {
    const [pollData, setPollData] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [reversedInteractables, setReversedInteractables] = useState([]);
    const user = getUser();
    const userLevel = user.level;

    useEffect(() => {
        // Reverse the interactables list
        setReversedInteractables(interactables.slice().reverse());
    }, [interactables]);

    useEffect(() => {
        if (userLevel > -1 && reversedInteractables.length > 0) {
            const currentInteractable = reversedInteractables[currentIndex];
            if (currentInteractable.InteractableType === 'poll') {
                getData(currentInteractable.InteractableID, currentInteractable.InteractableType)
                    .then(response => {
                        setPollData(response.data.Poll);
                    })
                    .catch(error => {
                        console.error("Error fetching poll data:", error);
                    });
            } else {
                setPollData(null); // Reset if the type is not poll
            }
        }
    }, [userLevel, reversedInteractables, currentIndex]);

    const handleNext = () => {
        if (currentIndex < reversedInteractables.length - 1) {
            setPollData(null); // Clear current poll data
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            setPollData(null); // Clear current poll data
            setCurrentIndex(currentIndex - 1);
        }
    };

    const getEmbedLink = (watchLink) => {
        let videoId;
        let embedLink;
        if (watchLink && watchLink.includes("v=")) {
            videoId = watchLink.split("v=")[1];
            let ampersandPosition = videoId.indexOf("&");
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
            embedLink = "https://www.youtube.com/embed/" + videoId;
        } else if (watchLink && watchLink.includes("youtu.be")) {
            videoId = watchLink.split("/").pop();
            embedLink = "https://www.youtube.com/embed/" + videoId;
        }
        return embedLink;
    };

    const getSpotifyEmbedLink = (spotifyLink) => {
        let embedLink = spotifyLink.replace("open.spotify.com", "open.spotify.com/embed");
        return embedLink;
    };

    const renderAnswerContent = (answer) => {
        switch (answer.type) {
            case 'text':
                return <span className="text-white px-2">{answer.data}</span>;
            case 'video':
                const videoLink = getEmbedLink(answer.data);
                return (
                    <div className="w-full h-full scale-25 mb-5">
                        <iframe src={videoLink} frameBorder="0" allowFullScreen></iframe>
                    </div>
                );
            case 'music':
                const musicLink = getSpotifyEmbedLink(answer.data);
                return (
                    <div className="w-full h-full scale-40 mb-5">
                        <iframe src={musicLink} width="300" height="80" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    </div>
                );
            default:
                return <span className="text-white px-2">{answer.data}</span>;
        }
    };

    if (userLevel > -1) {
        return (
            <div className="w-full h-full outline outline-1 bg-zinc-950 outline-blue-300 rounded-lg overflow-auto scroll p-4">
                <h1 className="text-lg py-2 font-semibold text-white">Interactables</h1>
                {pollData ? (
                    <div>
                        <h2 className="text-white text-lg">{pollData.PollQuestion}</h2>
                        {pollData.AnswerList.map(answer => {
                            const percentage = pollData.VotesTotal > 0 ? (answer.votes / pollData.VotesTotal) * 100 : 0;
                            return (
                                <div key={answer.data} className="my-2 relative outline outline-1 outline-blue-300 rounded-lg flex items-center p-4">
                                    <div
                                        className="bg-blue-500 h-10 rounded-l flex items-center"
                                        style={{ width: `${percentage}%` }}
                                    >
                                        {renderAnswerContent(answer)}
                                    </div>
                                    <div className="flex-grow"></div>
                                    <div className="h-10 flex items-center px-2 bg-zinc-950 text-white">
                                        <span>{answer.votes}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p className="text-white">No interactables available.</p>
                )}
                <div className="flex bottom-0 z-30  p-8 justify-between items-center mt-4">
                    <button
                        className="bg-blue-500 text-white hover:bg-blue-600 py-2 px-4 rounded"
                        onClick={handleBack}
                        disabled={currentIndex === 0}
                    >
                        <HiChevronLeft />
                    </button>
                    <button
                        className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                        onClick={handleNext}
                        disabled={currentIndex === reversedInteractables.length - 1}
                    >
                        <HiChevronRight />
                    </button>
                </div>
                <br />
            </div>
        );
    } else {
        return (
            <div className="w-full h-full outline outline-1 bg-zinc-950 outline-blue-300 rounded-lg p-4">
                <h1 className="text-lg py-2 font-semibold text-white">Insufficient Level</h1>
                <p className="text-white">You need to reach a higher level to view interactables.</p>
            </div>
        );
    }
}

export default Interactables;

async function getData(InteractableID, InteractableType) {
    const getPollAPIURL = 'https://83f5ucs1d5.execute-api.us-west-2.amazonaws.com/prod/getpoll';

    if (InteractableType === 'poll') {
        try {
            const response = await axios.post(getPollAPIURL, {
                PollID: InteractableID
            }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_KEY
                }
            });

            return response;
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                console.log(error.response.data.message);
            } else {
                console.log('Sorry, the server is down right now, please try again later.');
                console.log(error.response ? error.response.data.message : error.message);
            }
            throw error;
        }
    } else {
        console.log('error');
        return;
    }
}
